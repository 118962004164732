package org.tigase.officialtea.common.main.components.mixbrowser

import com.arkivanov.decompose.value.Value
import tigase.halcyon.core.xmpp.BareJID

interface MixChannelsList {

	val models: Value<Model>

	fun setAddress(account: BareJID, address: BareJID)
	fun selectItem(item: Item?)
	fun joinMixChannel(item: Item)

	data class Item(val account: BareJID, val name: String, val jid: BareJID)

	data class Model(
		val account: BareJID?,
		val address: BareJID?,
		val items: List<Item> = emptyList(),
		val selected: Item? = null,
		val errorMessage: String? = null,
	)
}