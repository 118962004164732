package org.tigase.officialtea.common.main.components.vcard

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.store.StoreFactory
import org.tigase.officialtea.common.main.components.vcard.VCardPanel.State
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.asValue
import org.tigase.officialtea.common.utils.getStore
import tigase.halcyon.core.xmpp.BareJID

class VCardPanelComponent(
	private val componentContext: ComponentContext,
	private val storeFactory: StoreFactory,
	private val serviceKeeper: ServiceKeeper,
	private val account: BareJID,
	private val jid: BareJID,
) : VCardPanel, ComponentContext by componentContext {

	private val store = instanceKeeper.getStore {
		VCardPanelStoreProvider(
			storeFactory = storeFactory, account = account, jid = jid, keeper = serviceKeeper
		).provide()
	}

	override val models: Value<State> = store.asValue()

}