package components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLElement

@Composable
fun FormPanel(content: ContentBuilder<HTMLElement>) {
	Div(attrs = { classes("form-wrapper") }) {
		content()
	}
}

@Composable
fun textInput(
	label: String,
	value: String,
	onValueChange: (String) -> Unit,
	onFocusChange: (() -> Unit)?=null,
	inputType: InputType<String> = InputType.Text
) {
	Div(attrs = {
		classes("textInput", "body-large", "clearfix","dark:border", "dark:border-[#fff]","mb-3")
	}) {
		//For some stupid  something in placeholder (for example " ")
		Input(type = inputType, attrs = {
			placeholder(" ")
			this.value(value)
			onInput { event ->
				onValueChange(event.value)
			}
			onFocusOut {
				onFocusChange?.invoke()
			}
		})


		Label {
			Text(label)
		}

	}
}

@Composable
fun CheckBox(label: String, value: Boolean, onValueChange: (Boolean) -> Unit){
	Label(attrs = {
		classes("pure-material-checkbox")
		onClick { onValueChange(!value) }
	}) {
		Input(type = InputType.Checkbox, attrs = {
			checked(value)
		})
		Span { Text(label) }
	}
}