package org.tigase.officialtea.common.services.settings

import com.arkivanov.decompose.value.MutableValue
import com.russhwolf.settings.ExperimentalSettingsApi
import com.russhwolf.settings.ObservableSettings
import com.russhwolf.settings.Settings

@ExperimentalSettingsApi
actual class ApplicationSettings actual constructor(settings: Settings) :
	AbstractApplicationSettings(settings) {
	override val allowTranslation: MutableValue<Boolean>
		get() = this.allowTranslation
	override val keepSideOpenWhileChatting: MutableValue<Boolean>
		get() = this.keepSideOpenWhileChatting
	override val keepSideOpenWhileNavigating: MutableValue<Boolean>
		get() = this.keepSideOpenWhileNavigating
	override val wrapMessagesInBubbles: MutableValue<Boolean>
		get() = this.wrapMessagesInBubbles

}