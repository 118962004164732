package org.tigase.officialtea.common.utils

import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.JID
import tigase.halcyon.core.xmpp.bareJID
import kotlin.math.min

private fun normalize(value: String): String = value.replace("[^a-zA-Z0-9 ]".toRegex(), "")
	.trim()
	.uppercase()

fun initials(jid: JID, name: String): String = initials(name, jid.bareJID.toString())

fun initials(vararg values: String?): String {
	try {
//		val normalizedName = normalize(name).ifBlank { normalize(jid.bareJID.toString()) }
		val normalizedName = values.filterNotNull().map { normalize(it) }.first { it.isNotBlank() }
		val items = normalizedName.split(' ')
			.filter { it.isNotBlank() }
		return if (normalizedName.isBlank()) "?"
		else if (items.size >= 2) {
			items[0].first() + "" + items[1].first()
		} else {
			normalizedName.substring(0, min(2, normalizedName.length))
		}
	} catch (e: Exception) {
		println("Cannot prepare initials for '$values' ")
		return "??"
	}
}