package org.tigase.officialtea.common.main.settings

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import org.tigase.officialtea.common.main.settings.Options.Model
import org.tigase.officialtea.common.main.settings.OptionsStore.Intent

internal interface OptionsStore : Store<Intent, Model, OptionsStore.Label> {

	sealed interface Intent {

		data class SelectItem(val item: Options.MenuItem) : Intent
	}

	sealed interface Label {

		data class ItemClicked(val item: Options.MenuItem) : Label

	}

}

internal class OptionsStoreProvider(
	private val storeFactory: StoreFactory, private val items: List<Options.MenuItem>,
) {

	fun provide(): OptionsStore =
		object : OptionsStore, Store<Intent, Model, OptionsStore.Label> by storeFactory.create(
			name = "OptionsStore",
			initialState = Model(selected = items.first(), items = items),
			bootstrapper = SimpleBootstrapper(Unit),
			executorFactory = ::ExecutorImpl,
			reducer = ReducerImpl
		) {}

	private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, Model, Intent, OptionsStore.Label>() {

		override fun executeIntent(intent: Intent, getState: () -> Model) {
			dispatch(intent)
			if (intent is Intent.SelectItem) publish(OptionsStore.Label.ItemClicked(intent.item))
		}
	}

	private object ReducerImpl : Reducer<Model, Intent> {

		override fun Model.reduce(result: Intent): Model = when (result) {
			is Intent.SelectItem -> copy(selected = result.item)
		}
	}
}
