package org.tigase.officialtea.common.main.components.mixbrowser

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.childContext
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.badoo.reaktive.observable.subscribe
import org.tigase.officialtea.common.services.ServiceKeeper

class MixBrowserComponent(
	componentContext: ComponentContext,
	storeFactory: StoreFactory,
	serviceKeeper: ServiceKeeper,
) : MixBrowser, ComponentContext by componentContext {

	override val mixChannelsList: MixChannelsList =
		MixChannelsListComponent(childContext("MixChannelsList"), storeFactory, serviceKeeper)

	override val addressInput: AddressInput =
		AddressInputComponent(childContext("AddressInput"), storeFactory, serviceKeeper = serviceKeeper)

	init {
		addressInput.labels()
			.subscribe {
				if (it is AddressInputStore.Label.S) {
					mixChannelsList.setAddress(it.account, it.address)
				}
			}
	}

}