package org.tigase.officialtea.common.main.settings

import com.arkivanov.decompose.value.Value

interface Options {

	val models: Value<Model>

	fun select(item: MenuItem)

	data class Model(val selected: MenuItem, val items: List<MenuItem>)

	data class MenuItem(
		val name: String, val icon: String, var action: () -> SettingsComponent.SettingsConfiguration,
	)

}