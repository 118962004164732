package org.tigase.officialtea.database

import com.squareup.sqldelight.Transacter
import com.squareup.sqldelight.db.SqlDriver
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.database.AccountsDatabaseQueries
import org.tigase.officialtea.common.database.Attachments
import org.tigase.officialtea.common.database.AttachmentsQueries
import org.tigase.officialtea.common.database.AvatarsCache
import org.tigase.officialtea.common.database.AvatarsCacheDatabaseQueries
import org.tigase.officialtea.common.database.CapabilitiesCacheQueries
import org.tigase.officialtea.common.database.ChatMarkers
import org.tigase.officialtea.common.database.ChatMarkersQueries
import org.tigase.officialtea.common.database.Messages
import org.tigase.officialtea.common.database.MessagesDatabaseQueries
import org.tigase.officialtea.common.database.OpenChats
import org.tigase.officialtea.common.database.OpenChatsDatabaseQueries
import org.tigase.officialtea.common.database.Roster
import org.tigase.officialtea.common.database.RosterDatabaseQueries
import org.tigase.officialtea.database.database.newInstance
import org.tigase.officialtea.database.database.schema

public interface OfficialTeaDatabase : Transacter {
  public val accountsDatabaseQueries: AccountsDatabaseQueries

  public val attachmentsQueries: AttachmentsQueries

  public val avatarsCacheDatabaseQueries: AvatarsCacheDatabaseQueries

  public val capabilitiesCacheQueries: CapabilitiesCacheQueries

  public val chatMarkersQueries: ChatMarkersQueries

  public val messagesDatabaseQueries: MessagesDatabaseQueries

  public val openChatsDatabaseQueries: OpenChatsDatabaseQueries

  public val rosterDatabaseQueries: RosterDatabaseQueries

  public companion object {
    public val Schema: SqlDriver.Schema
      get() = OfficialTeaDatabase::class.schema

    public operator fun invoke(
      driver: SqlDriver,
      AccountsAdapter: Accounts.Adapter,
      AttachmentsAdapter: Attachments.Adapter,
      AvatarsCacheAdapter: AvatarsCache.Adapter,
      ChatMarkersAdapter: ChatMarkers.Adapter,
      MessagesAdapter: Messages.Adapter,
      OpenChatsAdapter: OpenChats.Adapter,
      RosterAdapter: Roster.Adapter
    ): OfficialTeaDatabase = OfficialTeaDatabase::class.newInstance(driver, AccountsAdapter,
        AttachmentsAdapter, AvatarsCacheAdapter, ChatMarkersAdapter, MessagesAdapter,
        OpenChatsAdapter, RosterAdapter)
  }
}
