package org.tigase.officialtea.common.main.settings.accountslist

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.observable.combineLatest
import com.badoo.reaktive.observable.map
import com.badoo.reaktive.observable.observeOn
import com.badoo.reaktive.observable.startWithValue
import com.badoo.reaktive.scheduler.mainScheduler
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.services.AccountsService
import org.tigase.officialtea.common.services.ConnectionService
import org.tigase.officialtea.common.main.settings.accountslist.AccountsList.Model
import org.tigase.officialtea.common.main.settings.accountslist.AccountsListStore.Intent
import tigase.halcyon.core.AbstractHalcyon.State
import tigase.halcyon.core.HalcyonStateChangeEvent
import tigase.halcyon.rx.observe

internal interface AccountsListStore : Store<Intent, Model, Nothing> {

	sealed interface Intent

}

internal class AccountsListStoreProvider(
	private val storeFactory: StoreFactory,
	private val accountsService: AccountsService,
	private val connectionService: ConnectionService,
) {

	fun provide(): AccountsListStore = object : AccountsListStore, Store<Intent, Model, Nothing> by storeFactory.create(
		name = "AccountsListStore",
		initialState = Model(),
		bootstrapper = SimpleBootstrapper(Unit),
		executorFactory = ::ExecutorImpl,
		reducer = ReducerImpl
	) {}

	private sealed interface Result {

		data class DataLoaded(val items: List<Pair<Accounts, State>>) : Result

	}

	private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, Model, Result, Nothing>() {

		private fun toPairWithState(items: List<Accounts>) = items.map {
			val st = connectionService[it.userjid]?.state ?: State.Stopped
			Pair(it, st)
		}

		override fun executeAction(action: Unit, getState: () -> Model) {

			combineLatest(
				connectionService.eventBus.observe<HalcyonStateChangeEvent>(HalcyonStateChangeEvent.TYPE)
					.startWithValue(HalcyonStateChangeEvent(State.Stopped, State.Disconnected)),
				accountsService.observeAll(),
			) { _, list -> list }.observeOn(mainScheduler)
				.map(::toPairWithState)
				.map(Result::DataLoaded)
				.subscribeScoped(onNext = ::dispatch)
		}
	}

	private object ReducerImpl : Reducer<Model, Result> {

		override fun Model.reduce(result: Result): Model = when (result) {
			is Result.DataLoaded -> copy(items = result.items)
		}
	}
}
