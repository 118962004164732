package org.tigase.officialtea.common.main.components.roster

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.badoo.reaktive.base.Consumer
import com.badoo.reaktive.base.invoke
import org.tigase.officialtea.common.services.ConnectionService
import org.tigase.officialtea.common.services.RosterPresenceItem
import org.tigase.officialtea.common.services.RosterPresenceService
import org.tigase.officialtea.common.utils.asValue
import org.tigase.officialtea.common.utils.getStore
import tigase.halcyon.core.Halcyon
import tigase.halcyon.core.ReflectionModuleManager
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.roster.RosterItem
import tigase.halcyon.core.xmpp.modules.roster.RosterModule
import tigase.halcyon.core.xmpp.modules.spam.BlockingCommandModule

class RosterComponent(
	componentContext: ComponentContext,
	storeFactory: StoreFactory,
	private val service: RosterPresenceService,
	private val startChatOutput: Consumer<RosterPresenceItem>,
	private val connectionService: ConnectionService
) : Roster, ComponentContext by componentContext {

	private val store = instanceKeeper.getStore {
		RosterStoreProvider(storeFactory = storeFactory, service = service, connectionService = connectionService).provide()
	}

	override val models: Value<Roster.Model> = store.asValue()

	override fun onRosterItemClick(item: RosterPresenceItem) {
		store.accept(RosterStore.Intent.SelectRosterItem(item))
	}

	override fun onModalClose() {
		store.accept((RosterStore.Intent.CloseActionMenu))
	}

	override fun startChatWith(item: RosterPresenceItem) {
		startChatOutput(item)
	}

	override fun onRosterItemSelect(item: RosterPresenceItem) {
		store.accept(RosterStore.Intent.RosterEditMode(true))
		store.accept(RosterStore.Intent.SelectRosterItem(item))
	}

	override fun onActionMenuClicked(item: RosterPresenceItem) {
		store.accept(RosterStore.Intent.ActionMenu(item))
	}

	override fun editMode(value: Boolean) {
		store.accept(RosterStore.Intent.RosterEditMode(value))
	}

	override fun blockContact(account: BareJID, victim: BareJID) {
		connectionService[account]!!.getModule(BlockingCommandModule).block(victim)
			.send()
	}

	@OptIn(ReflectionModuleManager::class)
	override fun addContact(account: BareJID, victim: RosterItem) {
		connectionService[account]!!.getModule(RosterModule).addItem(victim)
			.send()
	}

	@OptIn(ReflectionModuleManager::class)
	override fun removeContact(account: BareJID, victim: BareJID) {
		connectionService[account]!!.getModule(RosterModule).deleteItem(victim)
			.send()
	}

	override fun updateVCard(account: BareJID, data: List<String>) {
		// need to revisit this, structuring vcard obj looks quite complicated
//        connectionService[account]!!.getModule(VCardModule).publish()
//            .send()
	}

}