package org.tigase.officialtea.common.main.components.chat.messagelist

import com.arkivanov.decompose.value.Value
import com.badoo.reaktive.observable.Observable
import kotlinx.datetime.Instant
import org.tigase.officialtea.common.database.MessageWithAttachment
import org.tigase.officialtea.common.database.OpenChats
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.chatmarkers.ChatMarkersModule

interface MessageList {

	fun refresh()
	fun loadMore()

	val models: Value<Model>

	data class Mark(val jid: BareJID, val nickname: String, val timestamp: Instant, val type: ChatMarkersModule.Marker)

	data class Model(
		val openChat: OpenChats? = null,
		val title: String = "",
		val messages: List<MessageWithAttachment> = emptyList(),
		val marks: List<Mark> = emptyList(),
		val compiledRows: List<DisplayRow> = emptyList(),
		val pageSize: Long = 50,
		val messagesCount: Long = 0,
		val displayLoadMore: Boolean = true,
	)

	sealed interface DisplayRow {

		data class Message(val message: MessageWithAttachment, val mergeWithPrevious: Boolean) : DisplayRow
		data class Mark(val marks: List<MessageList.Mark>) : DisplayRow
		data class DateSeparator(val date: Instant) : DisplayRow

	}

	val labels: Observable<MessageListStore.Label>
}

fun MessageWithAttachment.displayMessage(): String {
	return if (this.body == this.attachment_url) ""
	else this.body ?: ""
}