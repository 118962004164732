package org.tigase.officialtea.common.main.components.mixbrowser

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.maybe.Maybe
import com.badoo.reaktive.maybe.observeOn
import com.badoo.reaktive.maybe.subscribeOn
import com.badoo.reaktive.observable.*
import com.badoo.reaktive.scheduler.ioScheduler
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.flatten
import com.badoo.reaktive.single.map
import com.badoo.reaktive.single.observeOn
import com.badoo.reaktive.single.subscribeOn
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.main.components.mixbrowser.AddressInput.Model
import org.tigase.officialtea.common.main.components.mixbrowser.AddressInputStore.Intent
import org.tigase.officialtea.common.main.components.mixbrowser.AddressInputStore.Label
import org.tigase.officialtea.common.services.ServiceKeeper
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.bareJID
import tigase.halcyon.core.xmpp.modules.discovery.DiscoveryModule
import tigase.halcyon.core.xmpp.toBareJID
import tigase.halcyon.core.xmpp.toJID
import tigase.halcyon.rx.asSingle

interface AddressInputStore : Store<Intent, Model, Label> {

	sealed interface Intent {

		data class SetAddress(val value: String) : Intent
		data class SetAccount(val value: String) : Intent
		object TryToApply : Intent

	}

	sealed interface Label {

		data class S(val account: BareJID, val address: BareJID) : Label

	}
}

internal class AddressInputStoreProvider(
	private val storeFactory: StoreFactory, private val serviceKeeper: ServiceKeeper,
) {

	fun provide(): AddressInputStore = object : AddressInputStore, Store<Intent, Model, Label> by storeFactory.create(
		name = "AddressInputStore",
		initialState = Model(),
		bootstrapper = SimpleBootstrapper(Unit),
		executorFactory = ::ExecutorImpl,
		reducer = ReducerImpl
	) {}

	private sealed interface Result {

		data class AddressChanged(val value: String) : Result
		data class AccountsLoaded(val value: List<BareJID>) : Result
		data class AccountChanged(val value: BareJID) : Result

	}

	private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, Model, Result, Label>() {

		override fun executeAction(action: Unit, getState: () -> Model) {
			serviceKeeper.accountsService.observeAll()
				.observeOn(mainScheduler)
				.map {
					it.filter(Accounts::enabled)
						.map(Accounts::userjid)
				}
				.filter { it.isNotEmpty() }
				.subscribeScoped {
					dispatch(Result.AccountsLoaded(it))
					if (getState().account == null) {
						if (it.isNotEmpty()) dispatch(Result.AccountChanged(it.first()))
						if (getState().address.isBlank()) {
							findMix(getState().account!!).subscribeOn(ioScheduler)
								.observeOn(mainScheduler)
								.subscribeScoped {
									dispatch(Result.AddressChanged("$it"))
									sendLabel(getState())
								}
						}
					}
				}
		}

		private fun findMix(account: BareJID): Maybe<BareJID> =
			serviceKeeper.connectionService[account]!!.getModule<DiscoveryModule>(DiscoveryModule.TYPE)
				.items(account.domain.toJID())
				.asSingle()
				.subscribeOn(ioScheduler)
				.observeOn(mainScheduler)
				.map { it.items.map { it.jid } }
				.flatten()
				.flatMapSingle {
					serviceKeeper.connectionService[account]!!.getModule<DiscoveryModule>(DiscoveryModule.TYPE)
						.info(it)
						.asSingle()
						.subscribeOn(ioScheduler)
				}
				.filter {
					it.identities.any { it.type == "mix" }
				}
				.map {
					it.jid.bareJID
				}
				.firstOrComplete()

		private fun sendLabel(model: Model) {
			val account = model.account ?: return
			if (model.address.isBlank()) return
			publish(
				Label.S(
					account = account,
					address = model.address.toBareJID(),
				)
			)
		}

		override fun executeIntent(intent: Intent, getState: () -> Model) = when (intent) {
			is Intent.SetAddress -> onSetAddress(intent, getState)
			is Intent.SetAccount -> onSetAccount(intent, getState)
			Intent.TryToApply -> sendLabel(getState())
		}

		private fun onSetAccount(intent: Intent.SetAccount, getState: () -> Model) {
			dispatch(Result.AccountChanged(intent.value.toBareJID()))
			sendLabel(getState())
		}

		private fun onSetAddress(intent: Intent.SetAddress, getState: () -> Model) {
			dispatch(Result.AddressChanged(intent.value))
		}

	}

	private object ReducerImpl : Reducer<Model, Result> {

		override fun Model.reduce(msg: Result): Model = when (msg) {
			is Result.AddressChanged -> copy(address = msg.value)
			is Result.AccountsLoaded -> copy(availableAccounts = msg.value)
			is Result.AccountChanged -> copy(account = msg.value)
		}
	}
}
