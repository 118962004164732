package org.tigase.officialtea.common.services

import co.touchlab.kermit.Logger
import com.badoo.reaktive.maybe.observeOn
import com.badoo.reaktive.maybe.subscribe
import com.badoo.reaktive.observable.Observable
import com.badoo.reaktive.observable.asObservable
import com.badoo.reaktive.observable.subscribe
import com.badoo.reaktive.scheduler.ioScheduler
import com.badoo.reaktive.single.Single
import com.badoo.reaktive.single.observeOn
import com.badoo.reaktive.single.single
import com.badoo.reaktive.single.subscribe
import com.badoo.reaktive.subject.behavior.BehaviorSubject
import com.squareup.sqldelight.db.SqlDriver
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.main.version.OfficialTeaVersion
import org.tigase.officialtea.common.services.halcyon.LastMessageCorrectionModule
import org.tigase.officialtea.common.services.halcyon.PushNotificationsModule
import org.tigase.officialtea.common.services.roster.AccountRosterStore
import org.tigase.officialtea.common.services.utils.HalcyonKermitLoggerSPI
import org.tigase.officialtea.common.services.utils.MultiEventBus
import tigase.halcyon.core.AbstractHalcyon
import tigase.halcyon.core.Halcyon
import tigase.halcyon.core.HalcyonStateChangeEvent
import tigase.halcyon.core.builder.*
import tigase.halcyon.core.configuration.declaredUserJID
import tigase.halcyon.core.eventbus.Event
import tigase.halcyon.core.logger.LoggerFactory
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.avatar.UserAvatarModule
import tigase.halcyon.core.xmpp.modules.carbons.MessageCarbonsModule
import tigase.halcyon.core.xmpp.modules.chatmarkers.ChatMarkersModule
import tigase.halcyon.core.xmpp.modules.chatmarkers.ChatMarkersModuleConfig
import tigase.halcyon.core.xmpp.modules.presence.PresenceReceivedEvent
import tigase.halcyon.core.xmpp.modules.presence.PresenceStore
import tigase.halcyon.core.xmpp.modules.receipts.DeliveryReceiptsModule
import tigase.halcyon.core.xmpp.modules.receipts.DeliveryReceiptsModuleConfig

expect fun createPresenceStore(): PresenceStore

class ConnectionServiceImpl(private val accountsService: AccountsServiceImpl, private val driver: Single<SqlDriver>) :
    ConnectionService {

    private val log = Logger.withTag("ConnectionService")

    private val halcyons = mutableMapOf<BareJID, Halcyon>()

    override val eventBus = MultiEventBus().apply {
        register<Event> {
            log.i("Event: $it")
        }
    }

    private val _clients = BehaviorSubject(halcyons.values.toList())
    override val clients: Observable<List<Halcyon>> = _clients

    init {
        LoggerFactory.spiFactory = ::HalcyonKermitLoggerSPI

        accountsService.updates.subscribe(onNext = this::onAccountChange)
        accountsService.loadAll()
            .observeOn(ioScheduler)
            .subscribe(onSuccess = this::accountListLoaded)
        eventBus.register<HalcyonStateChangeEvent>(HalcyonStateChangeEvent.TYPE) {
            if (it.newState == AbstractHalcyon.State.Connected) {
                doWhenConnected(it.context)
            }
        }
        eventBus.register<PresenceReceivedEvent>(PresenceReceivedEvent.TYPE) { event ->
            event.stanza.children.filter { it.xmlns == "vcard-temp:x:update" }
                .forEach {
                    it.getFirstChild("photo")
                        ?.let { photo ->
                            AvatarsCacheService.checkAvatarFromVCard(event.context, event.jid, photo.value!!)
                        }
                }
        }
    }

    override fun allClients() = halcyons.values.asObservable()

    private fun doWhenConnected(halcyon: AbstractHalcyon) {
        halcyon.getModule<MessageCarbonsModule>(MessageCarbonsModule.TYPE)
            .enable()
            .send()
    }

    private fun accountListLoaded(list: List<Accounts>) {
        list.map { buildHalcyon(it) }
            .forEach {
                halcyons[it.config.declaredUserJID!!] = it
                eventBus.bind(it.eventBus)
                _clients.onNext(halcyons.values.toList())
                log.i("Added Halcyon ${it.config.declaredUserJID}")
                startIfRequired(it)
            }
    }

    private fun buildHalcyon(account: Accounts): Halcyon {
        log.i("Create Halcyon for ${account.userjid} account.")
        val halcyon = createHalcyon {
            auth {
                userJID = account.userjid
                password { account.password }
            }
            discovery {
                clientVersion = OfficialTeaVersion.APP_VERSION
                clientName = OfficialTeaVersion.APP_NAME
                clientType = "pc"
            }
            roster {
                store = AccountRosterStore(account = account.userjid, driver = driver)
            }
            presence {
                store = createPresenceStore()
            }
            capabilities {
                cache = CapabilitiesCacheService
            }
            install(UserAvatarModule) {
                this.store = AvatarsCacheService
            }
            install(ChatMarkersModule) {
                mode = ChatMarkersModuleConfig.Mode.Off
            }
            install(DeliveryReceiptsModule) {
                mode = DeliveryReceiptsModuleConfig.Mode.Off
            }
            install(LastMessageCorrectionModule)
            install(PushNotificationsModule)
            configureConnector(account)
        }

        return halcyon
    }

    private fun onAccountChange(event: AccountsService.ChangeEvent) = when (event) {
        is AccountsService.ChangeEvent.Added -> addAccount(event.jid)
        is AccountsService.ChangeEvent.Updated -> recreate(event.jid)
        is AccountsService.ChangeEvent.Removed -> remove(event.jid)
    }

    private fun recreate(jid: BareJID) {
        Logger.i { "Recreate $jid" }
        remove(jid)
        addAccount(jid)
    }

    private fun remove(jid: BareJID) {
        halcyons.remove(jid)
            ?.let {
                eventBus.unbind(it.eventBus)
                _clients.onNext(halcyons.values.toList())
                log.i("Removed Halcyon ${it.config.declaredUserJID}")
                if (it.state != AbstractHalcyon.State.Stopped) it.disconnect()
            }

    }

    private fun addAccount(jid: BareJID) {
        accountsService.load(jid)
            .observeOn(ioScheduler)
            .subscribe(onSuccess = { accounts ->
                val h = buildHalcyon(accounts)
                halcyons[accounts.userjid] = h
                eventBus.bind(h.eventBus)
                _clients.onNext(halcyons.values.toList())
                log.i("Added Halcyon ${h.config.declaredUserJID}")
                startIfRequired(h)
            })
    }

    private fun startIfRequired(halcyon: Halcyon) {
        halcyon.connect()

//		ioScheduler.newExecutor().submit {
//			log.i { "ODPALAMY $halcyon" }
//		}
    }

    override fun connectAll() {
        Logger.i { "Reconnecting all..." }
        accountsService.loadAll()
            .subscribe {
                it.forEach { recreate(it.userjid) }
            }
    }

    override fun disconnect() {
        halcyons.forEach {
            if (it.value.state != AbstractHalcyon.State.Stopped) {
                it.value.disconnect()
            }
        }
    }

    override fun get(account: BareJID): Halcyon? = halcyons[account]
    override fun halcyon(account: BareJID): Single<Halcyon> = single { emitter ->
        val r = halcyons[account]
        if (r == null) emitter.onError(RuntimeException("Halcyon for $account doesn't exists."))
        else emitter.onSuccess(r)
    }

}