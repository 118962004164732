package org.tigase.officialtea.common.database

import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.JID
import tigase.halcyon.core.xmpp.stanzas.MessageType

public data class GetLatestMamStanzaId(
  public val id: Long,
  public val openchat_id: Long?,
  public val account: BareJID,
  public val author_jid: JID,
  public val author_nickname: String?,
  public val recipient_jid: JID,
  public val recipient_nickname: String?,
  public val stanza_type: MessageType,
  public val origin_stanza_id: String,
  public val mam_stanza_id: String,
  public val remote_stanza_id: String?,
  public val participant_id: String?,
  public val body: String?,
  public val timestamp: Instant,
  public val state: MessageState,
  public val markable: Boolean,
  public val correction_stanza_id: String?,
  public val correction_timestamp: Instant?
) {
  public override fun toString(): String = """
  |GetLatestMamStanzaId [
  |  id: $id
  |  openchat_id: $openchat_id
  |  account: $account
  |  author_jid: $author_jid
  |  author_nickname: $author_nickname
  |  recipient_jid: $recipient_jid
  |  recipient_nickname: $recipient_nickname
  |  stanza_type: $stanza_type
  |  origin_stanza_id: $origin_stanza_id
  |  mam_stanza_id: $mam_stanza_id
  |  remote_stanza_id: $remote_stanza_id
  |  participant_id: $participant_id
  |  body: $body
  |  timestamp: $timestamp
  |  state: $state
  |  markable: $markable
  |  correction_stanza_id: $correction_stanza_id
  |  correction_timestamp: $correction_timestamp
  |]
  """.trimMargin()
}
