import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.jetbrains.subscribeAsState
import components.FormPanel
import components.MenuItem
import components.textInput
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.dom.*
import org.tigase.officialtea.common.main.components.mixbrowser.AddressInput
import org.tigase.officialtea.common.main.components.mixbrowser.MixBrowserComponent
import org.tigase.officialtea.common.main.components.mixbrowser.MixChannelsList

@Composable
fun MixBrowserContent(component: MixBrowserComponent) {
    MixAddressContent(component.addressInput)
    MixChannelsListContent(component.mixChannelsList)
}

@Composable
fun MixChannelsListContent(component: MixChannelsList) {
    val model by component.models.subscribeAsState()
    Div(attrs = { classes("mix-browser") }) {
        if (model.errorMessage != null) {
            Div(attrs = { classes("error-panel") }) {
                Text("ERROR: ${model.errorMessage}")
            }
        }
        for (item in model.items) {
            MixItemView(
                item = item,
                active = model.selected == item,
                onClickCallback = { component.selectItem(item) },
                joinCallBack = { component.joinMixChannel(item) })
        }
    }
}

@Composable
fun MixItemView(
    item: MixChannelsList.Item,
    active: Boolean,
    onClickCallback: (() -> Unit)? = null,
    joinCallBack: (() -> Unit)? = null
) {
    MenuItem(active = active, icon = { Avatar(item.jid, item.name) }, onClickCallback = onClickCallback, content = {
        Div(attrs = {
        }) {
            Text(item.name)
        }
        if (active)
            Div(attrs = { classes("button") }) {
                Button(attrs = {
                    classes( "bg-[#324167]", "hover:bg-sky-700", "px-5", "py-2", "h-[40px]", "w-[100px]", "rounded-full", "font-[500]", "text-white", "text-[15px]")
                    onClick {
                        it.stopPropagation()
                        joinCallBack?.invoke()
                    }
                }) { Text("Join") }
            }
    })
}

@Composable
fun MixAddressContent(component: AddressInput) {
    val model by component.models.subscribeAsState()
    FormPanel {

        Div(attrs = {
            classes("textInput", "body-large", "clearfix","dark:border", "dark:border-[#fff]", "mb-3")
        }) {
            //For some stupid  something in placeholder (for example " ")
            Select(attrs = {
                onChange { component.onAccountChange(it.value!!) }
            }) {
                model.availableAccounts.forEach {
                    Option(attrs = {
                        if (model.account == it) selected()
                    }, value = it.toString()) {
                        Text(it.toString())
                    }
                }
            }
            Label {
                Text("Account")
            }

        }

        textInput(
            label = "Address",
            value = model.address,
            onValueChange = component::onTextChange,
            onFocusChange = { component.tryToApplyData() })

    }
}