package org.tigase.officialtea.common.services.roster

import co.touchlab.kermit.Logger
import com.badoo.reaktive.maybe.Maybe
import com.badoo.reaktive.maybe.toMaybe
import com.badoo.reaktive.observable.*
import com.badoo.reaktive.single.*
import com.badoo.reaktive.subject.publish.PublishSubject
import com.squareup.sqldelight.db.SqlDriver
import org.tigase.officialtea.common.database.*
import org.tigase.officialtea.common.services.ConnectionService
import org.tigase.officialtea.common.services.ContactStatus
import org.tigase.officialtea.common.services.RosterPresenceItem
import org.tigase.officialtea.common.services.RosterPresenceService
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.presence.ContactChangeStatusEvent
import tigase.halcyon.core.xmpp.modules.presence.PresenceModule
import tigase.halcyon.core.xmpp.modules.roster.RosterEvent
import tigase.halcyon.core.xmpp.modules.roster.RosterUpdatedEvent
import tigase.halcyon.core.xmpp.stanzas.Presence
import tigase.halcyon.core.xmpp.stanzas.PresenceType
import tigase.halcyon.core.xmpp.stanzas.Show
import tigase.halcyon.rx.observe

class RosterPresenceServiceImpl(
    driver: Single<SqlDriver>, private val connectionService: ConnectionService,
) : RosterPresenceService {

    constructor(driver: SqlDriver, connectionService: ConnectionService) : this(singleOf(driver), connectionService)

    private val log = Logger.withTag("RosterPresenceService")

    private val queries: Single<RosterDatabaseQueries> = driver.map { SharedDatabase(it).rosterDatabaseQueries }
        .asObservable()
        .replay()
        .autoConnect()
        .firstOrError()

//	private val rosterSubject = BehaviorSubject<List<RosterPresenceItem>>(emptyList())

    private fun getContactStatus(account: BareJID, jid: BareJID): ContactStatus =
        connectionService[account]?.getModule<PresenceModule>(PresenceModule.TYPE)
            ?.getBestPresenceOf(jid)
            .toContactStatus()

    val updateSubject = PublishSubject<Unit>()

    init {
        connectionService.eventBus.observe(RosterEvent)
            .subscribe {
                Logger.i { "Update roster!" }
                updateSubject.onNext(Unit)
            }
        connectionService.eventBus.observe(ContactChangeStatusEvent)
            .subscribe {
                Logger.i { "Update roster!" }
                updateSubject.onNext(Unit)
            }

        updateSubject.onNext(Unit)
    }

	fun findRosterEntry(account: BareJID, jid: BareJID): Maybe<Roster> = queries.query { it.getItem(account, jid) }
		.mapNotNull { it.executeAsOneOrNull() }

    override fun observeAll(): Observable<List<RosterPresenceItem>> = queries.query(RosterDatabaseQueries::loadAll)
        .doOnBeforeSuccess {
            Logger.i { "Załadowano roster." }
        }
        .observe {
            it.executeAsList()
                .map { e ->
                    RosterPresenceItem(
                        account = e.account,
                        jid = e.jid,
                        name = e.name ?: e.jid.toString(),
                        status = getContactStatus(e.account, e.jid)
                    )
                }
        }.powtorzGdy(updateSubject)

//		queries.query(RosterDatabaseQueries::loadAll).observe {
//			it.executeAsList().map { e ->
//					RosterPresenceItem(
//						account = e.account.toBareJID(),
//						jid = e.jid.toBareJID(),
//						name = e.name ?: e.jid,
//						status = ContactStatus.Offline
//					)
//				}
//		}

//	override val rosterUpdates: Observable<List<RosterPresenceItem>> = TODO()
//		get() {
//			val rosterModule = halcyon.getModule<RosterModule>(RosterModule.TYPE)
//			val presenceModule = halcyon.getModule<PresenceModule>(PresenceModule.TYPE)
//
//			fun r2r(item: RosterItem): RosterPresenceItem {
//				return RosterPresenceItem(
//					item.jid,
//					item.name ?: item.jid.toString(),
//					presenceModule.getBestPresenceOf(item.jid).toContactStatus()
//				)
//			}
//
//
//
//			return merge(connectionService.eventBus.eventUpdatedValue<RosterUpdatedEvent, List<RosterPresenceItem>>(
//				RosterUpdatedEvent.TYPE,
//				initialValue = {
//					rosterModule.store.getAllItems().map(
//						::r2r
//					)
//				},
//				mapper = {
//					rosterModule.store.getAllItems().map(
//						::r2r
//					)
//				}),
//						 connectionService.eventBus.eventUpdatedValue<PresenceReceivedEvent, List<RosterPresenceItem>>(
//							 PresenceReceivedEvent.TYPE,
//							 initialValue = {
//								 rosterModule.store.getAllItems().map(::r2r)
//							 },
//							 mapper = {
//								 rosterModule.store.getAllItems().map(::r2r)
//							 })
//			)
//		}

}

fun Presence?.toContactStatus(): ContactStatus {
    return if (this == null) ContactStatus.Offline
    else if (this.type == PresenceType.Error) ContactStatus.Error
    else when (this.show) {
        null -> ContactStatus.Online
        Show.Chat -> ContactStatus.Chat
        Show.Away -> ContactStatus.Away
        Show.XA -> ContactStatus.Xa
        Show.DnD -> ContactStatus.Dnd
        else -> ContactStatus.Offline
    }
}