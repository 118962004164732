package org.tigase.officialtea.common.database

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.roster.Subscription

public data class Roster(
  public val id: Long,
  public val account: BareJID,
  public val jid: BareJID,
  public val name: String?,
  public val ask: Boolean,
  public val subscription: Subscription?,
  public val last_status: Long?,
  public val mixParticipantId: String?
) {
  public override fun toString(): String = """
  |Roster [
  |  id: $id
  |  account: $account
  |  jid: $jid
  |  name: $name
  |  ask: $ask
  |  subscription: $subscription
  |  last_status: $last_status
  |  mixParticipantId: $mixParticipantId
  |]
  """.trimMargin()

  public class Adapter(
    public val accountAdapter: ColumnAdapter<BareJID, String>,
    public val jidAdapter: ColumnAdapter<BareJID, String>,
    public val subscriptionAdapter: ColumnAdapter<Subscription, String>
  )
}
