package org.tigase.officialtea.common.main.components.accountStatus

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.observable.combineLatest
import com.badoo.reaktive.observable.map
import com.badoo.reaktive.observable.observeOn
import com.badoo.reaktive.observable.startWithValue
import com.badoo.reaktive.scheduler.mainScheduler
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.main.components.accountStatus.AccountStatusStore.Intent
import org.tigase.officialtea.common.main.components.accountStatus.AccountStatusStore.Label
import org.tigase.officialtea.common.services.AvatarsCacheService
import org.tigase.officialtea.common.services.ServiceKeeper
import tigase.halcyon.core.AbstractHalcyon
import tigase.halcyon.core.HalcyonStateChangeEvent
import tigase.halcyon.rx.observe

internal interface AccountStatusStore : Store<Intent, AccountStatus.Model, Label> {

    sealed interface Intent


    sealed interface Label
}

internal class AccountStatusStoreFactory(
    private val storeFactory: StoreFactory,
    private val serviceKeeper: ServiceKeeper,
    private val componentContext: ComponentContext
) {

    fun create(): AccountStatusStore =
        object : AccountStatusStore, Store<Intent, AccountStatus.Model, Label> by storeFactory.create(
            name = "AccountStatusStore",
            initialState = AccountStatus.Model(displayName = "Tygrys"),
            bootstrapper = SimpleBootstrapper(Unit),
            executorFactory = ::ExecutorImpl,
            reducer = ReducerImpl
        ) {}

    private sealed interface Msg {
        data class DataLoaded(val data: AccountStatus.Model) : Msg
    }

    private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, AccountStatus.Model, Msg, Label>() {
        override fun executeIntent(intent: Intent, getState: () -> AccountStatus.Model) {
        }

        override fun executeAction(action: Unit, getState: () -> AccountStatus.Model) {
            combineLatest(
                AvatarsCacheService.observer().startWithValue(null),
                serviceKeeper.connectionService.eventBus.observe(HalcyonStateChangeEvent)
                    .startWithValue(
                        HalcyonStateChangeEvent(
                            AbstractHalcyon.State.Stopped,
                            AbstractHalcyon.State.Stopped
                        )
                    ),
                serviceKeeper.accountsService.observeAll(),
            ) { changedAvatar, e,
                list ->
                Logger.i("AccountStatusStore") { "processing $changedAvatar    ${e.newState}       ${list.size}" }
                list.minByOrNull { it.id }
            }.observeOn(mainScheduler)
                .map { account ->
                    convertToState(account)
                }
                .map(Msg::DataLoaded)
                .subscribeScoped(onNext = ::dispatch)
        }

        private fun convertToState(account: Accounts?): AccountStatus.Model {
            return if (account == null) AccountStatus.Model(displayName = "Tygrys") else
                AccountStatus.Model(
                    avatarJid = if (AvatarsCacheService.isStored(account.userjid)) account.userjid else null,
                    jid = account.userjid,
                    displayName = account.nickname ?: account.userjid.localpart ?: "Tygrys",
                    state = serviceKeeper.connectionService[account.userjid]?.state ?: AbstractHalcyon.State.Stopped
                )
        }

    }

    private object ReducerImpl : Reducer<AccountStatus.Model, Msg> {
        override fun AccountStatus.Model.reduce(msg: Msg): AccountStatus.Model =
            when (msg) {
                is Msg.DataLoaded -> msg.data
            }
    }
}
