import androidx.compose.runtime.Composable
import co.touchlab.kermit.Logger
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.hsl
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.tigase.officialtea.common.services.AvatarsCacheService
import org.tigase.officialtea.common.utils.initials
import tigase.halcyon.core.toBase64
import tigase.halcyon.core.xmpp.JID
import tigase.halcyon.core.xmpp.bareJID
import tigase.halcyon.core.xmpp.modules.color.ColorGenerationModule

@Composable
fun Avatar(jid: JID?, name: String?, extraClass: String? = null) {
//	Img(
//		"https://www.shareicon.net/data/2016/07/05/791224_man_512x512.png",
//		"User Avatar",
//		attrs = { classes("avatar-item") })

    val data = jid?.let { AvatarsCacheService.getAvatarRecord(it.bareJID) }
    if (data != null) {
        Logger.i("Avatar") { "Data $jid/$name available" }
        val type = data.type ?: "image/jpeg"
        Img(attrs = { classes("avatar-item") }, src = "data:$type;base64, ${data.avatarData.toBase64()}")
    } else if (jid == null) {
        Logger.i("Avatar") { "Data null/$name not available" }
        Div(attrs = {
            classes("avatar-item")
            extraClass?.let { classes(it) }
            style { backgroundColor(ColorGenerationModule.calculateColor(name ?: "").toCSSColorValue()) }
        }) { Text(initials(name)) }
    } else {
        Logger.i("Avatar") { "Data $jid/$name not available" }
        Div(attrs = {
            classes("avatar-item")
            extraClass?.let { classes(it) }
            style { backgroundColor(ColorGenerationModule.calculateColor(jid).toCSSColorValue()) }
        }) { Text(initials(name, jid.bareJID.toString())) }
    }
}

fun tigase.halcyon.core.xmpp.modules.color.Color.toCSSColorValue() = hsl(this.h, this.s, this.l)