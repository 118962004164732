package org.tigase.officialtea.common.database

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.chatmarkers.ChatMarkersModule

public data class ChatMarkers(
  public val openchat_id: Long,
  public val sender_nick: String,
  public val sender_id: String,
  public val sender_jid: BareJID,
  public val timestamp: Instant,
  public val type: ChatMarkersModule.Marker
) {
  public override fun toString(): String = """
  |ChatMarkers [
  |  openchat_id: $openchat_id
  |  sender_nick: $sender_nick
  |  sender_id: $sender_id
  |  sender_jid: $sender_jid
  |  timestamp: $timestamp
  |  type: $type
  |]
  """.trimMargin()

  public class Adapter(
    public val sender_jidAdapter: ColumnAdapter<BareJID, String>,
    public val timestampAdapter: ColumnAdapter<Instant, Long>,
    public val typeAdapter: ColumnAdapter<ChatMarkersModule.Marker, String>
  )
}
