package org.tigase.officialtea.common.services

import co.touchlab.kermit.Logger
import com.badoo.reaktive.maybe.Maybe
import com.badoo.reaktive.maybe.maybe
import com.badoo.reaktive.single.Single
import com.badoo.reaktive.single.single
import kotlinx.browser.localStorage
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.get
import org.tigase.officialtea.common.database.Attachments
import org.w3c.files.FileReader
import tigase.halcyon.core.fromBase64
import tigase.halcyon.core.toBase64
import tigase.halcyon.core.xmpp.nextUIDLongs

actual class AttachmentProvider {

    actual fun download(attachment: Attachments): Maybe<DownloadResult> = maybe {
        it.onComplete()
    }

    actual fun loadPreview(attachmentId: Long, attachmentLocalFile: String?): ByteArray? {
        return attachmentLocalFile?.let { id ->
            localStorage.getItem("attachment.$id")?.fromBase64()
        }
    }

    actual fun prepareAttachment(fileToUpload: FileToUpload): Single<PreparedAttachment> = single { emiter ->
        val id = nextUIDLongs()
        val reader = FileReader()
        reader.onerror = {
            emiter.onError(RuntimeException("$it"))
        }
        reader.onload = {
            localStorage.setItem("attachment.$id", ((it.target as FileReader).result as ArrayBuffer).toBase64())
            Logger.i { "Saved att with id=$id" }
            emiter.onSuccess(
                PreparedAttachment(
                    fileName = fileToUpload.file.name,
                    localFile = id,
                    size = fileToUpload.file.size.toLong(),
                    contentType = fileToUpload.file.type
                )
            )
        }
        reader.readAsArrayBuffer(fileToUpload.file)
    }

}

fun ArrayBuffer.toBase64(): String {
    val arr = Uint8Array(this)
    val res = ByteArray(arr.length)
    for (i in 0 until arr.length) {
        res[i] = arr[i]
    }
    return res.toBase64()
}