package org.tigase.officialtea.common.main.settings.preferences

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.russhwolf.settings.ExperimentalSettingsApi
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.services.settings.ApplicationSettings

@ExperimentalSettingsApi
class PreferencesComponent(
	componentContext: ComponentContext,
	storeFactory: StoreFactory,
	serviceKeeper: ServiceKeeper,
) : Preferences, ComponentContext by componentContext {

	override val settings: ApplicationSettings = serviceKeeper.applicationPreferences

}