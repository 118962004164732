package org.tigase.officialtea.common.main.components.accounts

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.store.StoreFactory

import org.tigase.officialtea.common.services.AccountsService
import org.tigase.officialtea.common.utils.asValue
import org.tigase.officialtea.common.utils.getStore

class AccountsComponent(
    componentContext: ComponentContext,
    storeFactory: StoreFactory,
    private val service: AccountsService
): AccountsInt, ComponentContext by componentContext {
    private val store = instanceKeeper.getStore {
        AccountsStoreProvider(storeFactory = storeFactory, service = service).provide()
    }
    override val models: Value<AccountsInt.Model> = store.asValue()
    override fun loadActiveAccounts() {
        store.accept(AccountsStore.Intent.LoadActiveAccounts)
    }


}