import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.jetbrains.subscribeAsState
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.tigase.officialtea.common.main.components.accountStatus.AccountStatus

@Composable
fun AccountStatusPanel(accountStatus: AccountStatus) {
    val state by accountStatus.models.subscribeAsState()

    Div({
        style {
            paddingTop(10.px)
            paddingBottom(30.px)
        }
    }) {
        Div({
            style {
                display(DisplayStyle.Flex)
                alignItems(AlignItems.Center)
            }
        }) {
            Div({
                style {
                    width(64.px)
                    height(64.px)
                }
            }) { Avatar(state.avatarJid, state.displayName) }
            Div({ style { marginLeft(10.px) } }) {
                Div({
                    style {
                        fontSize(18.px)
                        fontWeight("bold")
                    }
                }) {
                    Text(state.displayName)
                }
                Div({
                    style {
                        fontSize(9.px)
                        fontStyle("italic")
                        marginTop(4.px)
                    }
                }) {
                    Text(state.state.name)
                }
            }
        }
    }
}

/*

MenuItem(
active = false,
icon = { Avatar(state.jid, state.displayName) },
content = {
    Div {
        Div({ classes("name") }) {
            Text(state.displayName)
        }
        Div({
            style {
                fontSize(9.px)
                fontStyle("itelic")
            }
        }) {
            Text(state.state.name)
        }
    }
})*/
