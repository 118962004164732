package org.tigase.officialtea.common.main.settings.themes

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import org.tigase.officialtea.common.main.settings.themes.ThemeEditorStore.Intent
import org.tigase.officialtea.common.main.settings.themes.ThemeEditorStore.Label
import org.tigase.officialtea.common.main.settings.themes.Themes.State

interface ThemeEditorStore : Store<Intent, State, Label> {

	sealed interface Intent {

		data class SetField(val field: Themes.Field, val value: String) : Intent
		data class SetIsLight(val value: Boolean) : Intent
		data class SetExportable(val value: String) : Intent
	}

	sealed interface Label {

		data class UpdateTheme(
			val primary: Int,
			val primaryVariant: Int,
			val secondary: Int,
			val secondaryVariant: Int,
			val background: Int,
			val surface: Int,
			val error: Int,
			val onPrimary: Int,
			val onSecondary: Int,
			val onBackground: Int,
			val onSurface: Int,
			val onError: Int,
			val isLight: Boolean,
		) : Label

	}
}

internal class ThemeEditorStoreProvider(
	private val storeFactory: StoreFactory,
) {

	fun provide(): ThemeEditorStore = object : ThemeEditorStore, Store<Intent, State, Label> by storeFactory.create(
		name = "ThemeEditorStore",
		initialState = State(),
		bootstrapper = SimpleBootstrapper(Unit),
		executorFactory = ::ExecutorImpl,
		reducer = ReducerImpl
	) {}

	private sealed interface Result {

		data class NewState(val state: State) : Result
	}

	private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, State, Result, Label>() {

		fun toCSV(state: State) = buildString {
			append("#").append(normalize(state.primary))
				.append(";")
			append("#").append(normalize(state.primaryVariant))
				.append(";")
			append("#").append(normalize(state.secondary))
				.append(";")
			append("#").append(normalize(state.secondaryVariant))
				.append(";")
			append("#").append(normalize(state.background))
				.append(";")
			append("#").append(normalize(state.surface))
				.append(";")
			append("#").append(normalize(state.error))
				.append(";")
			append("#").append(normalize(state.onPrimary))
				.append(";")
			append("#").append(normalize(state.onSecondary))
				.append(";")
			append("#").append(normalize(state.onBackground))
				.append(";")
			append("#").append(normalize(state.onSurface))
				.append(";")
			append("#").append(normalize(state.onError))
				.append(";")
			append(state.isLight)
		}

		private fun normalize(v: String) = "${v}00000000".substring(0, 8)

		private fun parseColors(csv: String): State {
			val tokens = csv.lowercase()
				.split(";")
				.map { it.trim() }
			val isLight = tokens.last()
				.toBoolean()
			val colVal = tokens.subList(0, tokens.size - 1)
				.asSequence()
				.map { it.replace("#","") }
				.map { normalize(it) }
//				.map { it.toULong(16) }
//				.map { it.toLong() }
//				.map { Color(it) }
				.toList()
			val s = State(
				primary = colVal[0],
				primaryVariant = colVal[1],
				secondary = colVal[2],
				secondaryVariant = colVal[3],
				background = colVal[4],
				surface = colVal[5],
				error = colVal[6],
				onPrimary = colVal[7],
				onSecondary = colVal[8],
				onBackground = colVal[9],
				onSurface = colVal[10],
				onError = colVal[11],
				isLight = isLight,
				""
			)
			return s.copy(exportable = toCSV(s))
		}

		override fun executeIntent(intent: Intent, getState: () -> State) = when (intent) {
			is Intent.SetExportable -> setExportableString(intent.value, getState())
			is Intent.SetField -> setField(intent.field, intent.value, getState())
			is Intent.SetIsLight -> setIsLight(intent.value, getState())
		}

		override fun executeAction(action: Unit, getState: () -> State) {
			val s = getState()
			dispatch(Result.NewState(s.copy(exportable = toCSV(s))))
		}

		private fun createLabel(state: State): Label.UpdateTheme {
			return Label.UpdateTheme(
				primary = normalize(state.primary).toUInt(16)
					.toInt(),
				primaryVariant = normalize(state.primaryVariant).toUInt(16)
					.toInt(),
				secondary = normalize(state.secondary).toUInt(16)
					.toInt(),
				secondaryVariant = normalize(state.secondaryVariant).toUInt(16)
					.toInt(),
				background = normalize(state.background).toUInt(16)
					.toInt(),
				surface = normalize(state.surface).toUInt(16)
					.toInt(),
				error = normalize(state.error).toUInt(16)
					.toInt(),
				onPrimary = normalize(state.onPrimary).toUInt(16)
					.toInt(),
				onSecondary = normalize(state.onSecondary).toUInt(16)
					.toInt(),
				onBackground = normalize(state.onBackground).toUInt(16)
					.toInt(),
				onSurface = normalize(state.onSurface).toUInt(16)
					.toInt(),
				onError = normalize(state.onError).toUInt(16)
					.toInt(),
				isLight = state.isLight
			)
		}

		private fun setExportableString(value: String, state: State) {
			val s = try {
				parseColors(value)
			} catch (e: Throwable) {
				e.printStackTrace()
				state.copy(exportable = value)
			}
			dispatch(Result.NewState(s))
			publish(createLabel(s))
		}

		private fun setIsLight(value: Boolean, state: State) {
			val s = state.copy(isLight = value)

			dispatch(Result.NewState(s.copy(exportable = toCSV(s))))
			publish(createLabel(s))
		}

		private fun setField(field: Themes.Field, value: String, state: State) {
			val n = normalize(value)
			val v = if (value.isBlank()) {
				n
			} else (if (value.length < 9) value else n).let { v ->
				v.toULongOrNull(16)
					?.let {
						v
					}
			} ?: return

			val s = when (field) {
				Themes.Field.Primary -> state.copy(primary = v)
				Themes.Field.PrimaryVariant -> state.copy(primaryVariant = v)
				Themes.Field.Secondary -> state.copy(secondary = v)
				Themes.Field.SecondaryVariant -> state.copy(secondaryVariant = v)
				Themes.Field.Background -> state.copy(background = v)
				Themes.Field.Surface -> state.copy(surface = v)
				Themes.Field.Error -> state.copy(error = v)
				Themes.Field.OnPrimary -> state.copy(onPrimary = v)
				Themes.Field.OnSecondary -> state.copy(onSecondary = v)
				Themes.Field.OnBackground -> state.copy(onBackground = v)
				Themes.Field.OnSurface -> state.copy(onSurface = v)
				Themes.Field.OnError -> state.copy(onError = v)
			}

			dispatch(Result.NewState(s.copy(exportable = toCSV(s))))
			publish(createLabel(s))
		}

	}

	private object ReducerImpl : Reducer<State, Result> {

		override fun State.reduce(result: Result): State = when (result) {
			is Result.NewState -> result.state
		}
	}
}

