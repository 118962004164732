package org.tigase.officialtea.common.services

import com.arkivanov.decompose.value.Value
import com.badoo.reaktive.completable.Completable
import com.badoo.reaktive.maybe.Maybe
import com.badoo.reaktive.observable.Observable
import com.badoo.reaktive.single.Single
import kotlinx.datetime.Instant
import org.tigase.officialtea.common.database.*
import tigase.halcyon.core.xmpp.BareJID

interface ChatsService {

	val unreadCount: Value<Int>
	val notification: Observable<Array<NotificationData>>

	fun selectedChat(chatId: Long?)

	fun observeOpenChats(): Observable<List<OpenChatUnreadsItem>>
	fun observeChats(chatId: Long, size: Long): Observable<List<MessageWithAttachment>>
	fun observeMessageCount(chatId: Long): Observable<Long>
	fun observeMarkers(chatId: Long): Observable<List<ChatMarkers>>

	fun openChatWith(account: BareJID, jid: BareJID): Single<OpenChats>

	fun findOpenChat(chatId: Long): Single<OpenChats?>
	fun findOpenChat(account: BareJID, jid: BareJID): Single<OpenChats?>
	fun sendMessage(openChat: OpenChats, text: String?, fileToUpload: FileToUpload?, editedMessageId: Long? = null)
	fun markAsRead(messages: MessageWithAttachment)
	fun closeChat(openChatId: Long): Completable
	fun markCurrentAsRead()
	fun loadLastMessage(openChatId: Long): Maybe<Messages>
	fun createMixChannel(account: BareJID, participants: List<BareJID>, channelJID: String?)

}

data class OpenChatUnreadsItem(
	val openChatId: Long,
	val account: BareJID,
	val jid: BareJID,
	val name: String,
	val type: OpenChatType,
	val unread: Long,
	val contactInRoster: Boolean,
)

data class NotificationItem(val sender: BareJID, val nickName: String, val timestamp: Instant, val message: String)

data class NotificationData(val account: BareJID, val openChat: OpenChats, val items: Array<NotificationItem>) {

	override fun equals(other: Any?): Boolean {
		if (this === other) return true
		if (other !is NotificationData) return false

		if (account != other.account) return false
		if (openChat != other.openChat) return false
		if (!items.contentEquals(other.items)) return false

		return true
	}

	override fun hashCode(): Int {
		var result = account.hashCode()
		result = 31 * result + openChat.hashCode()
		result = 31 * result + items.contentHashCode()
		return result
	}
}
