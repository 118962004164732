package org.tigase.officialtea.common.main.settings.accountslist

import com.arkivanov.decompose.value.Value
import org.tigase.officialtea.common.database.Accounts
import tigase.halcyon.core.AbstractHalcyon

interface AccountsList {

	val models: Value<Model>

	fun select(item: Accounts)

	fun onAddClick()

	data class Model(val items: List<Pair<Accounts, AbstractHalcyon.State>> = emptyList())

}