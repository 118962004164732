package org.tigase.officialtea.common.main.components.chat.mixparticipants

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.scheduler.ioScheduler
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.map
import com.badoo.reaktive.single.observeOn
import com.badoo.reaktive.single.subscribe
import com.badoo.reaktive.single.subscribeOn
import org.tigase.officialtea.common.main.components.chat.ChatData
import org.tigase.officialtea.common.main.components.chat.mixparticipants.MixParticipants.Model
import org.tigase.officialtea.common.main.components.chat.mixparticipants.MixParticipantsStore.Intent
import org.tigase.officialtea.common.main.components.chat.mixparticipants.MixParticipantsStore.Label
import org.tigase.officialtea.common.services.ServiceKeeper
import tigase.halcyon.core.xmpp.modules.mix.MIXModule
import tigase.halcyon.core.xmpp.modules.pubsub.PubSubModule
import tigase.halcyon.core.xmpp.toBareJID
import tigase.halcyon.rx.asSingle

internal interface MixParticipantsStore : Store<Intent, Model, Label> {

	sealed interface Intent

	sealed interface Label
}

internal class MixParticipantsStoreProvider(
	private val storeFactory: StoreFactory,
	private val chatData: ChatData,
	private val serviceKeeper: ServiceKeeper,
) {

	fun provide(): MixParticipantsStore =
		object : MixParticipantsStore, Store<Intent, Model, Label> by storeFactory.create(
			name = "MixParticipantsStore", initialState = Model(), bootstrapper = SimpleBootstrapper(
				Unit
			), executorFactory = ::ExecutorImpl, reducer = ReducerImpl
		) {}

	private sealed interface Result {

		data class ParticipantsLoaded(val participants: List<MixParticipants.Participant>) : Result

	}

	private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, Model, Result, Label>() {

		override fun executeAction(action: Unit, getState: () -> Model) {
			serviceKeeper.connectionService[chatData.account]!!.getModule<PubSubModule>(PubSubModule.TYPE)
				.retrieveItem(jid = chatData.jid, node = MIXModule.NODE_PARTICIPANTS)
				.asSingle()
				.subscribeOn(ioScheduler)
				.map {
					Result.ParticipantsLoaded(it.items.map {
						MixParticipants.Participant(
							id = it.id,
							jid = it.content!!.getFirstChild("jid")!!.value!!.toBareJID(),
							nick = it.content!!.getFirstChild("nick")!!.value!!,
						)
					})
				}
				.observeOn(mainScheduler)
				.subscribe(onSuccess = ::dispatch)
		}

	}

	private object ReducerImpl : Reducer<Model, Result> {

		override fun Model.reduce(msg: Result): Model = when (msg) {
			is Result.ParticipantsLoaded -> copy(participants = msg.participants)
		}
	}
}
