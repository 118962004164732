package org.tigase.officialtea.common.main.components.sidemenu

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.completable.subscribe
import com.badoo.reaktive.observable.map
import com.badoo.reaktive.observable.observeOn
import com.badoo.reaktive.scheduler.mainScheduler
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.database.OpenChatType
import org.tigase.officialtea.common.main.components.sidemenu.SideMenu.Model
import org.tigase.officialtea.common.main.components.sidemenu.SideMenuStore.Intent
import org.tigase.officialtea.common.main.components.sidemenu.SideMenuStore.Label
import org.tigase.officialtea.common.services.ChatsService
import org.tigase.officialtea.common.services.OpenChatUnreadsItem
import org.tigase.officialtea.common.services.RosterPresenceService

internal interface SideMenuStore : Store<Intent, Model, Label> {

	sealed interface Intent {

		data class ClickItem(val item: SideMenu.MenuItem) : Intent
		data class SelfActionMenu(val active: Boolean) : Intent
		data class ToggleSelection(val item: Collection<SideMenu.MenuItem>, val forceSelect: Boolean) : Intent
		object ClearSelection : Intent
		data class CloseChat(val item: SideMenu.MenuItem.ChatMenuItem) : Intent
		data class SetActiveAccount(val item: Accounts) : Intent
		data class SetSearchQuery(val searchQuery: String) : Intent
	}

	sealed interface Label {

		data class ItemClicked(val item: SideMenu.MenuItem) : Label
	}
}

internal class SideMenuStoreProvider(
	private val storeFactory: StoreFactory,
	private val chatsService: ChatsService,
	private val rosterPresenceService: RosterPresenceService,
	private val generalMenuItems: List<SideMenu.MenuItem.GeneralMenuItem>,
) {

	fun provide(): SideMenuStore = object : SideMenuStore, Store<Intent, Model, Label> by storeFactory.create(
		name = "SideMenuStore",
		initialState = Model(generalMenuItems = generalMenuItems, opened = generalMenuItems.first()),
		bootstrapper = SimpleBootstrapper(Unit),
		executorFactory = ::ExecutorImpl,
		reducer = ReducerImpl
	) {}


	private sealed interface Result {

		data class MenuItemClicked(val selected: SideMenu.MenuItem) : Result
		data class ActionModal(val active: Boolean) : Result
		data class OpenChatsLoaded(
			val items: List<OpenChatUnreadsItem>,
			val mix: List<OpenChatUnreadsItem>,
			val fromUnknown: List<OpenChatUnreadsItem>,
			val unreadChats: List<OpenChatUnreadsItem>,
		) : Result

		data class SelectedItems(val items: Set<SideMenu.MenuItem>) : Result
		data class SelectionMode(val active: Boolean) : Result
		data class SearchQuery(val searchQuery: String) : Result

	}

	private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, Model, Result, Label>() {

		override fun executeAction(action: Unit, getState: () -> Model) {
			chatsService.observeOpenChats()
				.observeOn(mainScheduler)
				.map {
					Result.OpenChatsLoaded(items = it.filter { it.type == OpenChatType.Direct && it.contactInRoster },
										   fromUnknown = it.filter { it.type == OpenChatType.Direct && !it.contactInRoster },
										   mix = it.filter { it.type == OpenChatType.Mix && it.contactInRoster },
											unreadChats = it.filter { it.unread > 0 })
				}
				.subscribeScoped(onNext = ::dispatch)
		}

//		private fun actionModal(selectedItem: Accounts?, state: Model) {
//				dispatch(SideMenuStoreProvider.Result.ActionModal())
//		}

		override fun executeIntent(intent: Intent, getModel: () -> Model) = when (intent) {
			is Intent.ClickItem -> doClickItem(intent.item, getModel())
			is Intent.CloseChat -> doCloseChat(intent.item)
			is Intent.ToggleSelection -> doToggleSelectionItem(intent.item, intent.forceSelect, getModel())
			is Intent.SelfActionMenu -> doOpenActionMenu(intent.active)
			is Intent.SetActiveAccount -> doSetActiveAccount(intent.item, getModel())
			is Intent.SetSearchQuery -> doChangeSearchQuery(intent.searchQuery)
			Intent.ClearSelection -> doClearSelection()
		}

		private fun doClearSelection() {
			dispatch(Result.SelectedItems(emptySet()))
			dispatch(Result.SelectionMode(false))
		}

		private fun doChangeSearchQuery(searchQuery: String){

		}

		private fun doToggleSelectionItem(items: Collection<SideMenu.MenuItem>, forceSelect: Boolean, model: Model) {
			if (!model.isInSelectionMode) {
				dispatch(Result.SelectionMode(true))
			}
			val resultItems = model.selectedItems.toMutableSet()
			items.forEach { item ->
				if (forceSelect || !resultItems.contains(item)) {
					resultItems.add(item)
				} else {
					resultItems.remove(item)
				}
			}
			dispatch(Result.SelectedItems(resultItems))
		}

		private fun doCloseChat(item: SideMenu.MenuItem.ChatMenuItem) {
			chatsService.closeChat(item.chat.openChatId)
				.subscribe { }
		}
		private fun doOpenActionMenu(item: Boolean) {
			dispatch(Result.ActionModal(item))
		}

		private fun doSetActiveAccount(item: Accounts, model: Model) {
			model.activeAccount = item
		}


		private fun doClickItem(item: SideMenu.MenuItem, model: Model) {
			if (model.isInSelectionMode) {
				return
			}
			when (item) {
				is SideMenu.MenuItem.DirectChatMenuItem -> chatsService.selectedChat(item.chat.openChatId)
				is SideMenu.MenuItem.MixChatMenuItem -> chatsService.selectedChat(item.chat.openChatId)
				else -> chatsService.selectedChat(null)
			}
			dispatch(Result.MenuItemClicked(item))
			publish(Label.ItemClicked(item))
		}
	}

	private object ReducerImpl : Reducer<Model, Result> {

		override fun Model.reduce(result: Result): Model = when (result) {
			is Result.MenuItemClicked -> copy(opened = result.selected)
			is Result.SearchQuery -> copy(searchQuery = result.searchQuery)
			is Result.OpenChatsLoaded -> copy(directChatList = result.items.map { openChat ->
				SideMenu.MenuItem.DirectChatMenuItem(openChat, "avatar_.png", openChat.name)
			}, fromUnknownChatList = result.fromUnknown.map { openChat ->
				SideMenu.MenuItem.DirectChatMenuItem(openChat, "avatar_.png", openChat.name)
			}, mix = result.mix.map { openChat ->
				SideMenu.MenuItem.MixChatMenuItem(openChat, "avatar_.png", openChat.name)
			}, unreadChats = result.unreadChats.map { openChat ->
				when(openChat.type){
					OpenChatType.Direct -> SideMenu.MenuItem.DirectChatMenuItem(openChat, "avatar_.png", openChat.name)
					OpenChatType.Mix -> SideMenu.MenuItem.MixChatMenuItem(openChat, "avatar_.png", openChat.name)
				}

			}


			)
			is Result.ActionModal -> copy(actionModal = result.active)

			is Result.SelectedItems -> copy(selectedItems = result.items)
			is Result.SelectionMode -> copy(isInSelectionMode = result.active)
		}
	}
}
