package org.tigase.officialtea.common.main.settings

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.labels
import com.badoo.reaktive.base.Consumer
import com.badoo.reaktive.base.invoke
import com.badoo.reaktive.observable.observeOn
import com.badoo.reaktive.scheduler.mainScheduler
import org.tigase.officialtea.common.i18n.AppMessages
import org.tigase.officialtea.common.main.components.chat.autoSubscribeIn
import org.tigase.officialtea.common.main.components.sidemenu.SideMenuStore
import org.tigase.officialtea.common.utils.asValue

class OptionsComponent(
	componentContext: ComponentContext,
	storeFactory: StoreFactory,
	private val consumer: Consumer<SettingsComponent.SettingsConfiguration>,
) : Options, ComponentContext by componentContext {

	private val optionsItems = listOf(
		Options.MenuItem(AppMessages.settings_section_preferences(),
						 "preferences",
						 action = { SettingsComponent.SettingsConfiguration.AppPreferences }),
		Options.MenuItem(
			AppMessages.settings_section_theme(),
			"palette",
			action = { SettingsComponent.SettingsConfiguration.Appearance }),
		Options.MenuItem(AppMessages.settings_section_accounts(),
						 "settings_accounts",
						 action = { SettingsComponent.SettingsConfiguration.AccountsList }),
	)

	private val store = instanceKeeper.getStore {
		OptionsStoreProvider(
			storeFactory = storeFactory, items = optionsItems
		).provide()
	}

	override val models: Value<Options.Model> = store.asValue()

	init {
		store.labels.observeOn(mainScheduler)
			.autoSubscribeIn(lifecycle = lifecycle) {
				if(it is OptionsStore.Label.ItemClicked)
				consumer(it.item.action())
			}
	}

	override fun select(item: Options.MenuItem) {
		store.accept(OptionsStore.Intent.SelectItem(item))
//		consumer(item.action())
	}
}