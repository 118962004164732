package org.tigase.officialtea.common.main.components.support

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.arkivanov.mvikotlin.core.store.StoreFactory
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.asValue
import org.tigase.officialtea.common.utils.getStore
import tigase.halcyon.core.xmpp.BareJID

class SupportComponent(
    componentContext: ComponentContext,
    storeFactory: StoreFactory,
    serviceKeeper: ServiceKeeper,
) : Support, ComponentContext by componentContext{

    private val store = instanceKeeper.getStore {
        SupportStoreProvider(storeFactory, serviceKeeper).provide()
    }

    override val models: Value<Support.Model> = store.asValue()

    override fun onSubmit() {
        TODO("Not yet implemented")
    }

    override fun onRedirect() {
        // admin function
        TODO()
    }

//    override fun onSubmit(ticketInfo: SupportTicketInfo) {
//        store.accept(SupportStore.Intent.Submit(ticketInfo))
//    }

    override fun onConnectToSupportChannel(account: BareJID) {
        store.accept(SupportStore.Intent.connectSupportChannel(account))
    }

    override fun connectToSupportChannel() {
        store.accept(SupportStore.Intent.AutoConnectSupportChannel)
    }

    // perhaps not necessary?
    sealed class SupportConfiguration : Parcelable {
        @Parcelize
        object SupportChatroom : SupportConfiguration()
    }
}