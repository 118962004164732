package org.tigase.officialtea.common.database

import kotlin.String
import kotlinx.datetime.Instant
import tigase.halcyon.core.xmpp.modules.chatmarkers.ChatMarkersModule

public data class FindMarker(
  public val type: ChatMarkersModule.Marker,
  public val timestamp: Instant
) {
  public override fun toString(): String = """
  |FindMarker [
  |  type: $type
  |  timestamp: $timestamp
  |]
  """.trimMargin()
}
