package org.tigase.officialtea.common.main.components.chat

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.replaceCurrent
import com.arkivanov.decompose.value.MutableValue
import com.arkivanov.mvikotlin.core.store.StoreFactory
import org.tigase.officialtea.common.main.components.chat.mixparticipants.MixParticipantsComponent
import org.tigase.officialtea.common.main.components.vcard.VCardPanelComponent
import org.tigase.officialtea.common.services.ServiceKeeper

class ChatPanelComponent(
	private val componentContext: ComponentContext,
	private val storeFactory: StoreFactory,
	chatDetails: ChatData,
	private val serviceKeeper: ServiceKeeper,
) : ChatPanel, ComponentContext by componentContext {

	override val models: MutableValue<ChatData> = MutableValue(chatDetails)

	override val features: MutableValue<Set<ChatPanel.Feature>> = MutableValue(
		when (chatDetails.type) {
			ChatPanel.Type.MIX -> setOf(ChatPanel.Feature.MIXParticipants)
			ChatPanel.Type.Direct -> setOf(
				ChatPanel.Feature.VCard
			)
		}
	)

	private val router = StackNavigation<ChatPanel.Configuration>()
	private val stack = childStack(
		key = "ChatPanelRouter",
		source = router,
		initialConfiguration = ChatPanel.Configuration.Chat,
		handleBackButton = true,
		childFactory = ::createChild,
	)

	override val routerState = stack

	private fun toggleVisiblePanel(config: ChatPanel.Configuration) {
		if (stack.value.active.configuration == config) {
			router.replaceCurrent(ChatPanel.Configuration.Chat)
		} else {
			router.replaceCurrent(config)
		}
	}

	private fun createChild(
		configuration: ChatPanel.Configuration,
		componentContext: ComponentContext,
	): ChatPanel.Child = when (configuration) {
		ChatPanel.Configuration.Chat -> ChatPanel.Child.Chat(
			ChatViewComponent(
				componentContext,
				models.value,
				storeFactory,
				serviceKeeper.chatsService,
				serviceKeeper.connectionService
			)
		)

		ChatPanel.Configuration.ChannelParticipants -> ChatPanel.Child.ChannelParticipants(
			MixParticipantsComponent(
				componentContext, storeFactory, models.value, serviceKeeper
			)
		)

		ChatPanel.Configuration.ContactVCard -> ChatPanel.Child.ContactVCard(
			VCardPanelComponent(
				componentContext, storeFactory, serviceKeeper, models.value.account, models.value.jid
			)
		)
	}

	override fun onPressedParticipants() = toggleVisiblePanel(ChatPanel.Configuration.ChannelParticipants)

	override fun onPressedVCard() = toggleVisiblePanel(ChatPanel.Configuration.ContactVCard)

}