package org.tigase.officialtea.common.database

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import tigase.halcyon.core.xmpp.BareJID

public data class Attachments(
  public val id: Long,
  public val openchat_id: Long?,
  public val account: BareJID,
  public val origin_stanza_id: String,
  public val url: String?,
  public val fileName: String?,
  public val type: String?,
  public val size: Long?,
  public val downloaded: Boolean,
  public val localFile: String?
) {
  public override fun toString(): String = """
  |Attachments [
  |  id: $id
  |  openchat_id: $openchat_id
  |  account: $account
  |  origin_stanza_id: $origin_stanza_id
  |  url: $url
  |  fileName: $fileName
  |  type: $type
  |  size: $size
  |  downloaded: $downloaded
  |  localFile: $localFile
  |]
  """.trimMargin()

  public class Adapter(
    public val accountAdapter: ColumnAdapter<BareJID, String>
  )
}
