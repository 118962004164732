package org.tigase.officialtea.common.main.components.chat.messagelist

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.observable.map
import com.badoo.reaktive.observable.observeOn
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.observeOn
import com.badoo.reaktive.single.subscribe
import com.badoo.reaktive.single.subscribeOn
import com.badoo.reaktive.subject.publish.PublishSubject
import org.tigase.officialtea.common.database.MessageState
import org.tigase.officialtea.common.database.MessageWithAttachment
import org.tigase.officialtea.common.database.OpenChats
import org.tigase.officialtea.common.database.powtorzGdy
import org.tigase.officialtea.common.main.components.chat.messagelist.MessageList.Model
import org.tigase.officialtea.common.main.components.chat.messagelist.MessageListStore.Intent
import org.tigase.officialtea.common.main.components.chat.messagelist.MessageListStore.Label
import org.tigase.officialtea.common.services.ChatsService
import kotlin.time.Duration.Companion.minutes

interface MessageListStore : Store<Intent, Model, Label> {

	sealed interface Intent {

		object MarkAsRead : Intent

		object LoadMore : Intent

	}

	sealed interface Label {

		data class MarkAsRead(val msg: MessageWithAttachment) : Label

	}
}

internal class MessageListStoreProvider(
	private val storeFactory: StoreFactory, private val service: ChatsService, private val selectedChatId: Long,
) {

	private enum class Actions { Init
	}

	fun provide(): MessageListStore = object : MessageListStore, Store<Intent, Model, Label> by storeFactory.create(
		name = "MessageListStore", initialState = Model(), bootstrapper = SimpleBootstrapper(
			Actions.Init
		), executorFactory = ::ExecutorImpl, reducer = ReducerImpl
	) {}

	private sealed interface Result {

		data class ChatLoaded(val chat: OpenChats) : Result
		data class MessageListUpdated(val messages: List<MessageWithAttachment>) : Result
		data class MarkersUpdated(val markers: List<MessageList.Mark>) : Result
		data class SetPageSize(val newSize: Long) : Result
		data class SetMessageCount(val count: Long) : Result

	}

	private inner class ExecutorImpl : ReaktiveExecutor<Intent, Actions, Model, Result, Label>() {

		override fun executeIntent(intent: Intent, getState: () -> Model) = when (intent) {
			is Intent.MarkAsRead -> doLoadMessage(getState)
			Intent.LoadMore -> doLoadMore(getState)
		}

		private fun doLoadMore(getState: () -> Model) {
			dispatch(Result.SetPageSize(getState().pageSize + 50))
			doLoadMessage(getState)
		}

		override fun executeAction(action: Actions, getState: () -> Model) {
			service.findOpenChat(selectedChatId)
				.observeOn(mainScheduler)
				.subscribe {
					it?.let {
						dispatch(Result.ChatLoaded(it))
						doLoadMessage(getState)
					}
				}
		}

		private fun doLoadMessage(getState: () -> Model) {
			service.observeMessageCount(selectedChatId)
				.observeOn(mainScheduler)
				.subscribeScoped {
					if (it != getState().messagesCount) {
						dispatch(Result.SetMessageCount(it))
					}
				}

			service.observeChats(selectedChatId, getState().pageSize)
				.observeOn(mainScheduler)
				.map { e ->
					e.filter { message -> message.state == MessageState.IncomingUnread }
						.maxByOrNull { it.timestamp }
						?.let {
							service.markAsRead(it)
							publish(Label.MarkAsRead(it))
						}
					Result.MessageListUpdated(e)
				}
				.subscribeScoped(onNext = ::dispatch)

			service.observeMarkers(selectedChatId)
				.observeOn(mainScheduler)
				.map { markers ->
					Result.MarkersUpdated(markers.map {
						MessageList.Mark(it.sender_jid, it.sender_nick, it.timestamp, it.type)
					})
				}
				.subscribeScoped(onNext = ::dispatch)
		}
	}

	private object ReducerImpl : Reducer<Model, Result> {

		fun mergeX(
			messages: List<MessageWithAttachment>,
			markers: List<MessageList.Mark>,
		): List<MessageList.DisplayRow> {
			val result = mutableListOf<MessageList.DisplayRow>()

			val m = markers.toMutableList()
			messages.forEachIndexed { index, message ->
				if (index != 0 && (!compareDates(messages[index.minus(1)].timestamp, message.timestamp))) {
					result.add(MessageList.DisplayRow.DateSeparator(messages[index.minus(1)].timestamp))
				}

				val mm = m.filter { message.timestamp <= it.timestamp }
				if (mm.isNotEmpty()) {
					result.add(MessageList.DisplayRow.Mark(mm))
					m.removeAll(mm)
				}

				val mergeWithPrevious = (if (index + 1 < messages.size) messages[index + 1] else null)?.let {
					(it.author_jid == message.author_jid) && (message.timestamp.minus(it.timestamp) <= 1.minutes)
				} ?: false

				result.add(MessageList.DisplayRow.Message(message, mergeWithPrevious))

			}

			messages.lastOrNull()
				?.let {
					result.add(MessageList.DisplayRow.DateSeparator(it.timestamp))
				}
			return result
		}

		override fun Model.reduce(result: Result): Model {
			val x = when (result) {
				is Result.MessageListUpdated -> copy(
					messages = result.messages, compiledRows = mergeX(result.messages, this.marks)
				)

				is Result.ChatLoaded -> copy(openChat = result.chat, title = result.chat.name)
				is Result.MarkersUpdated -> copy(
					marks = result.markers, compiledRows = mergeX(this.messages, result.markers)
				)

				is Result.SetPageSize -> copy(
					pageSize = result.newSize, displayLoadMore = result.newSize < this.messagesCount
				)

				is Result.SetMessageCount -> copy(
					messagesCount = result.count, displayLoadMore = this.pageSize < result.count
				)
			}


			return x
		}
	}
}
