package org.tigase.officialtea.common.main.components.chat.messagelist

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.labels
import com.badoo.reaktive.observable.Observable
import com.badoo.reaktive.observable.filter
import com.badoo.reaktive.observable.subscribe
import org.tigase.officialtea.common.database.MessageState
import org.tigase.officialtea.common.services.ChatsService
import org.tigase.officialtea.common.services.FocusService
import org.tigase.officialtea.common.utils.asValue
import org.tigase.officialtea.common.utils.getStore

class MessageListComponent(
	componentContext: ComponentContext,
	storeFactory: StoreFactory,
	private val selectedChatId: Long,
	private val service: ChatsService,
) : MessageList, ComponentContext by componentContext {

	private val store = instanceKeeper.getStore {
		MessageListStoreProvider(storeFactory = storeFactory, service = service, selectedChatId).provide()
	}

	override val labels: Observable<MessageListStore.Label> = store.labels

	private val x = FocusService.observe()
		.filter { it }
		.subscribe {
			store.state.messages.maxByOrNull { it.timestamp }
				?.let {
					if (it.state == MessageState.IncomingUnread) service.markAsRead(it)
				}
		}

	override val models: Value<MessageList.Model> = store.asValue()

	override fun refresh() {
	}

	override fun loadMore() {
		store.accept(MessageListStore.Intent.LoadMore)
	}

}