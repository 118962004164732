package org.tigase.officialtea.common.main.components.chat.mixparticipants

import com.arkivanov.decompose.value.Value
import tigase.halcyon.core.xmpp.BareJID

interface MixParticipants {

	val models: Value<Model>

	data class Participant(val id: String, val jid: BareJID, val nick: String)

	data class Model(val participants: List<Participant> = emptyList())

}