package org.tigase.officialtea.common.services.utils

import co.touchlab.kermit.*
import tigase.halcyon.core.logger.Level
import tigase.halcyon.core.logger.LoggerInternal

class HalcyonKermitLoggerSPI(private val name: String, private val e: Boolean) : LoggerInternal {

    private val tagName = shortName(name)

    private val kermit = Logger(
        tag = tagName, config = StaticConfig(minSeverity = Severity.Debug, logWriterList = listOf(CommonWriter()))
    )

    init {
        Logger.setLogWriters(platformLogWriter())
    }

    private val enabled: Boolean by lazy {
        e && tagName != "tigase.halcyon.core.eventbus.EventBus"
    }

    override fun isLoggable(level: Level): Boolean {
        if (!enabled) return false
        val s = level2Severity(level) ?: return false
        return (kermit.config.minSeverity <= s)
    }

    private fun level2Severity(level: Level): Severity? = when (level) {
        Level.OFF -> null
        Level.SEVERE -> Severity.Error
        Level.WARNING -> Severity.Warn
        Level.INFO -> Severity.Info
        Level.CONFIG -> Severity.Info
        Level.FINE -> Severity.Debug
        Level.FINER -> Severity.Verbose
        Level.FINEST -> Severity.Verbose
        Level.ALL -> Severity.Verbose
    }

    override fun log(level: Level, msg: String, caught: Throwable?) {
        if (!enabled) return
        val severity = level2Severity(level) ?: return

        kermit.log(severity = severity, throwable = caught, message = msg)
    }
}

internal fun shortName(s: String): String {
    val tokens = s.split('.')
    return if (tokens.size == 1) tokens[0] else tokens.subList(0, tokens.size - 1)
        .map { t -> t.elementAt(0) }
        .joinToString(separator = ".", postfix = ".") + tokens[tokens.size - 1]
}