package org.tigase.officialtea.common.main.components.chat

import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import org.tigase.officialtea.common.main.components.chat.mixparticipants.MixParticipants
import org.tigase.officialtea.common.main.components.vcard.VCardPanel

interface ChatPanel {

	val models: Value<ChatData>

	val features: Value<Set<Feature>>

	val routerState: Value<ChildStack<Configuration, Child>>

	fun onPressedParticipants()
	fun onPressedVCard()

	enum class Feature { VCard,
		MIXParticipants
	}

	enum class Type {

		MIX,
		Direct

	}

	sealed class Child {

		data class Chat(val component: ChatView) : Child()
		data class ChannelParticipants(val component: MixParticipants) : Child()
		data class ContactVCard(val component: VCardPanel) : Child()
	}

	sealed class Configuration : Parcelable {

		@Parcelize
		object Chat : Configuration()

		@Parcelize
		object ChannelParticipants : Configuration()

		@Parcelize
		object ContactVCard : Configuration()
	}
}