import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.jetbrains.subscribeAsState
import components.ChatListSection
import components.ChatMenuItem
import components.IconMenuItem
import components.TwoColumnPanel
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.tigase.officialtea.common.i18n.AppMessages
import org.tigase.officialtea.common.main.components.sidemenu.SideMenu
import org.tigase.officialtea.common.main.components.support.Support
import org.tigase.officialtea.common.root.AppRoot

@Composable
fun AppRootContent(component: AppRoot) {
    val stack by component.routerState.subscribeAsState()

    WorkInProgressPanel()

    TwoColumnPanel(header = { AccountStatusPanel(component.accountStatus) }, left = {
        SideMenu(component.sideMenu)
    }, right = {
        when (val instance = stack.active.instance) {
            is AppRoot.Child.Chat -> ChatPanelUI(instance.component)
            is AppRoot.Child.MixChannelBrowser -> MixBrowserContent(instance.component)
            is AppRoot.Child.RosterScreen -> RosterMainUI(instance.component)
            is AppRoot.Child.Settings -> SettingsContent(instance.component)
            is AppRoot.Child.Support -> SupportPanel(instance.component)
            AppRoot.Child.WelcomeScreen -> Text("Hi there")
        }

    })
}

@Composable
fun SupportPanel(component: Support) {

    Div(attrs = {
        style {
            textAlign("center")
            padding(48.px)
        }
    }) {
//		Div(attrs = { classes("otw-chat-header") }) { }

        H1(
            attrs = {
                style {
                    fontSize(42.px)
                }
                classes("h1darkmode")
            }
        ) {
            Text("Questions? Concerns?")
        }

        H3(attrs = {
            style { fontSize(18.px) }
            classes("h1darkmode")
        }) {
            Text("Join support@mix.tigase.io!")
        }
        Div(attrs = { style { margin(20.px) } }) {
            Button(attrs = {
                classes( "bg-[#324167]", "hover:bg-sky-700", "px-5", "py-2", "h-[32px]", "w-[175px]", "rounded-full", "font-[500]", "text-white", "text-[15px]")
                onClick {
                    component.connectToSupportChannel()
                }
            }) {
                Text("Join Support Channel")
            }
        }
//        Div(attrs = { style { margin(20.px) } }) {
//            Button(attrs = {
//                onClick {
//                    window.open(url = "https://support.tygrys.io/~login", target = "_blank")
//                }
//            }) {
//                Text("Support Dashboard")
//            }
//        }
    }
}

@Composable
fun SideMenu(component: SideMenu) {
    val model by component.models.subscribeAsState()

    model.generalMenuItems.forEach {


        IconMenuItem(active = it == model.opened, itemCaption = it.name, iconName = when (it.action) {
            SideMenu.MenuAction.Roster -> "group"
            SideMenu.MenuAction.ChannelBrowser -> "manage_search"
            SideMenu.MenuAction.Settings -> "settings"
            SideMenu.MenuAction.Support -> "support"
        }, onClickCallback = { component.onMenuItemClicked(it) })
    }

    ChatListSection(AppMessages.sideBar_section_directMessages()) {
        model.directChatList.forEach { chat ->
            ChatMenuItem(
                active = chat == model.opened, chat.name, onClickCallback = {
                    component.onMenuItemClicked(chat)
                }, jid = chat.chat.jid, unreadCount = chat.chat.unread, name = chat.name
            )
        }
    }
    ChatListSection(AppMessages.sideBar_section_channels()) {
        model.mix.forEach { chat ->
            ChatMenuItem(
                active = chat == model.opened, chat.name, onClickCallback = {
                    component.onMenuItemClicked(chat)
                }, jid = chat.chat.jid, unreadCount = chat.chat.unread, name = chat.name
            )
        }
    }
    if (model.fromUnknownChatList.isNotEmpty()) ChatListSection(AppMessages.sideBar_section_fromUnknown()) {
        model.fromUnknownChatList.forEach { chat ->
            ChatMenuItem(
                active = chat == model.opened, chat.name, onClickCallback = {
                    component.onMenuItemClicked(chat)
                }, jid = chat.chat.jid, unreadCount = chat.chat.unread, name = chat.name
            )
        }
    }

}