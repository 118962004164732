package org.tigase.officialtea.common.main.version

import kotlin.Long
import kotlin.String

public object OfficialTeaVersion {
  public const val APP_NAME: String = "tygrys"

  public const val APP_VERSION: String = "1.01.270"

  public const val BUILD_TIME: Long = 1697742187440L
}
