package org.tigase.officialtea.common.database

enum class MessageState(val outgoing: Boolean) {

	/**
	 * Incoming, unread message.
	 */
	IncomingUnread(false),

	/**
	 * Incoming message.
	 */
	IncomingRead(false),

	/**
	 * Outgoing, not sent message.
	 */
	OutNotSent(true),

	/**
	 * Outgoing, sent message.
	 */
	OutSent(true),

	/**
	 * Outgoing, delivered message.
	 */
	OutDelivered(true),

	/**
	 * Outgoing, read message.
	 */
	OutRead(true)
}