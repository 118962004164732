package org.tigase.officialtea.common.main.components.NewChat

import com.arkivanov.decompose.value.Value
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.toBareJID

interface NewChat{
    val models: Value<Model>
    fun enterJid(jid: BareJID)
    fun removeJidAt(index: Int)
    fun chatName(name: String)
    fun selectedJid(jid: BareJID)
    fun typingJid(name: String)
    fun clearEnteredJids()
    data class Model(
        val enteredJids: List<BareJID> = emptyList(),
        val chatName: String = "",
        val selectedJid: BareJID = "".toBareJID(),
        val typingJid: String = ""
    )
}