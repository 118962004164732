package org.tigase.officialtea.common.services

import co.touchlab.kermit.Logger
import com.badoo.reaktive.single.Single
import com.badoo.reaktive.single.subscribe
import com.squareup.sqldelight.db.SqlDriver
import org.tigase.officialtea.common.services.roster.RosterPresenceServiceImpl
import org.tigase.officialtea.common.services.settings.ApplicationSettings

fun ServiceKeeperFactory(driver: Single<SqlDriver>, applicationPreferences: ApplicationSettings): ServiceKeeper {

	driver.subscribe {
		Logger.i { "SQL Driver initialized!" }
		AvatarsCacheService.driver = it
		CapabilitiesCacheService.driver = it
	}

	val accountsService = AccountsServiceImpl(driver)
	val connectionService = ConnectionServiceImpl(accountsService, driver)
	val rosterPresenceService = RosterPresenceServiceImpl(driver, connectionService)
	val chatsService = ChatsServiceImpl(driver, connectionService, rosterPresenceService)


	return ServiceKeeper(
		accountsService = accountsService,
		connectionService = connectionService,
		rosterPresenceService = rosterPresenceService,
		chatsService = chatsService,
		applicationPreferences = applicationPreferences,
	)
}

data class ServiceKeeper(
	val accountsService: AccountsService,
	val connectionService: ConnectionService,
	val rosterPresenceService: RosterPresenceService,
	val chatsService: ChatsService,
	val applicationPreferences: ApplicationSettings,
)