package org.tigase.officialtea.common.i18n

import de.comahe.i18n4k.Locale
import de.comahe.i18n4k.messages.MessageBundle
import de.comahe.i18n4k.messages.providers.MessagesProvider
import de.comahe.i18n4k.strings.LocalizedString
import de.comahe.i18n4k.strings.LocalizedStringFactory1
import kotlin.Array
import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmStatic

/**
 * Massage constants for bundle 'AppMessages'. Generated by i18n4k.
 */
public object AppMessages : MessageBundle() {
  /**
   * Tygrys
   */
  @JvmStatic
  public val appName: LocalizedString = getLocalizedString0(0)

  /**
   * {0} is active
   */
  @JvmStatic
  public val chatState_active: LocalizedStringFactory1 = getLocalizedStringFactory1(1)

  /**
   * {0} is composing message...
   */
  @JvmStatic
  public val chatState_composing: LocalizedStringFactory1 = getLocalizedStringFactory1(2)

  /**
   * {0} closed chat
   */
  @JvmStatic
  public val chatState_gone: LocalizedStringFactory1 = getLocalizedStringFactory1(3)

  /**
   * {0} is not active
   */
  @JvmStatic
  public val chatState_inactive: LocalizedStringFactory1 = getLocalizedStringFactory1(4)

  /**
   * {0} paused composing message
   */
  @JvmStatic
  public val chatState_paused: LocalizedStringFactory1 = getLocalizedStringFactory1(5)

  /**
   * Load more
   */
  @JvmStatic
  public val chathistory_button_loadMore: LocalizedString = getLocalizedString0(6)

  /**
   * Connected
   */
  @JvmStatic
  public val connectionState_connected: LocalizedString = getLocalizedString0(7)

  /**
   * Connecting
   */
  @JvmStatic
  public val connectionState_connecting: LocalizedString = getLocalizedString0(8)

  /**
   * Disconnected
   */
  @JvmStatic
  public val connectionState_disconnected: LocalizedString = getLocalizedString0(9)

  /**
   * Disconnecting
   */
  @JvmStatic
  public val connectionState_disconnecting: LocalizedString = getLocalizedString0(10)

  /**
   * Disconnected
   */
  @JvmStatic
  public val connectionState_stopped: LocalizedString = getLocalizedString0(11)

  /**
   * Error: {0}
   */
  @JvmStatic
  public val mixPanel_error: LocalizedStringFactory1 = getLocalizedStringFactory1(12)

  /**
   * XMPP Jid
   */
  @JvmStatic
  public val roster_edit_jid: LocalizedString = getLocalizedString0(13)

  /**
   * Contact name
   */
  @JvmStatic
  public val roster_edit_name: LocalizedString = getLocalizedString0(14)

  /**
   * Allow for subscription
   */
  @JvmStatic
  public val roster_edit_subscribe_allow: LocalizedString = getLocalizedString0(15)

  /**
   * Ask for subscription
   */
  @JvmStatic
  public val roster_edit_subscribe_ask: LocalizedString = getLocalizedString0(16)

  /**
   * Save
   */
  @JvmStatic
  public val roster_edit_subscribe_saveButton: LocalizedString = getLocalizedString0(17)

  /**
   * Account
   */
  @JvmStatic
  public val settings_accounts_accountBarTitle: LocalizedString = getLocalizedString0(18)

  /**
   * Add
   */
  @JvmStatic
  public val settings_accounts_addButton: LocalizedString = getLocalizedString0(19)

  /**
   * Delete
   */
  @JvmStatic
  public val settings_accounts_deleteButton: LocalizedString = getLocalizedString0(20)

  /**
   * Hostname
   */
  @JvmStatic
  public val settings_accounts_field_hostname: LocalizedString = getLocalizedString0(21)

  /**
   * User JID
   */
  @JvmStatic
  public val settings_accounts_field_jid: LocalizedString = getLocalizedString0(22)

  /**
   * Nickname
   */
  @JvmStatic
  public val settings_accounts_field_nickname: LocalizedString = getLocalizedString0(23)

  /**
   * Optional
   */
  @JvmStatic
  public val settings_accounts_field_optionalPlaceholder: LocalizedString = getLocalizedString0(24)

  /**
   * Password
   */
  @JvmStatic
  public val settings_accounts_field_password: LocalizedString = getLocalizedString0(25)

  /**
   * Resource
   */
  @JvmStatic
  public val settings_accounts_field_resource: LocalizedString = getLocalizedString0(26)

  /**
   * Save
   */
  @JvmStatic
  public val settings_accounts_saveButton: LocalizedString = getLocalizedString0(27)

  /**
   * Accounts
   */
  @JvmStatic
  public val settings_accounts_title: LocalizedString = getLocalizedString0(28)

  /**
   * Accounts
   */
  @JvmStatic
  public val settings_section_accounts: LocalizedString = getLocalizedString0(29)

  /**
   * Preferences
   */
  @JvmStatic
  public val settings_section_preferences: LocalizedString = getLocalizedString0(30)

  /**
   * Theme
   */
  @JvmStatic
  public val settings_section_theme: LocalizedString = getLocalizedString0(31)

  /**
   * Channel browser
   */
  @JvmStatic
  public val sideBar_section_channelBrowser: LocalizedString = getLocalizedString0(32)

  /**
   * Channels
   */
  @JvmStatic
  public val sideBar_section_channels: LocalizedString = getLocalizedString0(33)

  /**
   * Direct messages
   */
  @JvmStatic
  public val sideBar_section_directMessages: LocalizedString = getLocalizedString0(34)

  /**
   * From unknown
   */
  @JvmStatic
  public val sideBar_section_fromUnknown: LocalizedString = getLocalizedString0(35)

  /**
   * Roster
   */
  @JvmStatic
  public val sideBar_section_roster: LocalizedString = getLocalizedString0(36)

  /**
   * Settings
   */
  @JvmStatic
  public val sideBar_section_settings: LocalizedString = getLocalizedString0(37)

  /**
   * Background
   */
  @JvmStatic
  public val themeEditor_field_background: LocalizedString = getLocalizedString0(38)

  /**
   * Error
   */
  @JvmStatic
  public val themeEditor_field_error: LocalizedString = getLocalizedString0(39)

  /**
   * Light Theme
   */
  @JvmStatic
  public val themeEditor_field_isLight: LocalizedString = getLocalizedString0(40)

  /**
   * On Background
   */
  @JvmStatic
  public val themeEditor_field_onBackground: LocalizedString = getLocalizedString0(41)

  /**
   * On Error
   */
  @JvmStatic
  public val themeEditor_field_onError: LocalizedString = getLocalizedString0(42)

  /**
   * On Primary
   */
  @JvmStatic
  public val themeEditor_field_onPrimary: LocalizedString = getLocalizedString0(43)

  /**
   * On Secondary
   */
  @JvmStatic
  public val themeEditor_field_onSecondary: LocalizedString = getLocalizedString0(44)

  /**
   * On Surface
   */
  @JvmStatic
  public val themeEditor_field_onSurface: LocalizedString = getLocalizedString0(45)

  /**
   * Primary
   */
  @JvmStatic
  public val themeEditor_field_primary: LocalizedString = getLocalizedString0(46)

  /**
   * Primary Variant
   */
  @JvmStatic
  public val themeEditor_field_primaryVariant: LocalizedString = getLocalizedString0(47)

  /**
   * Secondary
   */
  @JvmStatic
  public val themeEditor_field_secondary: LocalizedString = getLocalizedString0(48)

  /**
   * Secondary Variant
   */
  @JvmStatic
  public val themeEditor_field_secondaryVariant: LocalizedString = getLocalizedString0(49)

  /**
   * Surface
   */
  @JvmStatic
  public val themeEditor_field_surface: LocalizedString = getLocalizedString0(50)

  /**
   * Update
   */
  @JvmStatic
  public val themeEditor_updateButton: LocalizedString = getLocalizedString0(51)

  init {
    registerTranslation(AppMessages_en)
  }
}

/**
 * Translation of message bundle 'AppMessages' for locale 'en'. Generated by i18n4k.
 */
private object AppMessages_en : MessagesProvider {
  @JvmStatic
  private val _data: Array<String?> = arrayOf(
      "Tygrys",
      "{0} is active",
      "{0} is composing message...",
      "{0} closed chat",
      "{0} is not active",
      "{0} paused composing message",
      "Load more",
      "Connected",
      "Connecting",
      "Disconnected",
      "Disconnecting",
      "Disconnected",
      "Error: {0}",
      "XMPP Jid",
      "Contact name",
      "Allow for subscription",
      "Ask for subscription",
      "Save",
      "Account",
      "Add",
      "Delete",
      "Hostname",
      "User JID",
      "Nickname",
      "Optional",
      "Password",
      "Resource",
      "Save",
      "Accounts",
      "Accounts",
      "Preferences",
      "Theme",
      "Channel browser",
      "Channels",
      "Direct messages",
      "From unknown",
      "Roster",
      "Settings",
      "Background",
      "Error",
      "Light Theme",
      "On Background",
      "On Error",
      "On Primary",
      "On Secondary",
      "On Surface",
      "Primary",
      "Primary Variant",
      "Secondary",
      "Secondary Variant",
      "Surface",
      "Update")

  public override val locale: Locale = Locale("en")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}
