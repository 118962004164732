package org.tigase.officialtea.common.main.components.NewChat

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import org.tigase.officialtea.common.services.ServiceKeeper
import tigase.halcyon.core.xmpp.BareJID

internal interface NewChatStore : Store<NewChatStore.Intent, NewChat.Model, NewChatStore.Label> {
    sealed interface Intent{
        data class enterJid(val jid: BareJID) : Intent
        data class removeJidAt(val index: Int) : Intent
        data class chatName(val name: String) : Intent
        data class selectJid(val jid: BareJID) : Intent
        data class typingJid(val name: String) : Intent
        object clearEnteredJids : Intent
    }
    sealed interface Label
}

internal class NewChatStoreProvider(
    private val storeFactory: StoreFactory,
    private val serviceKeeper: ServiceKeeper,
    private val componentContext: ComponentContext,
){
    fun provide(): NewChatStore = object : NewChatStore, Store<NewChatStore.Intent, NewChat.Model, NewChatStore.Label> by storeFactory.create(
        name = "NewChatStore", initialState = NewChat.Model(), bootstrapper = SimpleBootstrapper(
            Unit
        ), executorFactory = ::ExecutorImpl, reducer = ReducerImpl
    ) {}

    private sealed interface Result {
        data class enterJid(val jid: BareJID) : Result
        data class removeJidAt(val index: Int) : Result
        data class chatName(val name: String) : Result
        data class selectedJid(val jid: BareJID) : Result
        data class typingJid(val name: String) : Result
        object clearEnteredJids : Result
    }
    private inner class ExecutorImpl : ReaktiveExecutor<NewChatStore.Intent, Unit, NewChat.Model, Result, NewChatStore.Label>() {
        override fun executeIntent(intent: NewChatStore.Intent, getState: () -> NewChat.Model) = when (intent) {
            is NewChatStore.Intent.chatName -> chatName(intent.name)
            is NewChatStore.Intent.clearEnteredJids -> clearEnteredJids()
            is NewChatStore.Intent.enterJid -> enterJID(intent.jid)
            is NewChatStore.Intent.removeJidAt -> removeJidAt(intent.index)
            is NewChatStore.Intent.selectJid -> selectedJID(intent.jid)
            is NewChatStore.Intent.typingJid -> typingJid(intent.name)
        }
        private fun enterJID(jid: BareJID) {
            dispatch(Result.enterJid(jid))
        }
        private fun removeJidAt(index: Int) {
            dispatch(Result.removeJidAt(index))
        }
        private fun chatName(name: String) {
            dispatch(Result.chatName(name))
        }
        private fun selectedJID(jid: BareJID) {
            dispatch(Result.selectedJid(jid))
        }
        private fun clearEnteredJids() {
            dispatch(Result.clearEnteredJids)
        }
        private fun typingJid(name: String) {
            dispatch(Result.typingJid(name))
        }
    }


    private object ReducerImpl : Reducer<NewChat.Model, Result> {
        override fun NewChat.Model.reduce(msg: Result): NewChat.Model = when(msg){
            is Result.chatName -> copy(chatName = msg.name)
            is Result.selectedJid -> copy(selectedJid = msg.jid)
            is Result.enterJid -> copy(enteredJids = enteredJids.plus(msg.jid))
            is Result.removeJidAt -> copy(enteredJids = enteredJids.drop(msg.index))
            is Result.clearEnteredJids -> copy(enteredJids = emptyList())
            is Result.typingJid -> copy(typingJid = msg.name)
        }
    }
}