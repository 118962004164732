package org.tigase.officialtea.common.services.chat

import co.touchlab.kermit.Logger
import com.badoo.reaktive.completable.Completable
import org.tigase.officialtea.common.database.MessageState
import org.tigase.officialtea.common.database.MessagesDatabaseQueries
import org.tigase.officialtea.common.database.execute
import org.tigase.officialtea.common.services.ChatsServiceImpl
import tigase.halcyon.core.xmpp.bareJID
import tigase.halcyon.core.xmpp.modules.chatmarkers.ChatMarkersModule
import tigase.halcyon.core.xmpp.modules.chatmarkers.getChatMarkerOrNull
import tigase.halcyon.core.xmpp.modules.receipts.getReceiptReceivedID

private fun MessagesDatabaseQueries.processReceipt(meta: MsgMeta) {
    val authorJid = meta.mtp.stanza.to?.bareJID ?: return
    val receivedStanzaId = meta.mtp.stanza.getReceiptReceivedID() ?: return
    this.updateMessageStateByOriginId(
            MessageState.OutDelivered,
            meta.openChat.id,
            "$authorJid%",
            receivedStanzaId,
            listOf(MessageState.OutSent, MessageState.OutNotSent)
    )
}

fun ChatsServiceImpl.receiptProcessor(items: Collection<MsgMeta>): Completable {
    return chatsQueries.execute { q ->
        items.forEach { q.processReceipt(it) }
    }
}

fun ChatsServiceImpl.processChatMarker(
        list: List<MsgMeta>,
): Completable {
    return chatsQueries.execute {
        Logger.w("ReceiptProcessor") { "ProcessingChatMarkers " + list.size }
        list.forEach { meta ->
            val chatMarker = meta.mtp.stanza.getChatMarkerOrNull()!!
            val (newState, changingStates) = when (chatMarker.marker) {
                ChatMarkersModule.Marker.Received -> Pair(
                        MessageState.OutDelivered, listOf(MessageState.OutSent, MessageState.OutNotSent)
                )

                ChatMarkersModule.Marker.Displayed -> {
                    if (chatMarker.sender.bareJID == meta.openChat.account)
                        Pair(MessageState.IncomingRead, listOf(MessageState.IncomingUnread))
                    else
                        Pair(MessageState.OutRead, listOf(MessageState.OutSent, MessageState.OutDelivered, MessageState.OutNotSent))
                }

                ChatMarkersModule.Marker.Acknowledged -> Pair(
                        MessageState.OutRead, listOf(
                        MessageState.OutSent, MessageState.OutDelivered, MessageState.OutNotSent
                )
                )
            }
            it.updateMessagesState(newState, meta.openChat.id, meta.timestamp, changingStates)
        }
    }
}

fun ChatsServiceImpl.updateChatMarkerDB(list: List<MsgMeta>): Completable {
    return chatMarkers.execute { q ->
        q.transaction {
            list.filter { it.senderJid?.bareJID != it.openChat.account }
                    .forEach {
                        val marker = it.mtp.stanza.getChatMarkerOrNull()!!

                        val oldMarker = q.findMarker(
                                openchat_id = it.openChat.id,
                                sender_nick = it.senderNick ?: "",
                                sender_id = it.participantId ?: "",
                                sender_jid = it.senderJid!!.bareJID
                        )
                                .executeAsOneOrNull()
                        if (oldMarker == null) q.addMarker(
                                openchat_id = it.openChat.id,
                                sender_nick = it.senderNick ?: "",
                                sender_jid = it.senderJid.bareJID,
                                sender_id = it.participantId ?: "",
                                type = marker.marker,
                                timestamp = it.timestamp
                        )
                        else q.updateMarker(
                                openchat_id = it.openChat.id,
                                sender_nick = it.senderNick ?: "",
                                sender_jid = it.senderJid.bareJID,
                                sender_id = it.participantId ?: "",
                                type = marker.marker,
                                timestamp = it.timestamp
                        )
                    }
        }
    }

}