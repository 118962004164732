package org.tigase.officialtea.common.database

import kotlin.String

public data class CapsIdentities(
  public val node: String,
  public val name: String?,
  public val type: String?,
  public val category: String?
) {
  public override fun toString(): String = """
  |CapsIdentities [
  |  node: $node
  |  name: $name
  |  type: $type
  |  category: $category
  |]
  """.trimMargin()
}
