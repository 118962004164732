import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.jetbrains.subscribeAsState
import components.FormPanel
import components.MenuItem
import components.textInput
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.main.settings.accountslist.AccountEdit
import org.tigase.officialtea.common.main.settings.accountslist.AccountsList
import org.tigase.officialtea.common.main.settings.accountslist.AccountsRoot
import tigase.halcyon.core.AbstractHalcyon

@Composable
fun AccountsContent(component: AccountsRoot) {
    val stack by component.routerState.subscribeAsState()


    when (val instance = stack.active.instance) {
        is AccountsRoot.Child.Edit -> AccountEdit(instance.component)
        is AccountsRoot.Child.List -> AccountsList(instance.component)
    }

}

@Composable
fun AccountEdit(component: AccountEdit) {
    val model by component.models.subscribeAsState()

    FormPanel {
        textInput(label = "User JID", value = model.userJid, onValueChange = component::onUserJidChange)
        textInput(
            label = "Password",
            value = model.password,
            onValueChange = component::onPasswordChange,
            inputType = InputType.Password
        )
        textInput(label = "Nickname", value = model.nickname, onValueChange = component::onNicknameChange)

        Button(attrs = {
            classes( "bg-[#324167]", "hover:bg-sky-700", "px-5", "py-2", "h-[40px]", "w-[100px]", "rounded-full", "font-[500]", "text-white", "text-[15px]")
            onClick {
                component.onSavePress()
            }
        }) {
            Text("Save")
        }
    }
}

@Composable
fun AccountsList(component: AccountsList) {
    val model by component.models.subscribeAsState()

    Button(attrs = {
        classes( "bg-[#324167]", "hover:bg-sky-700", "px-5", "py-2", "h-[40px]", "w-[100px]", "rounded-full", "font-[500]", "text-white", "text-[15px]")
        onClick {
            component.onAddClick()
        }
    }) {
        Text("Add")
    }

    model.items.forEach {
        AccountMenuItem(it.first, it.second)
    }

}

@Composable
fun AccountMenuItem(data: Accounts, state: AbstractHalcyon.State, onClickCallback: (() -> Unit)? = null) {
    MenuItem(
        active = false,
        icon = { Avatar(data.userjid, data.userjid.toString()) },
        onClickCallback = onClickCallback,
        content = {
            Div {
                Div (attrs = {
                    classes("name")
                }){
                    Text(data.userjid.toString())
                }
                Div {
                    Text(state.name)
                }
            }
        })
}