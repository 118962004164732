package org.tigase.officialtea.common.main.components.chat.messagelist

import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

fun compareDates(d1: Instant, d2: Instant): Boolean {
	val ld1 = d1.toLocalDateTime(TimeZone.UTC)
	val ld2 = d2.toLocalDateTime(TimeZone.UTC)
	return ld1.year == ld2.year && ld1.dayOfYear == ld2.dayOfYear
}