package org.tigase.officialtea.common.main.components.mixbrowser

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.maybe.filter
import com.badoo.reaktive.maybe.flatMapSingle
import com.badoo.reaktive.maybe.observeOn
import com.badoo.reaktive.maybe.subscribeOn
import com.badoo.reaktive.scheduler.ioScheduler
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.map
import com.badoo.reaktive.single.observeOn
import com.badoo.reaktive.single.subscribeOn
import org.tigase.officialtea.common.main.components.mixbrowser.MixChannelsList.Model
import org.tigase.officialtea.common.main.components.mixbrowser.MixChannelsListStore.Intent
import org.tigase.officialtea.common.main.components.mixbrowser.MixChannelsListStore.Label
import org.tigase.officialtea.common.services.ServiceKeeper
import tigase.halcyon.core.requests.XMPPError
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.bareJID
import tigase.halcyon.core.xmpp.modules.discovery.DiscoveryModule
import tigase.halcyon.core.xmpp.modules.mix.MIXModule
import tigase.halcyon.rx.asSingle

internal interface MixChannelsListStore : Store<Intent, Model, Label> {

	sealed interface Intent {

		data class SetAddress(val account: BareJID, val address: BareJID) : Intent
//		data class CreateChannel(val account: BareJID, val items: List<MixChannelsList.Item>) : Intent
		data class SelectItem(val item: MixChannelsList.Item?) : Intent
		data class JoinChannel(val item: MixChannelsList.Item) : Intent
	}

	sealed interface Label {

		data class JoinChannel(val account: BareJID, val address: BareJID) : Label

	}
}

internal class MixChannelsListStoreProvider(
	private val storeFactory: StoreFactory,
	val serviceKeeper: ServiceKeeper,
) {

	fun provide(): MixChannelsListStore =
		object : MixChannelsListStore, Store<Intent, Model, Label> by storeFactory.create(
			name = "MixChannelsListStore", initialState = Model(
				null, null
			), bootstrapper = SimpleBootstrapper(
				Unit
			), executorFactory = ::ExecutorImpl, reducer = ReducerImpl
		) {}

	private sealed interface Result {

		data class OnItemsLoaded(val account: BareJID, val address: BareJID, val items: List<MixChannelsList.Item>) :
			Result

		data class OnItemSelected(val item: MixChannelsList.Item?) : Result

		data class OnError(val message: String) : Result

	}

	private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, Model, Result, Label>() {

		override fun executeIntent(intent: Intent, getState: () -> Model) = when (intent) {
			is Intent.SetAddress -> onSetAddress(intent)
			is Intent.SelectItem -> onSelectItem(intent, getState())
			is Intent.JoinChannel -> onJoinChannel(intent)
		}

		private fun onJoinChannel(intent: Intent.JoinChannel) {
			serviceKeeper.accountsService.load(intent.item.account)
				.subscribeOn(ioScheduler)
				.flatMapSingle { account ->
					serviceKeeper.connectionService[account.userjid]!!.getModule<MIXModule>(MIXModule.TYPE)
						.join(channel = intent.item.jid, nick = account.nickname ?: account.userjid.localpart!!)
						.asSingle()
						.subscribeOn(ioScheduler)
				}
				.observeOn(mainScheduler)
				.subscribeScoped(onError = {
					dispatch(
						Result.OnError(
							when (it) {
								is XMPPError -> "${it.error}"
								else -> "Cannot join"
							}
						)
					)
				}, onSuccess = {
					publish(Label.JoinChannel(intent.item.account, intent.item.jid))
				})
		}

		private fun onSelectItem(intent: Intent.SelectItem, state: Model) {
			if (state.selected == intent.item) {
				dispatch(Result.OnItemSelected(null))
			} else if (intent.item != null) {
				serviceKeeper.accountsService.load(intent.item.account)
					.subscribeOn(ioScheduler)
					.flatMapSingle { account ->
						serviceKeeper.connectionService[account.userjid]!!.getModule<DiscoveryModule>(DiscoveryModule.TYPE)
							.info(jid = intent.item.jid)
							.asSingle()
							.subscribeOn(ioScheduler)
							.map { Pair(account, it) }
					}
					.filter { (account, info) ->
						info.features.contains("urn:xmpp:mix:core:1")
					}
					.flatMapSingle { (account, info) ->
						serviceKeeper.connectionService[account.userjid]!!.getModule<DiscoveryModule>(DiscoveryModule.TYPE)
							.items(jid = info.jid, "mix")
							.asSingle()
							.subscribeOn(ioScheduler)
					}
					.observeOn(mainScheduler)
					.subscribeScoped {
						dispatch(Result.OnItemSelected(intent.item))
					}
			} else {
				dispatch(Result.OnItemSelected(intent.item))
			}
		}

		private fun onSetAddress(intent: Intent.SetAddress) {
			serviceKeeper.connectionService[intent.account]!!.getModule<DiscoveryModule>(DiscoveryModule.TYPE)
				.items(intent.address)
				.asSingle()
				.subscribeOn(ioScheduler)
				.observeOn(mainScheduler)
				.subscribeScoped {
					val items = it.items.map {
						MixChannelsList.Item(intent.account, it.name ?: it.jid.toString(), it.jid.bareJID)
					}
					dispatch(Result.OnItemsLoaded(intent.account, intent.address, items))
				}
		}

	}

	private object ReducerImpl : Reducer<Model, Result> {

		override fun Model.reduce(result: Result): Model = when (result) {
			is Result.OnItemsLoaded -> copy(
				account = result.account,
				address = result.address,
				items = result.items,
				selected = null,
				errorMessage = null
			)

			is Result.OnItemSelected -> copy(selected = result.item, errorMessage = null)
			is Result.OnError -> copy(errorMessage = result.message)
		}
	}
}
