package org.tigase.officialtea.common.database

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import tigase.halcyon.core.xmpp.BareJID

public data class OpenChats(
  public val id: Long,
  public val account: BareJID,
  public val jid: BareJID,
  public val name: String,
  public val type: OpenChatType
) {
  public override fun toString(): String = """
  |OpenChats [
  |  id: $id
  |  account: $account
  |  jid: $jid
  |  name: $name
  |  type: $type
  |]
  """.trimMargin()

  public class Adapter(
    public val accountAdapter: ColumnAdapter<BareJID, String>,
    public val jidAdapter: ColumnAdapter<BareJID, String>,
    public val typeAdapter: ColumnAdapter<OpenChatType, String>
  )
}
