package components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Nav
import org.jetbrains.compose.web.dom.Section
import org.w3c.dom.HTMLElement

@Composable
fun TwoColumnPanel(
    header: ContentBuilder<HTMLElement>? = null,
    left: ContentBuilder<HTMLElement>? = null,
    right: ContentBuilder<HTMLElement>? = null
) {
    Div(attrs = { classes("section") }) {
        Nav {
            header?.invoke(this)
            left?.invoke(this)
        }
        Section (attrs = {classes("main-view")}) {
            Div(attrs = {classes("main-view-wrapper")}) {
                right?.invoke(this)
            }
        }
    }
}