package org.tigase.officialtea.common.main.components.chat

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.childContext
import com.arkivanov.essenty.lifecycle.Lifecycle
import com.arkivanov.essenty.lifecycle.doOnStart
import com.arkivanov.essenty.lifecycle.doOnStop
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.badoo.reaktive.disposable.Disposable
import com.badoo.reaktive.maybe.subscribe
import com.badoo.reaktive.observable.Observable
import com.badoo.reaktive.observable.subscribe
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.observeOn
import com.badoo.reaktive.single.subscribe
import com.badoo.reaktive.single.threadLocal
import org.tigase.officialtea.common.database.OpenChats
import org.tigase.officialtea.common.main.components.chat.chatstate.ChatStateComponent
import org.tigase.officialtea.common.main.components.chat.chatstate.ChatStateComponentImpl
import org.tigase.officialtea.common.main.components.chat.messagelist.MessageList
import org.tigase.officialtea.common.main.components.chat.messagelist.MessageListComponent
import org.tigase.officialtea.common.main.components.messageinput.MessageInput
import org.tigase.officialtea.common.main.components.messageinput.MessageInputComponent
import org.tigase.officialtea.common.services.ChatsService
import org.tigase.officialtea.common.services.ConnectionService
import org.tigase.officialtea.common.services.FileToUpload
import org.tigase.officialtea.common.services.FocusService
import org.tigase.officialtea.common.utils.Consumer
import tigase.halcyon.core.TickEvent
import tigase.halcyon.core.xmpp.modules.chatstates.ChatStateMachine
import tigase.halcyon.rx.observe

class ChatViewComponent(
	private val componentContext: ComponentContext,
	private val chatData: ChatData,
	private val storeFactory: StoreFactory,
	private val service: ChatsService,
	private val connectionService: ConnectionService,
) : ChatView, ComponentContext by componentContext {

	override val messageInput: MessageInputComponent =
		MessageInputComponent(childContext("MessageInput"), storeFactory, Consumer(::onSend))

	override val messageList: MessageList =
		MessageListComponent(childContext("MessageList"), storeFactory, chatData.chatId, service)

	override val chatState: ChatStateComponent =
		ChatStateComponentImpl(childContext("ChatState"), storeFactory, chatData, connectionService, service)

	private var chatStateMachine: ChatStateMachine? = null

	private var editedStanzaRowId: Long? = null

	init {
		messageInput.labels()
			.autoSubscribeIn(lifecycle = lifecycle) {
				chatStateMachine?.composing()
			}
		FocusService.observe()
			.autoSubscribeIn(lifecycle) {
				if (it) {
					chatStateMachine?.focused()
				} else {
					chatStateMachine?.focusLost()
				}
			}

		service.findOpenChat(chatData.chatId)
			.observeOn(mainScheduler)
			.threadLocal()
			.subscribe(onSuccess = { it?.let(::initializeComponent) })
		lifecycle.doOnStop {
			chatStateMachine?.focusLost()
		}

	}

	private fun initializeComponent(openChat: OpenChats) {
		messageInput.setPlaceholder(openChat.account.toString(), openChat.jid.toString())
		val eventBus = connectionService[openChat.account]!!.eventBus
		chatStateMachine = ChatStateMachine(openChat.jid, eventBus)
		if (FocusService.focused) chatStateMachine?.focused()

		eventBus.observe<TickEvent>(TickEvent.TYPE)
			.autoSubscribeIn(lifecycle) {
				chatStateMachine?.update()
			}
	}

	private fun onSend(output: MessageInput.Output) {
		when (output) {
			is MessageInput.Output.Finished -> {
				chatStateMachine?.sendingMessage()
				service.sendMessage(
					openChat = messageList.models.value.openChat!!,
					text = output.text,
					fileToUpload = output.file,
					editedMessageId = editedStanzaRowId
				)
				editedStanzaRowId = null
			}

			MessageInput.Output.EditLastMessage -> {
				service.loadLastMessage(chatData.chatId)
					.subscribe {
						editedStanzaRowId = it.id
						messageInput.reset()
						messageInput.onTextChanged(it.body ?: "")
					}
			}
		}
	}
}

fun <T> Observable<T>.autoSubscribeIn(
	lifecycle: Lifecycle,
	isThreadLocal: Boolean = false,
	onSubscribe: ((Disposable) -> Unit)? = null,
	onError: ((Throwable) -> Unit)? = null,
	onComplete: (() -> Unit)? = null,
	onNext: ((T) -> Unit)? = null,
) {
	var disposable: Disposable? = null
	lifecycle.doOnStart {
		disposable = this.subscribe(
			isThreadLocal = isThreadLocal,
			onSubscribe = onSubscribe,
			onError = onError,
			onComplete = onComplete,
			onNext = onNext
		)
	}
	lifecycle.doOnStop {
		disposable?.dispose()
	}
}