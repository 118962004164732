package org.tigase.officialtea.common.services.utils

import tigase.halcyon.core.eventbus.Event
import tigase.halcyon.core.eventbus.EventBusInterface
import tigase.halcyon.core.eventbus.EventHandler
import tigase.halcyon.core.eventbus.NoContextEventBus

class MultiEventBus : NoContextEventBus() {

	private val eventBuses: MutableSet<EventBusInterface> = mutableSetOf()

	override fun createHandlersMap(): MutableMap<String, MutableSet<EventHandler<*>>> = mutableMapOf()

	override fun createHandlersSet(): MutableSet<EventHandler<*>> = mutableSetOf()

	override fun updateBeforeFire(event: Event) {}

	private val handler = object : EventHandler<Event> {
		override fun onEvent(event: Event) {
			this@MultiEventBus.fire(event)
		}
	}

	fun bind(eventBus: EventBusInterface) {
		val added = eventBuses.add(eventBus)
		if (added) {
			eventBus.register(handler = handler)
		}
	}

	fun unbind(eventBus: EventBusInterface) {
		eventBus.unregister(handler)
		eventBuses.remove(eventBus)
	}

}