package org.tigase.officialtea.common.main.components.sidemenu

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.annotations.MainThread
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.labels
import com.badoo.reaktive.base.Consumer
import com.badoo.reaktive.base.invoke
import com.badoo.reaktive.disposable.CompositeDisposable
import com.badoo.reaktive.disposable.Disposable
import com.badoo.reaktive.disposable.addTo
import com.badoo.reaktive.maybe.MaybeObserver
import com.badoo.reaktive.maybe.observeOn
import com.badoo.reaktive.maybe.subscribeOn
import com.badoo.reaktive.observable.observeOn
import com.badoo.reaktive.observable.subscribe
import com.badoo.reaktive.scheduler.mainScheduler
import findUnreadChat
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.database.OpenChats
import org.tigase.officialtea.common.i18n.AppMessages
import org.tigase.officialtea.common.main.components.chat.autoSubscribeIn
import org.tigase.officialtea.common.main.components.sidemenu.SideMenu.MenuItem
import org.tigase.officialtea.common.main.components.sidemenu.SideMenu.MenuItem.GeneralMenuItem
import org.tigase.officialtea.common.services.AccountsService
import org.tigase.officialtea.common.services.ChatsService
import org.tigase.officialtea.common.services.RosterManipulationService
import org.tigase.officialtea.common.services.RosterPresenceService
import org.tigase.officialtea.common.services.settings.ApplicationSettings
import org.tigase.officialtea.common.utils.asValue
import org.tigase.officialtea.common.utils.getStore
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.roster.RosterItem

class SideMenuComponent(
    componentContext: ComponentContext,
    storeFactory: StoreFactory,
    private val chatsService: ChatsService,
    private val accountsService: AccountsService,
    private val rosterPresenceService: RosterPresenceService,
    private val preferences: ApplicationSettings,
    private val output: Consumer<MenuItem>,
    private val themeSwitcher: Consumer<Unit>,
) : SideMenu, ComponentContext by componentContext {

    val presenceListener = rosterPresenceService.observeAll()
    var activeAccount: BareJID? = null
    private val disposables = CompositeDisposable()


    private val generalOptions = listOf<GeneralMenuItem>(
        GeneralMenuItem(SideMenu.MenuAction.Roster, "roster.png", AppMessages.sideBar_section_roster()),
        GeneralMenuItem(
            SideMenu.MenuAction.ChannelBrowser,
            "channel_browser.png",
            AppMessages.sideBar_section_channelBrowser()
        ),
        GeneralMenuItem(SideMenu.MenuAction.Settings, "settings.png", AppMessages.sideBar_section_settings()),
        //add app messages for support, only engl for now
        GeneralMenuItem(SideMenu.MenuAction.Support, "support.png", "Support")
    )


    private val store = instanceKeeper.getStore {
        SideMenuStoreProvider(
            storeFactory = storeFactory,
            chatsService = chatsService,
            rosterPresenceService = rosterPresenceService,
            generalMenuItems = generalOptions
        ).provide()
    }

    override val models: Value<SideMenu.Model> = store.asValue()

    fun dispose() {
        disposables.dispose()
    }

    init {
        store.labels.observeOn(mainScheduler)
            .autoSubscribeIn(lifecycle = lifecycle) {
                if (it is SideMenuStore.Label.ItemClicked) {
                    output(it.item)
                }
            }
        presenceListener.subscribe(
            onNext = { rosterPresenceItems ->
                rosterPresenceItems.forEach { rosterPresenceItem ->
                    accountsService.load(rosterPresenceItem.account)
                        .observeOn(mainScheduler)
                        .subscribeOn(mainScheduler)
                        .subscribe(object : MaybeObserver<Accounts> {
                            override fun onSubscribe(disposable: Disposable) {
                                disposables.add(disposable)
                            }

                            override fun onSuccess(value: Accounts) {
                                store.accept(SideMenuStore.Intent.SetActiveAccount(value))
                            }

                            override fun onComplete() {
//                                TODO("Not yet implemented")
                            }

                            override fun onError(error: Throwable) {
                                println("Error: " + "Failed to load account: ${error.message}")
                            }
                        })
                }
            }
        ).addTo(disposables)
    }

    override fun onMenuItemClicked(item: MenuItem) {
        store.accept(SideMenuStore.Intent.ClickItem(item))
//		output(item)
    }


    override fun onOpenSelfProfile(open: Boolean) {
        store.accept(SideMenuStore.Intent.SelfActionMenu(open))
    }

    override fun onDirectChatClose(vararg items: MenuItem.ChatMenuItem) {
        val s = models.value.opened
        for (item in items) {
            if (s is MenuItem.ChatMenuItem && s.chat.openChatId == item.chat.openChatId) {
                val newSelected = findUnreadChat(models = models) ?: generalOptions[0]
                store.accept(SideMenuStore.Intent.ClickItem(newSelected))
//				output(newSelected)
            }
            store.accept(SideMenuStore.Intent.CloseChat(item))
        }
    }

    override fun open(chat: OpenChats) {
        models.value.directChatList.firstOrNull { item -> item.chat.openChatId == chat.id }
            ?.let {
                store.accept(SideMenuStore.Intent.ClickItem(it))
            }
    }

    override fun open(account: BareJID, jid: BareJID) {
        models.value.directChatList.firstOrNull { item -> item.chat.account == account && item.chat.jid == jid }
            ?.let {
                store.accept(SideMenuStore.Intent.ClickItem(it))
            }
    }

    override fun toggleTheme() {
        themeSwitcher(Unit)
    }

    override fun onToggleSelection(vararg items: MenuItem, forceSelect: Boolean) {
        store.accept(SideMenuStore.Intent.ToggleSelection(items.asList(), forceSelect))
    }

    override fun onClearSelection() {
        store.accept(SideMenuStore.Intent.ClearSelection)
    }

    override fun onCloseSelected() {
        val items = models.value.selectedItems.filterIsInstance<MenuItem.ChatMenuItem>()
            .toTypedArray()
        store.accept(SideMenuStore.Intent.ClearSelection)
        onDirectChatClose(*items)
    }

    override fun onAddUser(account: BareJID, victim: RosterItem) {
        TODO()
//        rosterManipulationService.addContact(account, victim)
    }

    override fun onChangeSearchText(searchQuery: String) {
        store.accept(SideMenuStore.Intent.SetSearchQuery(searchQuery))
    }

}