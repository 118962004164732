package org.tigase.officialtea.common.main.components.support

import com.arkivanov.decompose.value.Value
import tigase.halcyon.core.xmpp.BareJID

interface Support {

    val models: Value<Model>

    fun onSubmit()
    fun onConnectToSupportChannel(account: BareJID)
    fun onRedirect()
    fun connectToSupportChannel()

    data class Model(
        val errorMessage: String = ""
    )
}

data class SupportTicketInfo(
    val name: String,
    val email: String,
    val description: String,
    val replicationSteps: String,
    val status: String,
    // implement at a later time
//    val attachments
)