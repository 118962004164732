package org.tigase.officialtea.common.services.chat

import com.badoo.reaktive.completable.andThen
import com.badoo.reaktive.maybe.map
import com.badoo.reaktive.observable.flatMapMaybe
import com.badoo.reaktive.observable.flatMapSingle
import com.badoo.reaktive.observable.notNull
import com.badoo.reaktive.observable.toList
import com.badoo.reaktive.single.*
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.tigase.officialtea.common.database.*
import org.tigase.officialtea.common.services.ChatsServiceImpl
import org.tigase.officialtea.common.services.halcyon.getLastMessageCorrectionIdOrNull
import org.tigase.officialtea.common.services.hasVisibleContent
import org.tigase.officialtea.common.services.saveOrUpdate
import tigase.halcyon.core.xml.EscapeUtils
import tigase.halcyon.core.xmpp.*
import tigase.halcyon.core.xmpp.modules.chatmarkers.getChatMarkerOrNull
import tigase.halcyon.core.xmpp.modules.chatmarkers.isChatMarkerRequested
import tigase.halcyon.core.xmpp.modules.mam.ForwardedStanza
import tigase.halcyon.core.xmpp.modules.mix.MixAnnotation
import tigase.halcyon.core.xmpp.modules.mix.getMixAnnotation
import tigase.halcyon.core.xmpp.modules.uniqueId.getOriginID
import tigase.halcyon.core.xmpp.modules.uniqueId.getStanzaIDBy
import tigase.halcyon.core.xmpp.stanzas.Message
import tigase.halcyon.core.xmpp.stanzas.MessageType

private fun interlocutor(
    account: BareJID, e: MessageToProcess,
): Triple<BareJID, MessageState, MixAnnotation?>? {
    val annotation = e.stanza.getMixAnnotation()
    val from = e.stanza.from?.bareJID ?: return null

    var state = MessageState.IncomingUnread
    if (annotation != null && annotation.jid == account) {
        state = MessageState.OutSent
    }
    if (from != account) return Triple(from, state, annotation)
    val to = e.stanza.to?.bareJID ?: return null
    return Triple(to, MessageState.OutSent, annotation)
}

enum class MessageCollectionType {

    FromServer,
    FromMAM,
    FromMIX
}

fun ChatsServiceImpl.processReceivedMessages(
    type: MessageCollectionType,
    account: BareJID,
    events: Collection<MessageToProcess>,
    forceCreateNewChat: Boolean = false,
): Single<List<MsgMeta>> {
    return createOpenChatsAndPreprocessMessages(type, account, events, forceCreateNewChat).flatMap { z ->
        saveOrUpdateMessages(z).andThen(receiptProcessor(z))
            .andThen(findNewestChatMarker(type, z))
            .flatMap { list ->
                updateChatMarkerDB(list).andThen(processChatMarker(list))
                    .andThen(singleOf(list))
            }
            .flatMap { singleOf(z) }
    }
        .doOnBeforeSuccess {
            this.loadAttachments(account, it)
        }
}

//fun ChatsServiceImpl.saveOrUpdateMessages(z: List<MsgMeta>) = chatsQueries.execute { q ->
//	z.forEach {
//		val updated = if (it.correctLastMessageId != null) {
//			q.updateLastMessage(it)
//		} else {
//			false
//		}
//		if (!updated) q.saveOrUpdate(it)
//	}
//}
fun ChatsServiceImpl.saveOrUpdateMessages(z: List<MsgMeta>) = attachmentQueries.execute { q ->
    z.filter { it.attachment != null }
        .forEach {
            val name = it.attachment!!.url.substringAfterLast("/")
            q.addAttachment(
                chatId = it.openChat.id,
                account = it.openChat.account,
                originalStanzaId = it.originId,
                url = it.attachment.url,
                name = name
            )
        }
}
    .andThen(chatsQueries.execute { q ->
        z.forEach {
            val updated = if (it.correctLastMessageId != null) {
                q.updateLastMessage(it)
            } else {
                false
            }
            if (!updated) q.saveOrUpdate(it)
        }
    })

fun ChatsServiceImpl.createOpenChatsAndPreprocessMessages(
    type: MessageCollectionType,
    account: BareJID,
    events: Collection<MessageToProcess>,
    forceCreateNewChat: Boolean,
): Single<List<MsgMeta>> {
    val createNewChats: Boolean =
        forceCreateNewChat || (type == MessageCollectionType.FromServer || type == MessageCollectionType.FromMIX)
    val annotatedStanzas = events.mapNotNull { interlocutor(account, it)?.let { s -> Pair(s, it) } }
        .sortedBy { it.second.timestamp }
    return singleOf(annotatedStanzas.distinctBy { it.first.first }).flatten()
        .flatMapSingle {
            openChatWith(account, it.first.first, createNewChats && it.second.stanza.hasVisibleContent())
        }
        .notNull()
        .toList()
        .map { it.associateBy { it.jid } }
        .flatMap { openChats ->
            annotatedStanzas.filter { openChats.containsKey(it.first.first) }
                .mapNotNull {
                    val (conversationWithJid, firstState, annotation) = it.first
                    val event = it.second
                    openChats[conversationWithJid]?.let { openChat ->
                        createMsgMeta(openChat, type, event, conversationWithJid, firstState, annotation)
                    }
                }
                .toSingle()
        }

}

fun createMsgMeta(
    openChat: OpenChats,
    type: MessageCollectionType,
    event: MessageToProcess,
    conversationWithJid: BareJID,
    firstState: MessageState,
    annotation: MixAnnotation?,
): MsgMeta {
    val resultId = event.resultId
    val senderJid = annotation?.jid ?: event.stanza.from
    val participantId = if (annotation == null) null else event.stanza.from?.resource

    val recipientJid = when {
        event.stanza.to != null -> event.stanza.to
        event.stanza.type == MessageType.Groupchat -> openChat.jid
        else -> null
    }
    val originId = event.stanza.getOriginID() ?: event.stanza.attributes["id"] ?: nextUIDLongs()
    val mamStanzaId =
        if (type == MessageCollectionType.FromMAM) resultId else event.stanza.getStanzaIDBy(openChat.account)
    val remoteStanzaId = when {
        type == MessageCollectionType.FromMIX -> resultId
        type == MessageCollectionType.FromServer && openChat.type == OpenChatType.Mix -> event.stanza.getStanzaIDBy(
            openChat.jid
        )

        else -> null
    }

    val state = firstState
//					if (firstState == MessageState.IncomingUnread && visibleChatId == openChat.id && FocusService.focused) MessageState.IncomingRead else firstState

    val attachment = getAttachmentData(event.stanza)

    return MsgMeta(
        mtp = event,
        senderJid = senderJid,
        recipientJid = recipientJid,
        conversationWithJid = conversationWithJid,
        participantId = participantId,
        openChat = openChat,
        annotation = annotation,
        state = state,
        originId = originId,
        markable = event.stanza.isChatMarkerRequested(),
        mamStanzaId = mamStanzaId,
        remoteStanzaId = remoteStanzaId,
        senderNick = annotation?.nick,
        timestamp = event.timestamp,
        correctLastMessageId = event.stanza.getLastMessageCorrectionIdOrNull(),
        attachment = attachment
    )
}

fun getAttachmentData(stanza: Message): Attachment? {
    val url = stanza.getChildrenNS("x", "jabber:x:oob")
        ?.getFirstChild("url") ?: return null
    return Attachment(url.value!!)
}

fun ChatsServiceImpl.findNewestChatMarker(type: MessageCollectionType, list: List<MsgMeta>): Single<List<MsgMeta>> {
    return singleOf(list).map {
        it.filter { it.mtp.stanza.getChatMarkerOrNull() != null && it.senderJid != null }
            .groupBy {
                "${it.openChat.id}:${it.senderNick ?: ""}:${it.participantId ?: ""}:${it.senderJid}"
            }
            .mapNotNull {
                it.value.maxByOrNull { it.mtp.timestamp }
            }
    }
        .flatten()
        .flatMapMaybe {
            val marker = it.mtp.stanza.getChatMarkerOrNull()!!
            when (it.openChat.type) {
                OpenChatType.Direct -> findByOriginId(it.openChat.id, it.recipientJid!!.bareJID, marker.originId)
                OpenChatType.Mix -> findByRemoteId(it.openChat.id, marker.originId)
            }.map { oldMessage ->
                it.copy(timestamp = oldMessage.timestamp)
            }
        }
        .toList()
}

data class MsgMeta(
    val conversationWithJid: BareJID,
    val openChat: OpenChats,
    val annotation: MixAnnotation?,
    val mtp: MessageToProcess,
    val originId: String,
    val mamStanzaId: String?,
    val remoteStanzaId: String?,
    val state: MessageState,
    val correctLastMessageId: String?,
    val markable: Boolean,
    val participantId: String?,
    val senderJid: JID?,
    val senderNick: String?,
    val recipientJid: JID?,
    val timestamp: Instant,
    val attachment: Attachment?,
)

data class Attachment(val url: String)

fun MessagesDatabaseQueries.updateLastMessage(msg: MsgMeta): Boolean {
    if (!msg.mtp.stanza.hasVisibleContent() || msg.senderJid == null || msg.recipientJid == null) return false
    val author_jid = msg.annotation?.jid ?: msg.senderJid
    val updateId =
        getRowIdByOriginId(msg.openChat.id, "${author_jid.bareJID}%", msg.correctLastMessageId!!).executeAsOneOrNull()
    return if (updateId != null) {
        this.updateBody(
            id = updateId,
            body = EscapeUtils.unescape(msg.mtp.stanza.body) ?: "",
            correction_stanza_id = msg.originId,
            correction_timestamp = msg.timestamp
        )
        true
    } else {
        false
    }
}

fun MessagesDatabaseQueries.saveOrUpdate(msg: MsgMeta) {
    if (!msg.mtp.stanza.hasVisibleContent() || msg.senderJid == null || msg.recipientJid == null) return
    this.saveOrUpdate(
        openChatId = msg.openChat.id,
        account = msg.openChat.account,
        senderJid = msg.senderJid,
        annotation = msg.annotation,
        recipientJid = msg.recipientJid,
        stanzaType = msg.mtp.stanza.type ?: MessageType.Normal,
        originId = msg.originId,
        mamStanzaId = msg.mamStanzaId,
        remoteStanzaId = msg.remoteStanzaId,
        body = EscapeUtils.unescape(msg.mtp.stanza.body) ?: "",
        timestamp = msg.mtp.timestamp,
        state = msg.state,
        participantId = msg.participantId,
        markable = msg.markable
    )
}

fun ForwardedStanza<Message>.toMessageToProcess(): MessageToProcess =
    MessageToProcess(this.stanza, this.parent?.attributes?.get("id"), this.timestamp ?: Clock.System.now())

fun Message.toMessageToProcess(): MessageToProcess = MessageToProcess(this, null)

data class MessageToProcess(
    val stanza: Message,
    val resultId: String? = null,
    val timestamp: Instant = Clock.System.now(),
)