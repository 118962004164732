package org.tigase.officialtea.common.services.halcyon

import tigase.halcyon.core.Context
import tigase.halcyon.core.modules.AbstractXmppModule
import tigase.halcyon.core.modules.XmppModuleProvider
import tigase.halcyon.core.requests.RequestBuilder
import tigase.halcyon.core.xml.Element
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.ErrorCondition
import tigase.halcyon.core.xmpp.XMPPException
import tigase.halcyon.core.xmpp.forms.FieldType
import tigase.halcyon.core.xmpp.forms.FormType
import tigase.halcyon.core.xmpp.forms.JabberDataForm
import tigase.halcyon.core.xmpp.modules.commands.CommandsModule
import tigase.halcyon.core.xmpp.stanzas.IQ

interface PushNotificationsModuleConfig

class PushNotificationsModule(context: Context) : PushNotificationsModuleConfig, AbstractXmppModule(
	context = context, type = TYPE, features = arrayOf(XMLNS), criteria = null
) {

	companion object : XmppModuleProvider<PushNotificationsModule, PushNotificationsModuleConfig> {

		const val XMLNS = "urn:xmpp:push:0"
		override val TYPE = XMLNS
		override fun instance(context: Context): PushNotificationsModule = PushNotificationsModule(context)

		override fun configure(module: PushNotificationsModule, cfg: PushNotificationsModuleConfig.() -> Unit) =
			module.cfg()
	}

	override fun process(element: Element) = throw XMPPException(ErrorCondition.BadRequest)

	fun registerInPushService(pushServiceJid: BareJID, deviceToken: String): RequestBuilder<String, IQ> {
		val form = JabberDataForm.create(FormType.Submit)
			.apply {
				this.addField("provider", FieldType.ListSingle)
					.apply {
						this.fieldValue = "fcm-xmpp-api"
					}
				this.addField("device-token", FieldType.TextSingle)
					.apply {
						this.fieldValue = deviceToken
					}
			}.element
		return context.modules.getModule<CommandsModule>(CommandsModule.TYPE)
			.executeCommand(pushServiceJid, "register-device", form)
			.map {
				it.form?.getFieldByVar("node")?.fieldValue ?: throw XMPPException(
					ErrorCondition.BadRequest, "Cannot find node in response."
				)
			}
	}

	fun enable(pushServiceJid: BareJID, node: String): RequestBuilder<Unit, IQ> = context.request.iq {
		"enable" {
			xmlns = XMLNS
			attribute("jid", pushServiceJid.toString())
			attribute("node", node)
		}
	}
		.map { }

	fun disable(pushServiceJid: BareJID): RequestBuilder<Unit, IQ> = context.request.iq {
		"disable" {
			xmlns = XMLNS
			attribute("jid", pushServiceJid.toString())
		}
	}
		.map { }

	fun unregisterInPushService(pushServiceJid: BareJID, deviceToken: String): RequestBuilder<Unit, IQ> {
		val form = JabberDataForm.create(FormType.Submit)
			.apply {
				this.addField("provider", FieldType.ListSingle)
					.apply {
						this.fieldValue = "fcm-xmpp-api"
					}
				this.addField("device-token", FieldType.TextSingle)
					.apply {
						this.fieldValue = deviceToken
					}
			}.element
		return context.modules.getModule<CommandsModule>(CommandsModule.TYPE)
			.executeCommand(pushServiceJid, "unregister-device", form)
			.map {}
	}

}