package org.tigase.officialtea.common.main.components.roster

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.observable.map
import com.badoo.reaktive.observable.observeOn
import com.badoo.reaktive.scheduler.mainScheduler
import org.tigase.officialtea.common.main.components.roster.Roster.Model
import org.tigase.officialtea.common.main.components.roster.RosterStore.Intent
import org.tigase.officialtea.common.main.components.roster.RosterStore.Label
import org.tigase.officialtea.common.services.ConnectionService
import org.tigase.officialtea.common.services.RosterPresenceItem
import org.tigase.officialtea.common.services.RosterPresenceService
import tigase.halcyon.core.Halcyon
import tigase.halcyon.core.ReflectionModuleManager
import tigase.halcyon.core.xmpp.modules.spam.BlockingCommandModule

internal interface RosterStore : Store<Intent, Model, Label> {

	sealed interface Intent {

		data class SelectRosterItem(val item: RosterPresenceItem) : Intent
		data class ActionMenu(val item: RosterPresenceItem) : Intent
		object CloseActionMenu : Intent
		data class RosterEditMode(val editing: Boolean) : Intent
	}

	sealed interface Label
}

internal class RosterStoreProvider(
	private val storeFactory: StoreFactory, private val service: RosterPresenceService, private val connectionService: ConnectionService
) {

	fun provide(): RosterStore = object : RosterStore, Store<Intent, Model, Label> by storeFactory.create(
		name = "RosterStore", initialState = Model(), bootstrapper = SimpleBootstrapper(
			Unit
		), executorFactory = ::ExecutorImpl, reducer = ReducerImpl
	) {}

	private sealed interface Result {

		data class RosterLoaded(val items: List<RosterPresenceItem>) : Result
		data class RosterItemSelected(val item: RosterPresenceItem) : Result
		data class ActionMenu(val item: RosterPresenceItem?) : Result
		data class RosterItemsSelected(val groupSelected: List<RosterPresenceItem>) : Result
		data class RosterEditMode(val editing: Boolean) : Result
	}

	private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, Model, Result, Label>() {

		override fun executeAction(action: Unit, getState: () -> Model) {
			service.observeAll()
				.observeOn(mainScheduler)
				.map { list ->
					Result.RosterLoaded(list.sortedBy { it.name.ifBlank { it.jid.toString() } })
				}
				.subscribeScoped(onNext = ::dispatch)
		}

		override fun executeIntent(intent: Intent, getState: () -> Model) = when (intent) {
			is Intent.SelectRosterItem -> selectRosterItem(intent.item, getState())
			is Intent.RosterEditMode -> changeRosterEditMode(intent.editing, getState())
			is Intent.ActionMenu -> actionMenu(intent.item, getState())
			is Intent.CloseActionMenu -> actionMenu(null, getState())
		}

		private fun actionMenu(selectedItem: RosterPresenceItem?, state: Model) {
			if (state.actionMenu == selectedItem) {
				dispatch(Result.ActionMenu(null))
			} else {
//				changeRosterEditMode(false, state)
				if (selectedItem != null) selectRosterItem(selectedItem, state, true)
				dispatch(Result.ActionMenu(selectedItem))
			}
		}

		private fun selectRosterItem(selectedItem: RosterPresenceItem, state: Model, disableDeselect: Boolean = false) {
			actionMenu(null, state)
			if (state.editMode) {
				val selectedList = when {
					!state.selected.contains(selectedItem) -> state.selected + selectedItem
					disableDeselect -> state.selected
					else -> state.selected - selectedItem
				}
				dispatch(Result.RosterItemsSelected(selectedList))
			} else dispatch(Result.RosterItemSelected(selectedItem))
		}

		private fun changeRosterEditMode(editMode: Boolean, state: Model) {
			if (editMode == state.editMode) return

			actionMenu(null, state)
			dispatch(Result.RosterItemsSelected(emptyList()))
			dispatch(Result.RosterEditMode(editMode))
		}
	}

	private object ReducerImpl : Reducer<Model, Result> {

		override fun Model.reduce(result: Result): Model = when (result) {
			is Result.RosterItemSelected -> copy(selected = listOf(result.item))
			is Result.RosterItemsSelected -> copy(selected = result.groupSelected)
			is Result.RosterLoaded -> copy(items = result.items)
			is Result.RosterEditMode -> copy(editMode = result.editing)
			is Result.ActionMenu -> copy(actionMenu = result.item)
		}
	}
}
