package org.tigase.officialtea.common.main.settings

import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import org.tigase.officialtea.common.main.settings.accountslist.AccountsRoot
import org.tigase.officialtea.common.main.settings.preferences.Preferences
import org.tigase.officialtea.common.main.settings.themes.Themes

interface Settings {

	val routerState: Value<ChildStack<SettingsComponent.SettingsConfiguration, Child>>

	val options: Options

	sealed class Child {

		data class Accounts(val component: AccountsRoot) : Child()
		data class AppPreferences(val component: Preferences) : Child()
		data class Appearance(val component: Themes) : Child()
		data class Menu(val component: Settings) : Child()
	}

	fun select(screen: SettingsComponent.SettingsConfiguration)
}