package org.tigase.officialtea.common.main.components.roster

import com.arkivanov.decompose.Child
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.*
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.badoo.reaktive.base.Consumer
import org.tigase.officialtea.common.services.RosterPresenceItem
import org.tigase.officialtea.common.services.ServiceKeeper

interface RosterMain {
    fun onClickAddItem()

    val childStack: Value<ChildStack<*, Child>>

    sealed class Child {
        class ListChild(val component: Roster) : Child()
        class EditorChild(val component: RosterItemEditor) : Child()
    }
}

class RosterMainComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
    private val serviceKeeper: ServiceKeeper,
    private val startChatOutput: Consumer<RosterPresenceItem>,
) : RosterMain, ComponentContext by componentContext {

    private val navigation = StackNavigation<Config>()

    override val childStack: Value<ChildStack<*, RosterMain.Child>> =
        childStack(
            source = navigation,
            initialConfiguration = Config.List(),
            handleBackButton = true, // Pop the back stack on back button press
            childFactory = ::createChild,
        )

    private fun createChild(config: Config, componentContext: ComponentContext): RosterMain.Child =
        when (config) {
            is Config.List -> RosterMain.Child.ListChild(
                RosterComponent(
                    componentContext = componentContext,
                    storeFactory = storeFactory,
                    service = serviceKeeper.rosterPresenceService,
                    startChatOutput = startChatOutput,
                    connectionService = serviceKeeper.connectionService
                )
            )

            is Config.Editor -> RosterMain.Child.EditorChild(
                RosterItemEditorComponent(
                    componentContext = componentContext,
                    storeFactory = storeFactory,
                    serviceKeeper = serviceKeeper,
                    onDone = { navigation.popWhile { topOfStack -> topOfStack !is Config.List } }
                )
            )
        }

    override fun onClickAddItem() {
        navigation.push(Config.Editor())
    }


    private sealed class Config : Parcelable {
        @Parcelize
        class List : Config()

        @Parcelize
        class Editor : Config()

//        @Parcelize
//        data class Details(val itemId: Long) : Config()
    }
}