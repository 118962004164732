package org.tigase.officialtea.common.database

import kotlin.String

public data class GetVersion(
  public val roster_version: String?
) {
  public override fun toString(): String = """
  |GetVersion [
  |  roster_version: $roster_version
  |]
  """.trimMargin()
}
