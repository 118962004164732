package org.tigase.officialtea.common.main.components.accountStatus

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.store.StoreFactory
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.asValue
import org.tigase.officialtea.common.utils.getStore
import tigase.halcyon.core.AbstractHalcyon
import tigase.halcyon.core.xmpp.BareJID

interface AccountStatus {

    val models: Value<Model>

    data class Model(
        val jid: BareJID? = null,
        val displayName: String,
        val state: AbstractHalcyon.State = AbstractHalcyon.State.Stopped,
        val avatarJid: BareJID?=null
    )

}

class AccountStatusComponent(
    private val componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
    private val serviceKeeper: ServiceKeeper,
) : AccountStatus, ComponentContext by componentContext {

    private val store = instanceKeeper.getStore {
        AccountStatusStoreFactory(storeFactory, serviceKeeper, componentContext).create()
    }

    override val models = store.asValue()

}

