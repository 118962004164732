package org.tigase.officialtea.common.services.roster

import com.badoo.reaktive.single.Single
import com.badoo.reaktive.single.subscribe
import com.squareup.sqldelight.db.SqlDriver
import org.tigase.officialtea.common.database.Roster
import org.tigase.officialtea.common.database.SharedDatabase
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.mix.MIXRosterItemAnnotation
import tigase.halcyon.core.xmpp.modules.roster.Ask
import tigase.halcyon.core.xmpp.modules.roster.RosterItem
import tigase.halcyon.core.xmpp.modules.roster.RosterStore

class AccountRosterStore(val account: BareJID, driver: Single<SqlDriver>) : RosterStore {

//	private val queries = driver.map { SharedDatabase(it).rosterDatabaseQueries }
//		.asObservable()
//		.replay()
//		.autoConnect()
//		.firstOrError()

	private lateinit var dbDriver: SqlDriver

	init {
		driver.subscribe {
			dbDriver = it
		}
	}

	override fun addItem( value: RosterItem) {
		SharedDatabase(dbDriver).rosterDatabaseQueries.add(
			account = account,
			jid = value.jid,
			name = value.name,
			ask = value.ask == Ask.Subscribe,
			subscription = value.subscription,
			last_status = null,
			mixParticipantId = value.annotations.filterIsInstance<MIXRosterItemAnnotation>()
				.firstOrNull()?.participantId
		)
	}

	override fun getAllItems(): List<RosterItem> =
		SharedDatabase(dbDriver).rosterDatabaseQueries.selectAllFromAccount(account)
			.executeAsList()
			.map(Roster::toRosterItem)

	override fun getItem(jid: BareJID): RosterItem? =
		SharedDatabase(dbDriver).rosterDatabaseQueries.getItem(account, jid)
			.executeAsOneOrNull()
			?.toRosterItem()

	override fun getVersion(): String = SharedDatabase(dbDriver).rosterDatabaseQueries.getVersion(account)
		.executeAsOneOrNull()?.roster_version ?: ""

	override fun removeItem(jid: BareJID) = SharedDatabase(dbDriver).rosterDatabaseQueries.removeItem(account, jid)

	override fun setVersion(version: String) =
		SharedDatabase(dbDriver).rosterDatabaseQueries.setVersion(version, account)

	override fun updateItem( value: RosterItem) {
		SharedDatabase(dbDriver).rosterDatabaseQueries.update(
			account = account,
			jid = value.jid,
			name = value.name,
			ask = value.ask == Ask.Subscribe,
			subscription = value.subscription,
			last_status = null
		)
	}
}

fun Roster.toRosterItem(): RosterItem {
	return RosterItem(
		jid = this.jid,
		name = this.name,
		groups = emptyList(),
		ask = if (this.ask) Ask.Subscribe else null,
		subscription = this.subscription,
		annotations = if (this.mixParticipantId != null) {
			arrayOf(MIXRosterItemAnnotation(this.mixParticipantId!!))
		} else emptyArray()
	)
}