package org.tigase.officialtea.common.main.settings.accountslist

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.badoo.reaktive.base.Consumer
import com.badoo.reaktive.base.invoke
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.services.AccountsService
import org.tigase.officialtea.common.services.ConnectionService
import org.tigase.officialtea.common.main.settings.accountslist.AccountsList.Model
import org.tigase.officialtea.common.utils.asValue

class AccountsListComponent(
	componentContext: ComponentContext, storeFactory: StoreFactory, private val accountsService: AccountsService,
	connectionService: ConnectionService,
	private val accountSelectedOutput: Consumer<Accounts>,
	private val addClickOutput: Consumer<Unit>,
) : AccountsList, ComponentContext by componentContext {

	private val store = instanceKeeper.getStore {
		AccountsListStoreProvider(
			storeFactory = storeFactory, accountsService = accountsService, connectionService = connectionService
		).provide()
	}

	override val models: Value<Model> = store.asValue()

	override fun select(item: Accounts) {
		accountSelectedOutput(item)
	}

	override fun onAddClick() {
		addClickOutput(Unit)
	}

}