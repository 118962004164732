package org.tigase.officialtea.common.main.components.messageinput

import com.arkivanov.decompose.value.Value
import com.badoo.reaktive.observable.Observable
import org.tigase.officialtea.common.services.FileToUpload

interface MessageInput {

	val models: Value<Model>

	fun onTextChanged(text: String)
	fun addNewLine()
	fun onSendClick()
	fun onFileChanged(fileData: FileToUpload)
	fun onAttachmentClick(open: Boolean)
	fun onEmojiClick(open: Boolean)

	fun editLastMessageClick()

	fun reset()

	data class Model(
		val placeholder: String = "",
		val text: String = "",
		val attachmentsOpen: Boolean = false,
		val emojisOpen: Boolean = false,
		val file: FileToUpload? = null
	)

	sealed interface Output {

		data class Finished(val text: String?, val file: FileToUpload?) : Output
		object EditLastMessage : Output

	}

	fun labels(): Observable<MessageInputStore.Label>

}