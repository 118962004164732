package org.tigase.officialtea.common.main.components.accounts

import com.arkivanov.decompose.value.Value
import org.tigase.officialtea.common.database.Accounts



interface AccountsInt {

    val models: Value<Model>

    fun loadActiveAccounts()

    data class Model(
        val activeAccounts: List<Accounts> = emptyList()
    )
}