package org.tigase.officialtea.common.main.components.mixbrowser

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.badoo.reaktive.maybe.doOnBeforeError
import com.badoo.reaktive.maybe.flatMapSingle
import com.badoo.reaktive.maybe.map
import com.badoo.reaktive.maybe.subscribe
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.asValue
import tigase.halcyon.core.logger.LoggerFactory
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.mix.MIXModule
import tigase.halcyon.core.xmpp.toBareJID
import tigase.halcyon.rx.asSingle

class MixChannelsListComponent(
    componentContext: ComponentContext,
    storeFactory: StoreFactory,
    val serviceKeeper: ServiceKeeper,
) : MixChannelsList, ComponentContext by componentContext {

    private val log =
        LoggerFactory.logger("org.tigase.officialtea.common.main.components.mixbrowser.MixChannelsListComponent")


    private val store = instanceKeeper.getStore {
        MixChannelsListStoreProvider(storeFactory = storeFactory, serviceKeeper = serviceKeeper).provide()
    }

    override val models: Value<MixChannelsList.Model> = store.asValue()

    override fun setAddress(account: BareJID, address: BareJID) =
        store.accept(MixChannelsListStore.Intent.SetAddress(account, address))

	override fun selectItem(item: MixChannelsList.Item?) = store.accept(MixChannelsListStore.Intent.SelectItem(item))

	override fun joinMixChannel(item: MixChannelsList.Item) =
		store.accept(MixChannelsListStore.Intent.JoinChannel(item))

}