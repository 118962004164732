package org.tigase.officialtea.common.main.components.NewChat

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.store.StoreFactory
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.asValue
import org.tigase.officialtea.common.utils.getStore
import tigase.halcyon.core.xmpp.BareJID

class NewChatComponent(
    componentContext: ComponentContext,
    storeFactory: StoreFactory,
    serviceKeeper: ServiceKeeper
) : NewChat, ComponentContext by componentContext{

    private val store = instanceKeeper.getStore {
        NewChatStoreProvider(storeFactory, serviceKeeper, componentContext).provide()
    }

    override val models: Value<NewChat.Model> = store.asValue()

    override fun enterJid(jid: BareJID) {
        store.accept(NewChatStore.Intent.enterJid(jid))
    }

    override fun removeJidAt(index: Int) {
        store.accept(NewChatStore.Intent.removeJidAt(index))
    }

    override fun chatName(name: String) {
        store.accept(NewChatStore.Intent.chatName(name))
    }
    override fun selectedJid(jid: BareJID) {
        store.accept(NewChatStore.Intent.selectJid(jid))
    }

    override fun typingJid(name: String) {
        store.accept(NewChatStore.Intent.typingJid(name))
    }

    override fun clearEnteredJids() {
        store.accept(NewChatStore.Intent.clearEnteredJids)
    }
}