package org.tigase.officialtea.common.main.components.messageinput

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import org.tigase.officialtea.common.main.components.messageinput.MessageInputStore.Intent
import org.tigase.officialtea.common.services.FileToUpload

interface MessageInputStore : Store<Intent, MessageInput.Model, MessageInputStore.Label> {

	sealed interface Intent {

		data class SetText(val text: String) : Intent
		data class SetFile(val file: FileToUpload) : Intent
		data class AddToText(val text: String) : Intent
		data class SetPlaceholder(val text: String) : Intent
		data class AttachmentsOpen(val open: Boolean) : Intent
		data class EmojisOpen(val open: Boolean) : Intent
		object Reset : Intent
	}

	sealed interface Label {

		class Updated : Label
	}

}

internal class MessageInputStoreProvider(
	private val storeFactory: StoreFactory,
) {

	fun provide(): MessageInputStore =
		object : MessageInputStore, Store<Intent, MessageInput.Model, MessageInputStore.Label> by storeFactory.create(
			name = "MessageInput",
			initialState = ReducerImpl.initialState.copy(),
			executorFactory = ::ExecutorImpl,
			bootstrapper = SimpleBootstrapper(Unit),
			reducer = ReducerImpl
		) {}

	private inner class ExecutorImpl :
		ReaktiveExecutor<Intent, Unit, MessageInput.Model, Intent, MessageInputStore.Label>() {

		override fun executeIntent(intent: Intent, getState: () -> MessageInput.Model) {
			dispatch(intent)
			if (intent is Intent.SetText) {
				publish(MessageInputStore.Label.Updated())
			}
		}
	}

	private object ReducerImpl : Reducer<MessageInput.Model, Intent> {

		val initialState = MessageInput.Model(text = "")

		override fun MessageInput.Model.reduce(result: Intent): MessageInput.Model = when (result) {
			is Intent.Reset -> initialState.copy()
			is Intent.SetText -> copy(text = result.text)
			is Intent.SetPlaceholder -> copy(placeholder = result.text)
			is Intent.AddToText -> copy(text = this.text + result.text)
			is Intent.SetFile -> copy(file = result.file)
			is Intent.AttachmentsOpen -> copy(attachmentsOpen = result.open)
			is Intent.EmojisOpen -> copy(emojisOpen = result.open)
		}
	}
}
