package org.tigase.officialtea.common.services.halcyon

import org.tigase.officialtea.common.services.halcyon.LastMessageCorrectionModule.Companion.XMLNS
import tigase.halcyon.core.Context
import tigase.halcyon.core.exceptions.HalcyonException
import tigase.halcyon.core.modules.AbstractXmppModule
import tigase.halcyon.core.modules.XmppModuleProvider
import tigase.halcyon.core.xml.Element
import tigase.halcyon.core.xmpp.stanzas.Message
import tigase.halcyon.core.xmpp.stanzas.MessageNode

interface LastMessageCorrectionModuleConfig

class LastMessageCorrectionModule(context: Context) : LastMessageCorrectionModuleConfig, AbstractXmppModule(
	context = context, type = TYPE, features = arrayOf(XMLNS), criteria = null
) {

	companion object : XmppModuleProvider<LastMessageCorrectionModule, LastMessageCorrectionModuleConfig> {

		const val XMLNS = "urn:xmpp:message-correct:0"
		override val TYPE = XMLNS
		override fun instance(context: Context): LastMessageCorrectionModule = LastMessageCorrectionModule(context)

		override fun configure(module: LastMessageCorrectionModule, cfg: LastMessageCorrectionModuleConfig.() -> Unit) =
			module.cfg()

	}

	override fun process(element: Element) = throw HalcyonException("Cannot be executed")
}

fun Message.getLastMessageCorrectionIdOrNull(): String? = this.getChildrenNS("replace", XMLNS)
	?.let { it.attributes["id"] }

fun MessageNode.lastMessageCorrection(stanzaId: String) = this.addChild(tigase.halcyon.core.xml.element("replace") {
	xmlns = XMLNS
	attribute("id", stanzaId)
})