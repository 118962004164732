package org.tigase.officialtea.common.main.components.chat.mixparticipants

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import org.tigase.officialtea.common.main.components.chat.ChatData
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.asValue

class MixParticipantsComponent(
	componentContext: ComponentContext,
	storeFactory: StoreFactory,
	private val chatData: ChatData,
	private val serviceKeeper: ServiceKeeper,
) : MixParticipants, ComponentContext by componentContext {

	private val store = instanceKeeper.getStore {
		MixParticipantsStoreProvider(storeFactory = storeFactory, chatData, serviceKeeper).provide()
	}

	override val models: Value<MixParticipants.Model> = store.asValue()

}