package org.tigase.officialtea.common.main.components.chat

import tigase.halcyon.core.xmpp.BareJID

data class ChatData(
	val account: BareJID,
	val chatId: Long,
	val jid: BareJID,
	val title: String,
	val type: ChatPanel.Type,
)