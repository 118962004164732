package org.tigase.officialtea.common.services.settings

import com.arkivanov.decompose.value.MutableValue
import com.russhwolf.settings.ExperimentalSettingsApi
import com.russhwolf.settings.ObservableSettings
import com.russhwolf.settings.Settings
import com.russhwolf.settings.set

@ExperimentalSettingsApi
abstract class AbstractApplicationSettings(settings: Settings) {
	abstract val allowTranslation: MutableValue<Boolean>
	abstract val keepSideOpenWhileChatting: MutableValue<Boolean>
	abstract val keepSideOpenWhileNavigating: MutableValue<Boolean>
	abstract val wrapMessagesInBubbles: MutableValue<Boolean>
}
@ExperimentalSettingsApi
expect class ApplicationSettings(settings: Settings) : AbstractApplicationSettings {
}
fun Settings.booleanProperty(key: String, defaultValue: Boolean): MutableValue<Boolean> {
	val v = this.getBoolean(key, defaultValue)
	val result = MutableValue(v)
	result.subscribe {
		this.set(key, it)
	}
//	this.addBooleanListener(key, defaultValue, {
//		result.value = it
//	})
	return result
}

