package org.tigase.officialtea.common.database

import kotlin.Long
import kotlin.String
import tigase.halcyon.core.xmpp.BareJID

public data class SelectWithUnread(
  public val id: Long,
  public val account: BareJID,
  public val jid: BareJID,
  public val name: String,
  public val type: OpenChatType,
  public val cnt: Long?,
  public val inroster: Long?
) {
  public override fun toString(): String = """
  |SelectWithUnread [
  |  id: $id
  |  account: $account
  |  jid: $jid
  |  name: $name
  |  type: $type
  |  cnt: $cnt
  |  inroster: $inroster
  |]
  """.trimMargin()
}
