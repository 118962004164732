package org.tigase.officialtea.common.services

import com.badoo.reaktive.completable.Completable
import com.badoo.reaktive.completable.doOnAfterComplete
import com.badoo.reaktive.maybe.Maybe
import com.badoo.reaktive.observable.Observable
import com.badoo.reaktive.observable.autoConnect
import com.badoo.reaktive.observable.firstOrError
import com.badoo.reaktive.observable.replay
import com.badoo.reaktive.single.*
import com.badoo.reaktive.subject.Subject
import com.badoo.reaktive.subject.publish.PublishSubject
import com.squareup.sqldelight.db.SqlDriver
import org.tigase.officialtea.common.database.*
import tigase.halcyon.core.xmpp.BareJID

class AccountsServiceImpl  constructor(driver: Single<SqlDriver>) : AccountsService {

	constructor(driver: SqlDriver) : this(singleOf(driver))

	override val updates: Subject<AccountsService.ChangeEvent> = PublishSubject()

	private val queries: Single<AccountsDatabaseQueries> = driver.map { SharedDatabase(it).accountsDatabaseQueries }
		.asObservable()
		.replay()
		.autoConnect()
		.firstOrError()

	override var pushDeviceToken: String? = null

	override fun add(id: Long?,
					 enabled: Boolean,
					 userjid: BareJID,
					 password: String,
					 nickname: String?,
					 resource: String?,
					 hostname: String?,
					 port: Long?,
					 roster_version: String?,
					 push_node: String?,
					 push_enabled: Boolean): Completable = queries.execute {
		it.addFull(
			id = id,
			enabled = enabled,
			password = password,
			userjid = userjid,
			nickname = nickname,
			resource = resource,
			hostname = hostname,
			port = port,
			roster_version = roster_version,
			push_node = push_node,
			push_enabled = push_enabled
		)
	}

	override fun addOrUpdate(
		id: Long?,
		userjid: BareJID,
		password: String,
		nickname: String?,
		resource: String?,
		hostname: String?,
		port: Long?,
	): Completable = queries.execute {
		if (id != null) it.update(
			id = id,
			password = password,
			nickname = nickname.toNullIfEmpty(),
			resource = resource.toNullIfEmpty(),
			hostname = hostname.toNullIfEmpty(),
			port = port
		) else it.add(
			userjid = userjid,
			password = password,
			nickname = nickname.toNullIfEmpty(),
			resource = resource.toNullIfEmpty(),
			hostname = hostname.toNullIfEmpty(),
			port = port
		)
	}
		.doOnAfterComplete {
			updates.onNext(
				if (id == null) AccountsService.ChangeEvent.Added(userjid) else AccountsService.ChangeEvent.Updated(
					userjid
				)
			)
		}

	override fun observeAll(): Observable<List<Accounts>> = queries.query(AccountsDatabaseQueries::selectAll)
		.observe {
			it.executeAsList()
		}

	override fun loadAll(): Single<List<Accounts>> = queries.query(AccountsDatabaseQueries::selectAll)
		.map { it.executeAsList() }

	override fun load(name: BareJID): Maybe<Accounts> = queries.query { it.selectByName(name) }
		.mapNotNull { it.executeAsOneOrNull() }

	override fun load(id: Long): Maybe<Accounts> = queries.query { it.selectById(id) }
		.mapNotNull { it.executeAsOneOrNull() }

	override fun delete(name: BareJID): Completable = queries.execute { it.delete(name) }
		.doOnAfterComplete {
			updates.onNext(AccountsService.ChangeEvent.Removed(name))
		}

	override fun updatePushNode(id: Long, pushNode: String?, pushEnabled: Boolean): Completable =
		queries.execute { it.updatePushInfo(id = id, push_node = pushNode, push_enabled = pushEnabled) }

}

private fun String?.toNullIfEmpty(): String? = if (this.isNullOrEmpty()) null else this