package org.tigase.officialtea.common.root

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.childContext
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.replaceCurrent
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.badoo.reaktive.base.Consumer
import com.badoo.reaktive.base.invoke
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.delay
import com.badoo.reaktive.single.subscribe
import org.tigase.officialtea.common.main.components.NewChat.NewChat
import org.tigase.officialtea.common.main.components.NewChat.NewChatComponent
import org.tigase.officialtea.common.main.components.accountStatus.AccountStatusComponent
import org.tigase.officialtea.common.main.components.accounts.AccountsComponent
import org.tigase.officialtea.common.main.components.accounts.AccountsInt
import org.tigase.officialtea.common.main.components.chat.ChatData
import org.tigase.officialtea.common.main.components.chat.ChatPanel
import org.tigase.officialtea.common.main.components.chat.ChatPanelComponent
import org.tigase.officialtea.common.main.components.mixbrowser.MixBrowserComponent
import org.tigase.officialtea.common.main.components.roster.RosterMainComponent
import org.tigase.officialtea.common.main.components.sidemenu.SideMenu
import org.tigase.officialtea.common.main.components.sidemenu.SideMenuComponent
import org.tigase.officialtea.common.main.components.support.SupportComponent
import org.tigase.officialtea.common.main.settings.SettingsComponent
import org.tigase.officialtea.common.root.AppRoot.Child
import org.tigase.officialtea.common.services.*
import org.tigase.officialtea.common.services.settings.ApplicationSettings
import org.tigase.officialtea.common.utils.Consumer
import tigase.halcyon.core.logger.LoggerFactory
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.toBareJID

class AppRootComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
    val serviceKeeper: ServiceKeeper,
    val switchDarkMode: Consumer<Unit>,
    override val preferences: ApplicationSettings,
    override val chatsService: ChatsService,
    override val accountsService: AccountsService,
) : AppRoot, ComponentContext by componentContext {

    override val settingsComponent: SettingsComponent = SettingsComponent(
        childContext("SettingsComponent"), storeFactory, serviceKeeper
    )

    val log = LoggerFactory.logger("ROOT")

    override val accountStatus = AccountStatusComponent(childContext("AccountStatus"), storeFactory, serviceKeeper)

    override val sideMenu: SideMenu = SideMenuComponent(
        childContext("SideMenu"),
        storeFactory,
        serviceKeeper.chatsService,
        serviceKeeper.accountsService,
        serviceKeeper.rosterPresenceService,
        serviceKeeper.applicationPreferences,
        Consumer(::onChatSelect),
        switchDarkMode
    )

    override val newChat: NewChat = NewChatComponent(
        componentContext = childContext("NewChat"),
        storeFactory = storeFactory,
        serviceKeeper = serviceKeeper
    )

    override val accountsComponent: AccountsInt = AccountsComponent(
        childContext("Accounts"), storeFactory, serviceKeeper.accountsService
    )


    private val router = StackNavigation<Configuration>()
    private val stack = childStack(source = router,
        childFactory = ::createChild,
        initialStack = { listOf<Configuration>(Configuration.Roster) })

    private fun createChild(
        configuration: Configuration, componentContext: ComponentContext,
    ): Child = when (configuration) {
        is Configuration.Nothing -> Child.WelcomeScreen
        is Configuration.Conversation -> Child.Chat(
            ChatPanelComponent(
                componentContext, storeFactory, configuration.toModel(), serviceKeeper
            )
        )

        is Configuration.Roster -> Child.RosterScreen(
            RosterMainComponent(componentContext, storeFactory, serviceKeeper, Consumer(::onStartChat))
//            RosterComponent(
//                componentContext,
//                storeFactory,
//                serviceKeeper.rosterPresenceService,
//                Consumer(::onStartChat),
//                serviceKeeper.connectionService
//            )
        )


        Configuration.Settings -> Child.Settings(SettingsComponent(componentContext, storeFactory, serviceKeeper))
        Configuration.Support -> Child.Support(SupportComponent(componentContext, storeFactory, serviceKeeper))
        Configuration.MixChannelBrowser -> Child.MixChannelBrowser(
            MixBrowserComponent(
                componentContext, storeFactory, serviceKeeper
            )
        )

    }

    override val routerState = stack

    fun openChat(account: BareJID, jid: BareJID) {
        serviceKeeper.chatsService.openChatWith(account, jid)
            .delay(150, mainScheduler)
            .subscribe { oc ->
                sideMenu.open(oc)
            }
    }

    private fun onStartChat(rosterItem: RosterPresenceItem) {
        serviceKeeper.chatsService.openChatWith(rosterItem.account, rosterItem.jid)
            .delay(150, mainScheduler)
            .subscribe { oc ->
                sideMenu.open(oc)
            }
    }

    private fun onChatSelect(menuItem: SideMenu.MenuItem) {
        when (menuItem) {
            is SideMenu.MenuItem.MixChatMenuItem -> {
                router.replaceCurrent(
                    Configuration.Conversation(
                        menuItem.chat.account.toString(),
                        menuItem.chat.openChatId,
                        menuItem.chat.jid.toString(),
                        menuItem.chat.name,
                        Configuration.Type.Mix
                    )
                )
            }

            is SideMenu.MenuItem.DirectChatMenuItem -> {
                router.replaceCurrent(
                    Configuration.Conversation(
                        menuItem.chat.account.toString(),
                        menuItem.chat.openChatId,
                        menuItem.chat.jid.toString(),
                        menuItem.chat.name,
                        Configuration.Type.Direct
                    )
                )
            }

            is SideMenu.MenuItem.GeneralMenuItem -> when (menuItem.action) {
                SideMenu.MenuAction.ChannelBrowser -> router.replaceCurrent(Configuration.MixChannelBrowser)
                SideMenu.MenuAction.Roster -> router.replaceCurrent(Configuration.Roster)
                SideMenu.MenuAction.Settings -> router.replaceCurrent(Configuration.Settings)
                SideMenu.MenuAction.Support -> router.replaceCurrent(Configuration.Support)
            }

            is SideMenu.MenuItem.ChatMenuItem -> TODO()
        }
    }

    override fun toggleTheme() {
        switchDarkMode(Unit)
    }

    sealed class Configuration : Parcelable {

        enum class Type {
            Direct,
            Mix
        }

        @Parcelize
        object Nothing : Configuration()

        @Parcelize
        object MixChannelBrowser : Configuration()

        @Parcelize
        object Roster : Configuration()

        @Parcelize
        object Settings : Configuration()

        @Parcelize
        object Support : Configuration()

        @Parcelize
        data class Conversation(
            val account: String,
            val chatId: Long,
            val jid: String,
            val title: String,
            val type: Type,
        ) : Configuration()
    }
}

fun AppRootComponent.Configuration.Conversation.toModel(): ChatData = ChatData(
    this.account.toBareJID(), this.chatId, this.jid.toBareJID(), this.title, when (this.type) {
        AppRootComponent.Configuration.Type.Direct -> ChatPanel.Type.Direct
        AppRootComponent.Configuration.Type.Mix -> ChatPanel.Type.MIX
    }
)