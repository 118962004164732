package org.tigase.officialtea.common.database

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import tigase.halcyon.core.xmpp.BareJID

public data class Accounts(
  public val id: Long,
  public val enabled: Boolean,
  public val userjid: BareJID,
  public val password: String,
  public val nickname: String?,
  public val resource: String?,
  public val hostname: String?,
  public val port: Long?,
  public val roster_version: String?,
  public val push_node: String?,
  public val push_enabled: Boolean
) {
  public override fun toString(): String = """
  |Accounts [
  |  id: $id
  |  enabled: $enabled
  |  userjid: $userjid
  |  password: $password
  |  nickname: $nickname
  |  resource: $resource
  |  hostname: $hostname
  |  port: $port
  |  roster_version: $roster_version
  |  push_node: $push_node
  |  push_enabled: $push_enabled
  |]
  """.trimMargin()

  public class Adapter(
    public val userjidAdapter: ColumnAdapter<BareJID, String>
  )
}
