package org.tigase.officialtea.common.database

import com.squareup.sqldelight.ColumnAdapter
import com.squareup.sqldelight.EnumColumnAdapter
import com.squareup.sqldelight.db.SqlDriver
import kotlinx.datetime.Instant
import org.tigase.officialtea.database.OfficialTeaDatabase
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.JID
import tigase.halcyon.core.xmpp.toBareJID
import tigase.halcyon.core.xmpp.toJID

private val bareJidAdapter = object : ColumnAdapter<BareJID, String> {
	override fun decode(databaseValue: String): BareJID = databaseValue.toBareJID()

	override fun encode(value: BareJID): String = value.toString()
}

private val jidAdapter = object : ColumnAdapter<JID, String> {
	override fun decode(databaseValue: String): JID = databaseValue.toJID()

	override fun encode(value: JID): String = value.toString()
}

private val instantAdapter = object : ColumnAdapter<Instant, Long> {
	override fun decode(databaseValue: Long): Instant = Instant.fromEpochMilliseconds(databaseValue)

	override fun encode(value: Instant): Long = value.toEpochMilliseconds()

}

fun SharedDatabase(driver: SqlDriver): OfficialTeaDatabase {
	return OfficialTeaDatabase(
		driver,
		AccountsAdapter = Accounts.Adapter(userjidAdapter = bareJidAdapter),
		MessagesAdapter = Messages.Adapter(
			accountAdapter = bareJidAdapter,
			author_jidAdapter = jidAdapter,
			recipient_jidAdapter = jidAdapter,
			stateAdapter = EnumColumnAdapter(),
			timestampAdapter = instantAdapter,
			stanza_typeAdapter = EnumColumnAdapter(),
			correction_timestampAdapter = instantAdapter
		),
		OpenChatsAdapter = OpenChats.Adapter(
			accountAdapter = bareJidAdapter, jidAdapter = bareJidAdapter, typeAdapter = EnumColumnAdapter()
		),
		RosterAdapter = Roster.Adapter(
			accountAdapter = bareJidAdapter, jidAdapter = bareJidAdapter, subscriptionAdapter = EnumColumnAdapter()
		),
		AvatarsCacheAdapter = AvatarsCache.Adapter(jidAdapter = bareJidAdapter),
		ChatMarkersAdapter = ChatMarkers.Adapter(
			sender_jidAdapter = bareJidAdapter, timestampAdapter = instantAdapter, typeAdapter = EnumColumnAdapter()
		),
		AttachmentsAdapter = Attachments.Adapter(accountAdapter = bareJidAdapter)

	)
}