package org.tigase.officialtea.common.services

import org.tigase.officialtea.common.database.Accounts
import tigase.halcyon.core.builder.ConfigurationBuilder
import tigase.halcyon.core.builder.webSocketConnector
import tigase.halcyon.core.xmpp.modules.discoaltconn.AlternativeConnectionMethodModule

actual fun ConfigurationBuilder.configureConnector(account: Accounts) {
    install(AlternativeConnectionMethodModule)
    webSocketConnector {}
}