@file:OptIn(ExperimentalComposeWebSvgApi::class)

import androidx.compose.runtime.CompositionLocalProvider
import co.touchlab.kermit.ConsoleWriter
import co.touchlab.kermit.Logger
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import com.arkivanov.essenty.lifecycle.resume
import com.arkivanov.essenty.lifecycle.stop
import com.arkivanov.mvikotlin.logging.store.LoggingStoreFactory
import com.arkivanov.mvikotlin.main.store.DefaultStoreFactory
import com.badoo.reaktive.completable.subscribe
import com.badoo.reaktive.observable.observeOn
import com.badoo.reaktive.observable.subscribe
import com.badoo.reaktive.promise.asSingle
import com.badoo.reaktive.scheduler.ioScheduler
import com.russhwolf.settings.ExperimentalSettingsApi
import com.russhwolf.settings.StorageSettings
import com.squareup.sqldelight.drivers.sqljs.initSqlDriver
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.renderComposable
import org.tigase.officialtea.common.root.AppRootComponent
import org.tigase.officialtea.common.services.FocusService
import org.tigase.officialtea.common.services.ServiceKeeperFactory
import org.tigase.officialtea.common.services.settings.ApplicationSettings
import org.tigase.officialtea.common.utils.Consumer
import org.tigase.officialtea.database.OfficialTeaDatabase
import org.w3c.dom.Document

external fun require(module: String): dynamic

@JsModule("./styles/theme.css")
@JsNonModule
private external val OfficialTeaStyles: dynamic

@ExperimentalSettingsApi
suspend fun main() {
	Logger.addLogWriter(ConsoleWriter())

	val driver = initSqlDriver(OfficialTeaDatabase.Schema).asSingle()

	val lifecycle = LifecycleRegistry()
	val storeFactory = LoggingStoreFactory(DefaultStoreFactory())

	val settings = StorageSettings(window.localStorage)
	val serviceKeeper = ServiceKeeperFactory(driver, ApplicationSettings(settings))

	AccountStoreHelper.restore(serviceKeeper.accountsService).subscribe {
		serviceKeeper.connectionService.connectAll()
	}


	serviceKeeper.accountsService.observeAll().observeOn(ioScheduler).subscribe {
		if (it.isNotEmpty()) {
			AccountStoreHelper.store(it)
		}
	}

	val root = AppRootComponent(
		DefaultComponentContext(lifecycle = lifecycle),
		storeFactory = storeFactory,
		switchDarkMode = Consumer { },
		serviceKeeper = serviceKeeper,
		preferences = serviceKeeper.applicationPreferences,
		chatsService = serviceKeeper.chatsService,
		accountsService = serviceKeeper.accountsService,
	)
	lifecycle.attachToDocument()
	renderComposable(rootElementId = "main") {
		CompositionLocalProvider {
			AppRootContent(root)
		}
	}
}

private val Document.visibilityState: String get() = asDynamic().visibilityState.unsafeCast<String>()
private fun LifecycleRegistry.attachToDocument() {
	fun onVisibilityChanged() {
		if (document.visibilityState == "visible") {
			resume()
		} else {
			stop()
		}
	}

	onVisibilityChanged()
	FocusService.setActive(true)

	document.addEventListener(type = "visibilitychange", callback = { onVisibilityChanged() })
	window.addEventListener(type = "focus", callback = {
		FocusService.setActive(true)
	})
	window.addEventListener(type = "blur", callback = {
		FocusService.setActive(false)
	})
}

