package org.tigase.officialtea.common.main.settings.themes

import com.arkivanov.decompose.value.Value

interface ThemeUpdater {

	fun updateTheme(
		primary: Int,
		primaryVariant: Int,
		secondary: Int,
		secondaryVariant: Int,
		background: Int,
		surface: Int,
		error: Int,
		onPrimary: Int,
		onSecondary: Int,
		onBackground: Int,
		onSurface: Int,
		onError: Int,
		isLight: Boolean,
	)

}

interface Themes {

	val models: Value<State>

	var themeUpdater: ThemeUpdater?

	fun setField(field: Field, value: String)

	fun setIsLight(value: Boolean)

	fun setExportable(value: String)

	data class State(
		val primary: String = "ff1d8df6",
		val primaryVariant: String = "ff174ab1",
		val secondary: String = "fff6861d",
		val secondaryVariant: String = "fffede3f",
		val background: String = "ffffffff",
		val surface: String = "ffffffff",
		val error: String = "ffa8201a",
		val onPrimary: String = "ff000000",
		val onSecondary: String = "ffffffff",
		val onBackground: String = "ff000000",
		val onSurface: String = "ff000000",
		val onError: String = "ffffffff",
		val isLight: Boolean = true,
		val exportable: String = "",
	)

	enum class Field {

		Primary,
		PrimaryVariant,
		Secondary,
		SecondaryVariant,
		Background,
		Surface,
		Error,
		OnPrimary,
		OnSecondary,
		OnBackground,
		OnSurface,
		OnError
	}

}