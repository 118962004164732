package org.tigase.officialtea.common.main.components.roster

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.observable.map
import org.tigase.officialtea.common.main.components.roster.RosterItemEditor.State
import org.tigase.officialtea.common.main.components.roster.RosterItemEditorStore.Intent
import org.tigase.officialtea.common.main.components.roster.RosterItemEditorStore.Label
import org.tigase.officialtea.common.services.ServiceKeeper
import tigase.halcyon.core.AbstractHalcyon
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.toBareJID

internal interface RosterItemEditorStore : Store<Intent, State, Label> {

    sealed interface Intent {

        data class UpdateJID(val jid: String) : Intent
        data class UpdateName(val name: String) : Intent
        data class UpdateAccount(val account: String) : Intent
        data class UpdateAskForSubscription(val value: Boolean) : Intent
        data class UpdateAllowForSubscription(val value: Boolean) : Intent
    }

    sealed interface Label
}

internal class RosterItemEditorStoreFactory(
    val storeFactory: StoreFactory,
    val serviceKeeper: ServiceKeeper
) {

    fun create(): RosterItemEditorStore =
        object : RosterItemEditorStore, Store<Intent, State, Label> by storeFactory.create(
            name = "RosterItemEditorStore",
            initialState = State(),
            bootstrapper = SimpleBootstrapper(Action.LoadAccounts),
            executorFactory = ::ExecutorImpl,
            reducer = ReducerImpl
        ) {}

    private sealed interface Action {
        object LoadAccounts : Action
    }

    private sealed interface Msg {

        data class UpdateJID(val jid: String) : Msg
        data class UpdateJIDError(val value: Boolean) : Msg
        data class UpdateName(val name: String) : Msg
        data class UpdateAccount(val account: BareJID) : Msg
        data class UpdateAskForSubscription(val value: Boolean) : Msg
        data class UpdateAllowForSubscription(val value: Boolean) : Msg
        data class AccountListLoaded(val list: List<BareJID>) : Msg

    }

    private inner class ExecutorImpl : ReaktiveExecutor<Intent, Action, State, Msg, Label>() {
        override fun executeIntent(intent: Intent, getState: () -> State) = when (intent) {
            is Intent.UpdateAccount -> dispatch(Msg.UpdateAccount(intent.account.toBareJID()))
            is Intent.UpdateAllowForSubscription -> dispatch(Msg.UpdateAllowForSubscription(intent.value))
            is Intent.UpdateAskForSubscription -> dispatch(Msg.UpdateAskForSubscription(intent.value))
            is Intent.UpdateJID -> onUpdateJID(intent)
            is Intent.UpdateName -> dispatch(Msg.UpdateName(intent.name))
        }

        private fun onUpdateJID(intent: Intent.UpdateJID) {
            dispatch(Msg.UpdateJID(intent.jid))
            try {
                val x = intent.jid.toBareJID()
                val v = !x.localpart.isNullOrBlank() && x.toString() == x.toString().trim() && x.domain.isNotBlank()
                dispatch(Msg.UpdateJIDError(!v))
            } catch (_: Exception) {
                dispatch(Msg.UpdateJIDError(true))
            }
        }

        override fun executeAction(action: Action, getState: () -> State) {
            serviceKeeper.connectionService.clients.map {
                it.filter { it.state == AbstractHalcyon.State.Connected }.map { it.boundJID!!.bareJID }
            }.subscribeScoped {
                dispatch(Msg.AccountListLoaded(it))
                if (it.isNotEmpty() && getState().account == null) {
                    dispatch(Msg.UpdateAccount(it.first()))
                }
            }
        }
    }

    private object ReducerImpl : Reducer<State, Msg> {
        override fun State.reduce(message: Msg): State =
            when (message) {
                is Msg.UpdateAccount -> copy(account = message.account)
                is Msg.UpdateAllowForSubscription -> copy(allowForSubscription = message.value)
                is Msg.UpdateAskForSubscription -> copy(askForSubscription = message.value)
                is Msg.UpdateJID -> copy(jid = message.jid)
                is Msg.UpdateName -> copy(name = message.name)
                is Msg.UpdateJIDError -> copy(jidError = message.value)
                is Msg.AccountListLoaded -> copy(accountsList = message.list)
            }
    }
}
