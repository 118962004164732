package org.tigase.officialtea.common.main.components.mixbrowser

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.labels
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.asValue

class AddressInputComponent(
	componentContext: ComponentContext,
	storeFactory: StoreFactory,
	serviceKeeper: ServiceKeeper,
) : AddressInput, ComponentContext by componentContext {

	private val store = instanceKeeper.getStore {
		AddressInputStoreProvider(
			storeFactory = storeFactory, serviceKeeper = serviceKeeper
		).provide()
	}

	override fun labels() = store.labels

	override val models: Value<AddressInput.Model> = store.asValue()

	override fun onTextChange(value: String) = store.accept(AddressInputStore.Intent.SetAddress(value))
	override fun onAccountChange(account: String) = store.accept(AddressInputStore.Intent.SetAccount(account))

	override fun tryToApplyData() = store.accept(AddressInputStore.Intent.TryToApply)

}