package org.tigase.officialtea.common.main.components.sidemenu

import com.arkivanov.decompose.value.Value
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.database.OpenChats
import org.tigase.officialtea.common.services.OpenChatUnreadsItem
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.roster.RosterItem
import tigase.halcyon.core.xmpp.toBareJID

interface SideMenu {

	val models: Value<Model>

	fun onMenuItemClicked(item: MenuItem)
	fun onToggleSelection(vararg items: MenuItem, forceSelect: Boolean = false)
	fun onClearSelection()
	fun onDirectChatClose(vararg items: MenuItem.ChatMenuItem)
	fun onOpenSelfProfile(open: Boolean)
	fun onCloseSelected()
	fun onAddUser(account: BareJID, victim: RosterItem)
	fun onChangeSearchText(searchQuery: String)
	fun open(chat: OpenChats)
	fun open(account: BareJID, jid: BareJID)
	fun toggleTheme()

	data class Model(
        val opened: MenuItem,
        val generalMenuItems: List<MenuItem.GeneralMenuItem> = emptyList(),
        val directChatList: List<MenuItem.DirectChatMenuItem> = emptyList(),
        val mix: List<MenuItem.MixChatMenuItem> = emptyList(),
        val fromUnknownChatList: List<MenuItem.DirectChatMenuItem> = emptyList(),
        val selectedItems: Set<MenuItem> = emptySet(),
        val isInSelectionMode: Boolean = false,
		val searchQuery: String = "",
        var actionModal: Boolean = false,
        val unreadChats: List<MenuItem.ChatMenuItem> = listOf(),
        var activeAccount: Accounts = Accounts(
			id = 0,
			enabled = false,
			userjid = "".toBareJID(),
			password = "",
			nickname = "",
			resource = "",
			hostname = "",
			port = 0,
			roster_version = "",
			push_node = "",
			push_enabled = false
		)
	)

	enum class MenuAction {

		Roster,
		ChannelBrowser,
		Settings,
		Support,
	}

	sealed interface MenuItem {

		interface ChatMenuItem : MenuItem {

			val chat: OpenChatUnreadsItem
		}

		val icon: String
		val name: String

		data class GeneralMenuItem(val action: MenuAction, override val icon: String, override val name: String) :
			MenuItem

		data class DirectChatMenuItem(
			override val chat: OpenChatUnreadsItem, override val icon: String, override val name: String,
		) : ChatMenuItem

		data class MixChatMenuItem(
			override val chat: OpenChatUnreadsItem, override val icon: String, override val name: String,
		) : ChatMenuItem

	}

}