import androidx.compose.runtime.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun WIP() {
    Div(
        attrs = {
            style {
                fontSize(24.px)
                fontStyle("italic")
            }
        }
    ) {
        Text("Work In Progress!")

    }
}

@Composable
fun WorkInProgressPanel() {
    var visible by remember { mutableStateOf(true) }
    val primaryColor = Color("#e59f39")
    val secondaryColor = Color("#ab782d")
    val textColor = Color("#ffffff")

    if (visible) {
        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Row)
                alignContent(AlignContent.Center)
                alignItems(AlignItems.Stretch)
                justifyContent(JustifyContent.SpaceBetween)
                property("border-bottom", "1px solid $secondaryColor")

                backgroundColor(primaryColor)
                color(Color.white)
                fontSize(15.px)
                fontWeight(400)
            }
        }) {
            Div({
                style {
                    display(DisplayStyle.Flex)
                    flexDirection(FlexDirection.Row)
//                    alignContent(AlignContent.Center)
                    alignItems(AlignItems.Center)
                    padding(8.px)
                    color(textColor)
                    property("border-left", "10px solid $secondaryColor")
                }
            }) {
                Span({
                    classes("material-icons-outlined")
                    style {
                        paddingLeft(8.px)
                        paddingRight(8.px)
                    }
                }) { Text("warning") }
                Span { Text("This app is experimental and under development. It may not be stable or reliable, and may contain errors or bugs.") }
            }
            Button({
                onClick {
                    visible = false
                }
                style {
                    color(textColor)
                    backgroundColor(secondaryColor)
                    property("border", "none")
                }
            }) {
                Span({ classes("material-icons-outlined") }) { Text("close") }
            }
        }
    }
}