package org.tigase.officialtea.common.main.settings.accountslist

import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value

interface AccountsRoot {

	val routerState: Value<ChildStack<AccountsRootComponent.Configuration, Child>>

	sealed class Child {

		data class List(val component: AccountsList) : Child()
		data class Edit(val component: AccountEdit) : Child()
	}

}