package org.tigase.officialtea.common.services

import com.badoo.reaktive.completable.Completable
import com.badoo.reaktive.maybe.Maybe
import com.badoo.reaktive.observable.Observable
import com.badoo.reaktive.single.Single
import com.badoo.reaktive.subject.Subject
import org.tigase.officialtea.common.database.Accounts
import tigase.halcyon.core.xmpp.BareJID

interface AccountsService{

	val updates: Subject<ChangeEvent>

	var pushDeviceToken: String?

	fun addOrUpdate(
		id: Long? = null,
		userjid: BareJID,
		password: String,
		nickname: String? = null,
		resource: String? = null,
		hostname: String? = null,
		port: Long? = null,
	): Completable

	fun observeAll(): Observable<List<Accounts>>
	fun loadAll(): Single<List<Accounts>>
	fun load(name: BareJID): Maybe<Accounts>
	fun load(id: Long): Maybe<Accounts>
	fun delete(name: BareJID): Completable
	fun updatePushNode(id: Long, pushNode: String?, pushEnabled: Boolean): Completable

	sealed class ChangeEvent(val jid: BareJID) { class Added(jid: BareJID) : ChangeEvent(jid)
		class Updated(jid: BareJID) : ChangeEvent(jid)
		class Removed(jid: BareJID) : ChangeEvent(jid)
	}

	fun add(
		id: Long?,
		enabled: Boolean,
		userjid: BareJID,
		password: String,
		nickname: String?,
		resource: String?,
		hostname: String?,
		port: Long?,
		roster_version: String? = null,
		push_node: String?,
		push_enabled: Boolean
	): Completable
}