package components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun FloatingButton(
    onClick: () -> Unit,
    iconName: String
) {
    Div({
        style {
            position(Position.Fixed)
            right(0.px)
            bottom(0.px)
        }
    }) {
        Button({
            onClick { onClick() }
            classes("btn", "btn-primary", "rounded-circle")
            style {
                marginBottom(52.px)
                marginRight(52.px)
                height(65.px)
                width(65.px)
                backgroundColor(Color("#2cb3f0"))
                color(Color.white)
                borderRadius(50.percent)
                lineHeight(65.px)
                textAlign("center")
                fontSize(23.px)
                cursor("pointer")
                border(0.px)
                paddingTop(4.px)
                property("box-shadow", "rgba(44, 179, 240, 0.6) 0px 10px 25px -5px")
            }
            onClick { onClick() }
        }) {
            Span({ classes("material-icons-outlined") }) { Text(iconName) }
        }
    }

}