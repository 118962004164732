package org.tigase.officialtea.common.main.settings.accountslist

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.arkivanov.mvikotlin.core.store.StoreFactory
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.Consumer

class AccountsRootComponent(
	componentContext: ComponentContext,
	private val storeFactory: StoreFactory,
	private val serviceKeeper: ServiceKeeper,
) : AccountsRoot, ComponentContext by componentContext {

	private val router = StackNavigation<Configuration>()
	private val stack = childStack(
		key = "AccountsSettings",
		source = router,
		initialConfiguration = Configuration.List,
		handleBackButton = true,
		childFactory = ::createChild
	)
	override val routerState = stack

	private fun createChild(configuration: Configuration, componentContext: ComponentContext): AccountsRoot.Child =
		when (configuration) {
			Configuration.List -> AccountsRoot.Child.List(
				AccountsListComponent(
					componentContext,
					storeFactory,
					serviceKeeper.accountsService,
					serviceKeeper.connectionService,
					accountSelectedOutput = Consumer(::onAccountClick),
					addClickOutput = Consumer(::onAddAccountClick)
				)
			)

			is Configuration.Edit -> AccountsRoot.Child.Edit(
				AccountEditComponent(
					componentContext,
					storeFactory,
					serviceKeeper,
					configuration.accountId,
					Consumer { router.pop() })
			)
		}

	private fun onAddAccountClick(@Suppress("UNUSED_PARAMETER") unit: Unit) {
		router.push(Configuration.Edit(null, true))
	}

	private fun onAccountClick(account: Accounts) {
		router.push(Configuration.Edit(account.id))
	}

	sealed class Configuration : Parcelable {

		@Parcelize
		object List : Configuration()

		@Parcelize
		data class Edit(val accountId: Long?, val create: Boolean = false) : Configuration()
	}
}