package org.tigase.officialtea.common.services

import co.touchlab.kermit.Logger
import com.badoo.reaktive.completable.Completable
import com.badoo.reaktive.completable.asSingle
import com.badoo.reaktive.single.Single
import com.badoo.reaktive.single.flatMap
import com.badoo.reaktive.single.map
import org.tigase.officialtea.common.database.Attachments
import org.tigase.officialtea.common.database.AttachmentsQueries
import tigase.halcyon.core.Halcyon
import tigase.halcyon.core.exceptions.HalcyonException
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.fileupload.FileUploadModule
import tigase.halcyon.core.xmpp.modules.fileupload.Slot
import tigase.halcyon.core.xmpp.modules.serviceFinder.ServiceFinderModule
import tigase.halcyon.rx.asSingle
import tigase.halcyon.rx.findAll

expect class FileUploader(
    attachmentProvider: AttachmentProvider,
    attachmentQueries: Single<AttachmentsQueries>,
    connectionService: ConnectionService
) {

    fun upload(account: BareJID, originStanzaId: String): Single<Slot?>

    fun sendDataToSlot(slot: Slot, attachment: Attachments): Completable

}

fun Halcyon.findUploadService() =
    this.getModule(ServiceFinderModule).findAll { it.features.contains("urn:xmpp:http:upload:0") }.map {
        it.firstOrNull() ?: throw HalcyonException("Cannot find File Upload Service.")
    }


fun FileUploader.upload(connectionService: ConnectionService, attachment: Attachments) =
    connectionService.halcyon(attachment.account).flatMap { halcyon ->
        halcyon.findUploadService().flatMap { serviceInfo ->
            Logger.i("FileUploader") { "Preparing upload slot request" }
            halcyon.getModule(FileUploadModule)
                .requestSlot(serviceInfo.jid, attachment.fileName!!, attachment.size!!, attachment.type!!)
                .asSingle()
        }
    }.flatMap { slot ->
        Logger.i("FileUploader") { "Preparing sending file to slot ${slot.putUrl}" }
        sendDataToSlot(slot, attachment).asSingle { slot }
    }
