import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.tigase.officialtea.common.database.MessageWithAttachment

@Composable
fun Attachment(message: MessageWithAttachment) {
    Div(attrs = {
        classes("attachment")
    }) {
        Img(src = message.attachment_url!!, alt = "")
        Div(attrs = {
            classes("footer")
        }) {
            A(href = message.attachment_url!!) {
                Text(message.attachment_name ?: "-")
            }
        }
    }
}

