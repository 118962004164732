package org.tigase.officialtea.common.database

import kotlin.String

public data class CapsFeatures(
  public val node: String,
  public val feature: String
) {
  public override fun toString(): String = """
  |CapsFeatures [
  |  node: $node
  |  feature: $feature
  |]
  """.trimMargin()
}
