import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.jetbrains.subscribeAsState
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.tigase.officialtea.common.main.components.vcard.VCardPanel

@Composable
fun VCardContent(component: VCardPanel) {
    val model by component.models.subscribeAsState()

    Div({ classes("vcard-header") }) {
        Div({ classes("vcard-avatar", "w-60", "h-60", "rounded-full", "mt-1", "mr-3", "border-[6px]", "border-[#324167]", "bg-white", "shadow-xl") }) {
            Avatar(model.jid, model.name,"large")
        }
        Div({ classes("vcard-header-fields") }) {
            Div({ classes("vcard-name") }) { Text(model.name) }
            Div({ classes("vcard-jid") }) { Text(model.jid.toString()) }
            Div({ classes("vcard-role") }) { Text(model.vCard?.role ?: "") }
        }
    }

    model.vCard?.emails?.forEach {
        Property("mail", it.parameters.types.joinToString { it }, it.text ?: "")
    }
    model.vCard?.telephones?.forEach {
        Property("phone", it.parameters.types.joinToString { it }, it.uri ?: "")
    }
    model.vCard?.organizations?.forEach {
        Property("work", it.parameters.types.joinToString { it }, it.name ?: "")
    }
    model.vCard?.addresses?.forEach {
        Property(
            "apartment",
            it.parameters.types.joinToString { it },
            it.street,
            it.locality,
            it.region,
            it.country
        )
    }
}

@Composable
fun Property(iconName: String, label: String?, vararg values: String?) {
    Div({ classes("vcard-property") }) {
        Div({ classes("vcard-property-icon") }) {
            Span({ classes("material-icons-outlined", "text-[#324167]") }) { Text(iconName) }
        }
        Div({ classes("vcard-property-content") }) {
            values.filterNotNull()
                .forEach {
                    Div({ classes("vcard-property-value") }) { Text(it) }
                }
            if (!label.isNullOrBlank())
                Div({ classes("vcard-property-label") }) { Text(label) }
        }
    }
}