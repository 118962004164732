package org.tigase.officialtea.common.main.components.support

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.maybe.flatMapSingle
import com.badoo.reaktive.maybe.observeOn
import com.badoo.reaktive.maybe.subscribeOn
import com.badoo.reaktive.observable.filter
import com.badoo.reaktive.observable.firstOrComplete
import com.badoo.reaktive.scheduler.ioScheduler
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.subscribeOn
import org.tigase.officialtea.common.services.ServiceKeeper
import tigase.halcyon.core.AbstractHalcyon
import tigase.halcyon.core.configuration.declaredUserJID
import tigase.halcyon.core.requests.XMPPError
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.JID
import tigase.halcyon.core.xmpp.modules.mix.MIXModule
import tigase.halcyon.core.xmpp.toBareJID
import tigase.halcyon.rx.asSingle


internal interface SupportStore : Store<SupportStore.Intent, Support.Model, SupportStore.Label> {
    sealed interface Intent {
        data class Submit(val supportTicket: SupportTicketInfo) : Intent
        object Redirect : Intent
        data class connectSupportChannel(val account: BareJID) : Intent
        object AutoConnectSupportChannel : Intent
    }

    sealed interface Label {
        data class JoinChannel(val account: BareJID, val channel: JID) : Label
    }
}


internal class SupportStoreProvider(
    private val storeFactory: StoreFactory,
    private val serviceKeeper: ServiceKeeper
) {

    fun provide(): SupportStore =
        object : SupportStore, Store<SupportStore.Intent, Support.Model, SupportStore.Label> by storeFactory.create(
            name = "SupportStore", initialState = Support.Model(), bootstrapper = SimpleBootstrapper(
                Unit
            ), executorFactory = ::ExecutorImpl, reducer = ReducerImpl
        ) {}

    private sealed interface Result {
        data class Submitted(val supportTicket: SupportTicketInfo)
        object Redirected
        object connectedSupportChannel
        data class OnError(val message: String) : Result
    }

    private inner class ExecutorImpl :
        ReaktiveExecutor<SupportStore.Intent, Unit, Support.Model, Result, SupportStore.Label>() {

        override fun executeIntent(intent: SupportStore.Intent, getState: () -> Support.Model) = when (intent) {
            is SupportStore.Intent.connectSupportChannel -> connectToSupportChannel(intent.account)
            SupportStore.Intent.AutoConnectSupportChannel -> connectToSupportChannel()
            SupportStore.Intent.Redirect,
            is SupportStore.Intent.Submit -> {
            }
        }

        private fun allowRedirect() {
            TODO("Check admin privledges to see if redirect is available")
        }

        private fun submitTicket(ticket: SupportTicketInfo) {
            TODO("Fully implement if we decide to do in-app ticket hosting / pushing")
        }

        private fun connectToSupportChannel() {
            serviceKeeper.connectionService.allClients().filter { it.state == AbstractHalcyon.State.Connected }
                .firstOrComplete().subscribeScoped {
                connectToSupportChannel(it.config.declaredUserJID!!)
            }
        }

        private fun connectToSupportChannel(accountJID: BareJID) {
            serviceKeeper.accountsService.load(accountJID)
                .subscribeOn(ioScheduler)
                .flatMapSingle { account ->
                    serviceKeeper.connectionService[account.userjid]!!.getModule<MIXModule>(MIXModule.TYPE)
                        .join(
                            channel = "support@mix.tygrys.io".toBareJID(),
                            nick = account.nickname ?: account.userjid.localpart!!
                        )
                        .asSingle()
                        .subscribeOn(ioScheduler)
                }
                .observeOn(mainScheduler)
                .subscribeScoped(onError = {
                    dispatch(
                        Result.OnError(
                            when (it) {
                                is XMPPError -> "${it.error}"
                                else -> "Cannot join"
                            }
                        )
                    )
                }, onSuccess = {
                    publish(SupportStore.Label.JoinChannel(accountJID, it.jid))
                })
        }
    }

    private object ReducerImpl : Reducer<Support.Model, Result> {
        override fun Support.Model.reduce(result: Result): Support.Model = when (result) {
            is Result.OnError -> copy(errorMessage = result.message)
            else -> {
                TODO()
            }
        }
    }
}