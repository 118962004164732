package org.tigase.officialtea.common.main.settings.themes

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.labels
import com.badoo.reaktive.observable.filter
import com.badoo.reaktive.observable.map
import com.badoo.reaktive.observable.subscribe
import org.tigase.officialtea.common.utils.asValue
import org.tigase.officialtea.common.utils.getStore

class ThemesComponent(
	private val componentContext: ComponentContext, storeFactory: StoreFactory,
) : Themes, ComponentContext by componentContext {

	private val store = instanceKeeper.getStore {
		ThemeEditorStoreProvider(
			storeFactory = storeFactory,
		).provide()
	}

	override var themeUpdater: ThemeUpdater? = null

	override val models: Value<Themes.State> = store.asValue()

	init {
		store.labels.filter { it is ThemeEditorStore.Label.UpdateTheme }
			.map { it as ThemeEditorStore.Label.UpdateTheme }
			.subscribe {
				themeUpdater?.updateTheme(
					it.primary,
					it.primaryVariant,
					it.secondary,
					it.secondaryVariant,
					it.background,
					it.surface,
					it.error,
					it.onPrimary,
					it.onSecondary,
					it.onBackground,
					it.onSurface,
					it.onError,
					it.isLight
				)
			}
	}

	override fun setField(field: Themes.Field, value: String) =
		store.accept(ThemeEditorStore.Intent.SetField(field, value))

	override fun setIsLight(value: Boolean) = store.accept(ThemeEditorStore.Intent.SetIsLight(value))

	override fun setExportable(value: String) = store.accept(ThemeEditorStore.Intent.SetExportable(value))
}