package org.tigase.officialtea.common.database

import com.badoo.reaktive.base.setCancellable
import com.badoo.reaktive.completable.Completable
import com.badoo.reaktive.disposable.Disposable
import com.badoo.reaktive.maybe.Maybe
import com.badoo.reaktive.observable.*
import com.badoo.reaktive.scheduler.ioScheduler
import com.badoo.reaktive.single.*
import com.squareup.sqldelight.Query
import com.squareup.sqldelight.Transacter

fun <T : Any, R> Single<Query<T>>.observe(get: (Query<T>) -> R): Observable<R> =
	flatMapObservable { it.observed() }.observeOn(ioScheduler)
		.map(get)

fun <T> Observable<T>.powtorzGdy(observable: Observable<*>): Observable<T> = observable { emitter ->
	var listener: Disposable? = this@powtorzGdy.subscribe(
		onNext = emitter::onNext, onError = emitter::onError, onComplete = emitter::onComplete
	)

	observable.subscribe {
		listener?.dispose()
		listener = this@powtorzGdy.subscribe(
			onNext = emitter::onNext, onError = emitter::onError, onComplete = emitter::onComplete
		)
	}
}

fun <T : Any> Query<T>.observed(): Observable<Query<T>> = observable { emitter ->
	val listener = object : Query.Listener {
		override fun queryResultsChanged() {
			emitter.onNext(this@observed)
		}
	}

	emitter.onNext(this@observed)
	addListener(listener)
	emitter.setCancellable { removeListener(listener) }
}

fun <DB : Transacter, T : Any> Single<DB>.query(query: (DB) -> Query<T>): Single<Query<T>> = this.observeOn(ioScheduler)
	.map(query)

fun <DB : Transacter> Single<DB>.execute(query: (DB) -> Unit): Completable = this.observeOn(ioScheduler)
	.doOnBeforeSuccess(query)
	.retry(3)
	.asCompletable()

fun <DB : Transacter, R> Single<DB>.executeWithResult(query: (DB) -> R): Maybe<R> = this.observeOn(ioScheduler)
	.map { query.invoke(it) }
	.asMaybe()