import co.touchlab.kermit.Logger
import com.badoo.reaktive.observable.flatMapCompletable
import com.badoo.reaktive.single.flatten
import com.badoo.reaktive.single.singleOf
import kotlinx.browser.window
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.services.AccountsService
import org.w3c.dom.get
import tigase.halcyon.core.xmpp.BareJID

@Serializable
data class Account(
    val id: Long,
    val enabled: Boolean,
    val userjid: BareJID,
    val password: String,
    val nickname: String?,
    val resource: String?,
    val hostname: String?,
    val port: Long?,
    val roster_version: String?,
    val push_node: String?,
    val push_enabled: Boolean,
)

object AccountStoreHelper {

    fun load(): List<Account> {
        val data = window.localStorage.get("accounts") ?: return emptyList()
        return Json.decodeFromString<List<Account>>(data)
    }

    fun store(list: List<Accounts>) {
        window.localStorage.setItem("accounts",
            Json.encodeToString(list.map { it.toAccount() }
                .toList()))
    }

    fun restore(driver: AccountsService) = singleOf(load()).flatten().flatMapCompletable {
        Logger.i { "Restoring account ${it.userjid}" }
        driver.add(
            id = it.id,
            enabled = it.enabled,
            password = it.password,
            userjid = it.userjid,
            nickname = it.nickname,
            resource = it.resource,
            hostname = it.hostname,
            port = it.port,
            roster_version = null,
            push_node = it.push_node,
            push_enabled = it.push_enabled
        )
    }
}

private fun Accounts.toAccount() = Account(
    id = this.id,
    enabled = this.enabled,
    userjid = this.userjid,
    password = this.password,
    nickname = this.nickname,
    resource = this.resource,
    hostname = this.hostname,
    port = this.port,
    roster_version = this.roster_version,
    push_node = this.push_node,
    push_enabled = this.push_enabled,
)