package components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.internal.runtime.ComposeWebInternalApi
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement


@Composable
fun ChatListSection(title: String, content:  ContentBuilder<HTMLElement>? = null){

	Div ({classes("chat-section")}){
		H1 (attrs = { classes("text-[14px]", "mb-[8px]", "tracking-[0.4px]") }){ Text(title) }
		content?.invoke(this)
	}

}