package org.tigase.officialtea.database.database

import com.squareup.sqldelight.Query
import com.squareup.sqldelight.TransacterImpl
import com.squareup.sqldelight.`internal`.copyOnWriteList
import com.squareup.sqldelight.db.SqlCursor
import com.squareup.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.ByteArray
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.Collection
import kotlin.collections.MutableList
import kotlin.reflect.KClass
import kotlinx.datetime.Instant
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.database.AccountsDatabaseQueries
import org.tigase.officialtea.common.database.Attachments
import org.tigase.officialtea.common.database.AttachmentsQueries
import org.tigase.officialtea.common.database.AvatarsCache
import org.tigase.officialtea.common.database.AvatarsCacheDatabaseQueries
import org.tigase.officialtea.common.database.CapabilitiesCacheQueries
import org.tigase.officialtea.common.database.CapsFeatures
import org.tigase.officialtea.common.database.CapsIdentities
import org.tigase.officialtea.common.database.ChatMarkers
import org.tigase.officialtea.common.database.ChatMarkersQueries
import org.tigase.officialtea.common.database.FindMarker
import org.tigase.officialtea.common.database.GetLatestMamStanzaId
import org.tigase.officialtea.common.database.GetVersion
import org.tigase.officialtea.common.database.MessageState
import org.tigase.officialtea.common.database.MessageWithAttachment
import org.tigase.officialtea.common.database.Messages
import org.tigase.officialtea.common.database.MessagesDatabaseQueries
import org.tigase.officialtea.common.database.OpenChatType
import org.tigase.officialtea.common.database.OpenChats
import org.tigase.officialtea.common.database.OpenChatsDatabaseQueries
import org.tigase.officialtea.common.database.Roster
import org.tigase.officialtea.common.database.RosterDatabaseQueries
import org.tigase.officialtea.common.database.SelectUnsentMessages
import org.tigase.officialtea.common.database.SelectWithUnread
import org.tigase.officialtea.database.OfficialTeaDatabase
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.JID
import tigase.halcyon.core.xmpp.modules.chatmarkers.ChatMarkersModule
import tigase.halcyon.core.xmpp.modules.roster.Subscription
import tigase.halcyon.core.xmpp.stanzas.MessageType

internal val KClass<OfficialTeaDatabase>.schema: SqlDriver.Schema
  get() = OfficialTeaDatabaseImpl.Schema

internal fun KClass<OfficialTeaDatabase>.newInstance(
  driver: SqlDriver,
  AccountsAdapter: Accounts.Adapter,
  AttachmentsAdapter: Attachments.Adapter,
  AvatarsCacheAdapter: AvatarsCache.Adapter,
  ChatMarkersAdapter: ChatMarkers.Adapter,
  MessagesAdapter: Messages.Adapter,
  OpenChatsAdapter: OpenChats.Adapter,
  RosterAdapter: Roster.Adapter
): OfficialTeaDatabase = OfficialTeaDatabaseImpl(driver, AccountsAdapter, AttachmentsAdapter,
    AvatarsCacheAdapter, ChatMarkersAdapter, MessagesAdapter, OpenChatsAdapter, RosterAdapter)

private class OfficialTeaDatabaseImpl(
  driver: SqlDriver,
  internal val AccountsAdapter: Accounts.Adapter,
  internal val AttachmentsAdapter: Attachments.Adapter,
  internal val AvatarsCacheAdapter: AvatarsCache.Adapter,
  internal val ChatMarkersAdapter: ChatMarkers.Adapter,
  internal val MessagesAdapter: Messages.Adapter,
  internal val OpenChatsAdapter: OpenChats.Adapter,
  internal val RosterAdapter: Roster.Adapter
) : TransacterImpl(driver), OfficialTeaDatabase {
  public override val accountsDatabaseQueries: AccountsDatabaseQueriesImpl =
      AccountsDatabaseQueriesImpl(this, driver)

  public override val attachmentsQueries: AttachmentsQueriesImpl = AttachmentsQueriesImpl(this,
      driver)

  public override val avatarsCacheDatabaseQueries: AvatarsCacheDatabaseQueriesImpl =
      AvatarsCacheDatabaseQueriesImpl(this, driver)

  public override val capabilitiesCacheQueries: CapabilitiesCacheQueriesImpl =
      CapabilitiesCacheQueriesImpl(this, driver)

  public override val chatMarkersQueries: ChatMarkersQueriesImpl = ChatMarkersQueriesImpl(this,
      driver)

  public override val messagesDatabaseQueries: MessagesDatabaseQueriesImpl =
      MessagesDatabaseQueriesImpl(this, driver)

  public override val openChatsDatabaseQueries: OpenChatsDatabaseQueriesImpl =
      OpenChatsDatabaseQueriesImpl(this, driver)

  public override val rosterDatabaseQueries: RosterDatabaseQueriesImpl =
      RosterDatabaseQueriesImpl(this, driver)

  public object Schema : SqlDriver.Schema {
    public override val version: Int
      get() = 3

    public override fun create(driver: SqlDriver): Unit {
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS Accounts (
          |    id INTEGER PRIMARY KEY AUTOINCREMENT,
          |    enabled INTEGER NOT NULL DEFAULT 1,
          |    userjid TEXT  NOT NULL UNIQUE,
          |    password TEXT NOT NULL,
          |    nickname TEXT,
          |    resource TEXT,
          |    hostname TEXT,
          |    port INTEGER,
          |    roster_version TEXT,
          |    push_node TEXT,
          |    push_enabled INTEGER NOT NULL DEFAULT 0
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE Attachments (
          |    id INTEGER PRIMARY KEY AUTOINCREMENT,
          |    openchat_id INTEGER,
          |    account TEXT NOT NULL,
          |
          |    origin_stanza_id TEXT NOT NULL,
          |
          |    url TEXT,
          |    fileName TEXT,
          |    type TEXT,
          |    size INTEGER,
          |    downloaded INTEGER NOT NULL DEFAULT 0,
          |    localFile TEXT,
          |
          |    FOREIGN KEY (openchat_id) REFERENCES OpenChats(id) ON DELETE CASCADE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE AvatarsCache (
          |    id INTEGER PRIMARY KEY AUTOINCREMENT,
          |    jid TEXT NOT NULL UNIQUE,
          |    avatarId TEXT NOT NULL,
          |    bytes INTEGER,
          |    width INTEGER,
          |    height INTEGER,
          |    type TEXT,
          |    url TEXT,
          |    avatarData BLOB NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE CapsFeatures (
          |    node TEXT NOT NULL,
          |    feature TEXT NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE CapsIdentities(
          |    node TEXT NOT NULL,
          |    name TEXT,
          |    type TEXT,
          |    category TEXT
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE ChatMarkers (
          |    openchat_id INTEGER NOT NULL,
          |    sender_nick TEXT NOT NULL,
          |    sender_id TEXT NOT NULL,
          |    sender_jid TEXT NOT NULL,
          |    timestamp INTEGER NOT NULL,
          |    type TEXT NOT NULL,
          |
          |    FOREIGN KEY (openchat_id) REFERENCES OpenChats(id) ON DELETE CASCADE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE Messages (
          |    id INTEGER PRIMARY KEY AUTOINCREMENT,
          |    openchat_id INTEGER,
          |    account TEXT NOT NULL,
          |
          |    author_jid TEXT NOT NULL,
          |    author_nickname TEXT,
          |
          |    recipient_jid TEXT NOT NULL,
          |    /** nickname odbiorcy jak masz wiadomość MUC-PM */
          |    recipient_nickname TEXT,
          |
          |    stanza_type TEXT NOT NULL,
          |    origin_stanza_id TEXT NOT NULL,
          |    mam_stanza_id TEXT,
          |    /** stable_id zdalengo archiwum MAM:2 (np. pokoju MUC, kanału MIX) */
          |    remote_stanza_id TEXT,
          |    /** MIX only participant id */
          |    participant_id TEXT,
          |    body TEXT,
          |    timestamp INTEGER NOT NULL,
          |    state TEXT NOT NULL,
          |    markable INTEGER NOT NULL DEFAULT 0,
          |
          |    correction_stanza_id TEXT,
          |    correction_timestamp INTEGER,
          |
          |    FOREIGN KEY (openchat_id) REFERENCES OpenChats(id) ON DELETE CASCADE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE OpenChats (
          |    id INTEGER PRIMARY KEY AUTOINCREMENT,
          |    account TEXT NOT NULL,
          |    jid TEXT NOT NULL,
          |    name TEXT NOT NULL,
          |    type TEXT NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE Roster (
          |    id INTEGER PRIMARY KEY AUTOINCREMENT,
          |    account TEXT NOT NULL,
          |    jid TEXT NOT NULL,
          |    name TEXT,
          |    ask INTEGER NOT NULL DEFAULT 1,
          |    subscription TEXT,
          |    last_status INTEGER,
          |    mixParticipantId TEXT
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE INDEX IF NOT EXISTS caps_features_node_idx ON CapsFeatures (
          |    node
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE INDEX IF NOT EXISTS caps_features_feature_idx ON CapsFeatures (
          |    feature
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE INDEX IF NOT EXISTS caps_indentities_node_idx ON CapsIdentities (
          |    node
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE UNIQUE INDEX IF NOT EXISTS chat_markers_key ON ChatMarkers (
          |    openchat_id, sender_nick, sender_id, sender_jid
          |)
          """.trimMargin(), 0)
      driver.execute(null,
          "CREATE UNIQUE INDEX OpenChatsAccountJidIndex ON OpenChats(account, jid)", 0)
    }

    public override fun migrate(
      driver: SqlDriver,
      oldVersion: Int,
      newVersion: Int
    ): Unit {
      if (oldVersion <= 1 && newVersion > 1) {
        driver.execute(null, """
            |CREATE TABLE Attachments (
            |    id INTEGER PRIMARY KEY AUTOINCREMENT,
            |    openchat_id INTEGER,
            |    account TEXT NOT NULL,
            |
            |    origin_stanza_id TEXT NOT NULL,
            |
            |    url TEXT,
            |    fileName TEXT,
            |    type TEXT,
            |    size INTEGER,
            |    downloaded INTEGER NOT NULL DEFAULT 0,
            |
            |    FOREIGN KEY (openchat_id) REFERENCES OpenChats(id) ON DELETE CASCADE
            |)
            """.trimMargin(), 0)
      }
      if (oldVersion <= 2 && newVersion > 2) {
        driver.execute(null, "ALTER TABLE Attachments ADD localFile TEXT", 0)
      }
    }
  }
}

private class AccountsDatabaseQueriesImpl(
  private val database: OfficialTeaDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), AccountsDatabaseQueries {
  internal val selectAll: MutableList<Query<*>> = copyOnWriteList()

  internal val selectByName: MutableList<Query<*>> = copyOnWriteList()

  internal val selectById: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectAll(mapper: (
    id: Long,
    enabled: Boolean,
    userjid: BareJID,
    password: String,
    nickname: String?,
    resource: String?,
    hostname: String?,
    port: Long?,
    roster_version: String?,
    push_node: String?,
    push_enabled: Boolean
  ) -> T): Query<T> = Query(440344462, selectAll, driver, "AccountsDatabase.sq", "selectAll", """
  |SELECT *
  |FROM Accounts
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1)!! == 1L,
      database.AccountsAdapter.userjidAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getLong(10)!! == 1L
    )
  }

  public override fun selectAll(): Query<Accounts> = selectAll { id, enabled, userjid, password,
      nickname, resource, hostname, port, roster_version, push_node, push_enabled ->
    Accounts(
      id,
      enabled,
      userjid,
      password,
      nickname,
      resource,
      hostname,
      port,
      roster_version,
      push_node,
      push_enabled
    )
  }

  public override fun <T : Any> selectByName(userjid: BareJID, mapper: (
    id: Long,
    enabled: Boolean,
    userjid: BareJID,
    password: String,
    nickname: String?,
    resource: String?,
    hostname: String?,
    port: Long?,
    roster_version: String?,
    push_node: String?,
    push_enabled: Boolean
  ) -> T): Query<T> = SelectByNameQuery(userjid) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1)!! == 1L,
      database.AccountsAdapter.userjidAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getLong(10)!! == 1L
    )
  }

  public override fun selectByName(userjid: BareJID): Query<Accounts> = selectByName(userjid) { id,
      enabled, userjid_, password, nickname, resource, hostname, port, roster_version, push_node,
      push_enabled ->
    Accounts(
      id,
      enabled,
      userjid_,
      password,
      nickname,
      resource,
      hostname,
      port,
      roster_version,
      push_node,
      push_enabled
    )
  }

  public override fun <T : Any> selectById(id: Long, mapper: (
    id: Long,
    enabled: Boolean,
    userjid: BareJID,
    password: String,
    nickname: String?,
    resource: String?,
    hostname: String?,
    port: Long?,
    roster_version: String?,
    push_node: String?,
    push_enabled: Boolean
  ) -> T): Query<T> = SelectByIdQuery(id) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1)!! == 1L,
      database.AccountsAdapter.userjidAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getLong(10)!! == 1L
    )
  }

  public override fun selectById(id: Long): Query<Accounts> = selectById(id) { id_, enabled,
      userjid, password, nickname, resource, hostname, port, roster_version, push_node,
      push_enabled ->
    Accounts(
      id_,
      enabled,
      userjid,
      password,
      nickname,
      resource,
      hostname,
      port,
      roster_version,
      push_node,
      push_enabled
    )
  }

  public override fun add(
    userjid: BareJID,
    password: String,
    nickname: String?,
    resource: String?,
    hostname: String?,
    port: Long?
  ): Unit {
    driver.execute(-1235937302, """
    |INSERT INTO Accounts (userjid, password, nickname, resource, hostname, port)
    |VALUES (?,?,?,?,?,?)
    """.trimMargin(), 6) {
      bindString(1, database.AccountsAdapter.userjidAdapter.encode(userjid))
      bindString(2, password)
      bindString(3, nickname)
      bindString(4, resource)
      bindString(5, hostname)
      bindLong(6, port)
    }
    notifyQueries(-1235937302, {database.rosterDatabaseQueries.getVersion +
        database.accountsDatabaseQueries.selectAll + database.accountsDatabaseQueries.selectById +
        database.accountsDatabaseQueries.selectByName})
  }

  public override fun addFull(
    id: Long?,
    enabled: Boolean,
    userjid: BareJID,
    password: String,
    nickname: String?,
    resource: String?,
    hostname: String?,
    port: Long?,
    roster_version: String?,
    push_node: String?,
    push_enabled: Boolean
  ): Unit {
    driver.execute(-722163303, """
    |INSERT INTO Accounts (id, enabled, userjid,password,nickname,resource,hostname,port,roster_version,push_node,push_enabled)
    |VALUES (?,?,?,?,?,?,?,?,?,?,?)
    """.trimMargin(), 11) {
      bindLong(1, id)
      bindLong(2, if (enabled) 1L else 0L)
      bindString(3, database.AccountsAdapter.userjidAdapter.encode(userjid))
      bindString(4, password)
      bindString(5, nickname)
      bindString(6, resource)
      bindString(7, hostname)
      bindLong(8, port)
      bindString(9, roster_version)
      bindString(10, push_node)
      bindLong(11, if (push_enabled) 1L else 0L)
    }
    notifyQueries(-722163303, {database.rosterDatabaseQueries.getVersion +
        database.accountsDatabaseQueries.selectAll + database.accountsDatabaseQueries.selectById +
        database.accountsDatabaseQueries.selectByName})
  }

  public override fun update(
    password: String,
    nickname: String?,
    resource: String?,
    hostname: String?,
    port: Long?,
    id: Long
  ): Unit {
    driver.execute(1530226912,
        """UPDATE Accounts SET password=?, nickname=?, resource=?, hostname=?, port=? WHERE id=?""",
        6) {
      bindString(1, password)
      bindString(2, nickname)
      bindString(3, resource)
      bindString(4, hostname)
      bindLong(5, port)
      bindLong(6, id)
    }
    notifyQueries(1530226912, {database.rosterDatabaseQueries.getVersion +
        database.accountsDatabaseQueries.selectAll + database.accountsDatabaseQueries.selectById +
        database.accountsDatabaseQueries.selectByName})
  }

  public override fun updatePushInfo(
    push_node: String?,
    push_enabled: Boolean,
    id: Long
  ): Unit {
    driver.execute(1289705448, """UPDATE Accounts SET push_node=?, push_enabled=? WHERE id=?""", 3)
        {
      bindString(1, push_node)
      bindLong(2, if (push_enabled) 1L else 0L)
      bindLong(3, id)
    }
    notifyQueries(1289705448, {database.rosterDatabaseQueries.getVersion +
        database.accountsDatabaseQueries.selectAll + database.accountsDatabaseQueries.selectById +
        database.accountsDatabaseQueries.selectByName})
  }

  public override fun delete(userjid: BareJID): Unit {
    driver.execute(1033614786, """DELETE FROM Accounts WHERE userjid=?""", 1) {
      bindString(1, database.AccountsAdapter.userjidAdapter.encode(userjid))
    }
    notifyQueries(1033614786, {database.rosterDatabaseQueries.getVersion +
        database.accountsDatabaseQueries.selectAll + database.accountsDatabaseQueries.selectById +
        database.accountsDatabaseQueries.selectByName})
  }

  public override fun updateEnable(enabled: Boolean, id: Long): Unit {
    driver.execute(-1393208701, """UPDATE Accounts SET enabled=? WHERE id=?""", 2) {
      bindLong(1, if (enabled) 1L else 0L)
      bindLong(2, id)
    }
    notifyQueries(-1393208701, {database.rosterDatabaseQueries.getVersion +
        database.accountsDatabaseQueries.selectAll + database.accountsDatabaseQueries.selectById +
        database.accountsDatabaseQueries.selectByName})
  }

  public override fun updateRosterVersion(roster_version: String?, id: Long): Unit {
    driver.execute(-2007378227, """UPDATE Accounts SET roster_version=? WHERE id=?""", 2) {
      bindString(1, roster_version)
      bindLong(2, id)
    }
    notifyQueries(-2007378227, {database.rosterDatabaseQueries.getVersion +
        database.accountsDatabaseQueries.selectAll + database.accountsDatabaseQueries.selectById +
        database.accountsDatabaseQueries.selectByName})
  }

  private inner class SelectByNameQuery<out T : Any>(
    public val userjid: BareJID,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectByName, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1511583349,
        """SELECT * FROM Accounts WHERE userjid=?""", 1) {
      bindString(1, database.AccountsAdapter.userjidAdapter.encode(userjid))
    }

    public override fun toString(): String = "AccountsDatabase.sq:selectByName"
  }

  private inner class SelectByIdQuery<out T : Any>(
    public val id: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(765817733,
        """SELECT * FROM Accounts WHERE id=?""", 1) {
      bindLong(1, id)
    }

    public override fun toString(): String = "AccountsDatabase.sq:selectById"
  }
}

private class AttachmentsQueriesImpl(
  private val database: OfficialTeaDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), AttachmentsQueries {
  internal val getByOriginId: MutableList<Query<*>> = copyOnWriteList()

  internal val allNotDownloadedByAccount: MutableList<Query<*>> = copyOnWriteList()

  internal val allNotDownloadedByAccountAndIds: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getByOriginId(
    account: BareJID,
    originStanzaId: String,
    mapper: (
      id: Long,
      openchat_id: Long?,
      account: BareJID,
      origin_stanza_id: String,
      url: String?,
      fileName: String?,
      type: String?,
      size: Long?,
      downloaded: Boolean,
      localFile: String?
    ) -> T
  ): Query<T> = GetByOriginIdQuery(account, originStanzaId) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.AttachmentsAdapter.accountAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getLong(8)!! == 1L,
      cursor.getString(9)
    )
  }

  public override fun getByOriginId(account: BareJID, originStanzaId: String): Query<Attachments> =
      getByOriginId(account, originStanzaId) { id, openchat_id, account_, origin_stanza_id, url,
      fileName, type, size, downloaded, localFile ->
    Attachments(
      id,
      openchat_id,
      account_,
      origin_stanza_id,
      url,
      fileName,
      type,
      size,
      downloaded,
      localFile
    )
  }

  public override fun <T : Any> allNotDownloadedByAccount(account: BareJID, mapper: (
    id: Long,
    openchat_id: Long?,
    account: BareJID,
    origin_stanza_id: String,
    url: String?,
    fileName: String?,
    type: String?,
    size: Long?,
    downloaded: Boolean,
    localFile: String?
  ) -> T): Query<T> = AllNotDownloadedByAccountQuery(account) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.AttachmentsAdapter.accountAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getLong(8)!! == 1L,
      cursor.getString(9)
    )
  }

  public override fun allNotDownloadedByAccount(account: BareJID): Query<Attachments> =
      allNotDownloadedByAccount(account) { id, openchat_id, account_, origin_stanza_id, url,
      fileName, type, size, downloaded, localFile ->
    Attachments(
      id,
      openchat_id,
      account_,
      origin_stanza_id,
      url,
      fileName,
      type,
      size,
      downloaded,
      localFile
    )
  }

  public override fun <T : Any> allNotDownloadedByAccountAndIds(
    account: BareJID,
    identifiers: Collection<String>,
    mapper: (
      id: Long,
      openchat_id: Long?,
      account: BareJID,
      origin_stanza_id: String,
      url: String?,
      fileName: String?,
      type: String?,
      size: Long?,
      downloaded: Boolean,
      localFile: String?
    ) -> T
  ): Query<T> = AllNotDownloadedByAccountAndIdsQuery(account, identifiers) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.AttachmentsAdapter.accountAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getLong(8)!! == 1L,
      cursor.getString(9)
    )
  }

  public override fun allNotDownloadedByAccountAndIds(account: BareJID,
      identifiers: Collection<String>): Query<Attachments> =
      allNotDownloadedByAccountAndIds(account, identifiers) { id, openchat_id, account_,
      origin_stanza_id, url, fileName, type, size, downloaded, localFile ->
    Attachments(
      id,
      openchat_id,
      account_,
      origin_stanza_id,
      url,
      fileName,
      type,
      size,
      downloaded,
      localFile
    )
  }

  public override fun addAttachment(
    chatId: Long?,
    account: BareJID,
    originalStanzaId: String,
    url: String?,
    name: String?
  ): Unit {
    driver.execute(1916535470, """
    |INSERT INTO Attachments (openchat_id, account, origin_stanza_id, url, fileName)
    |VALUES (?,?,?,?, ?)
    """.trimMargin(), 5) {
      bindLong(1, chatId)
      bindString(2, database.AttachmentsAdapter.accountAdapter.encode(account))
      bindString(3, originalStanzaId)
      bindString(4, url)
      bindString(5, name)
    }
    notifyQueries(1916535470, {database.attachmentsQueries.allNotDownloadedByAccountAndIds +
        database.attachmentsQueries.allNotDownloadedByAccount +
        database.attachmentsQueries.getByOriginId +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.selectUnsentMessages})
  }

  public override fun addOutgoingAttachment(
    chatId: Long?,
    account: BareJID,
    originalStanzaId: String,
    fileName: String?,
    localFile: String?,
    size: Long?,
    contentType: String?
  ): Unit {
    driver.execute(-2097020870, """
    |INSERT INTO Attachments (openchat_id, account, origin_stanza_id, fileName, localFile, size, type, downloaded)
    |VALUES (?,?,?,?, ?, ?, ?,1)
    """.trimMargin(), 7) {
      bindLong(1, chatId)
      bindString(2, database.AttachmentsAdapter.accountAdapter.encode(account))
      bindString(3, originalStanzaId)
      bindString(4, fileName)
      bindString(5, localFile)
      bindLong(6, size)
      bindString(7, contentType)
    }
    notifyQueries(-2097020870, {database.attachmentsQueries.allNotDownloadedByAccountAndIds +
        database.attachmentsQueries.allNotDownloadedByAccount +
        database.attachmentsQueries.getByOriginId +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.selectUnsentMessages})
  }

  public override fun markAsDownloaded(
    size: Long?,
    localFile: String?,
    contentType: String?,
    id: Long
  ): Unit {
    driver.execute(386635292,
        """UPDATE Attachments SET downloaded=1, size=?, localFile=?, type=? WHERE id=?""", 4) {
      bindLong(1, size)
      bindString(2, localFile)
      bindString(3, contentType)
      bindLong(4, id)
    }
    notifyQueries(386635292, {database.attachmentsQueries.allNotDownloadedByAccountAndIds +
        database.attachmentsQueries.allNotDownloadedByAccount +
        database.attachmentsQueries.getByOriginId +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.selectUnsentMessages})
  }

  public override fun markAsUploaded(
    url: String?,
    account: BareJID,
    originStanzaId: String
  ): Unit {
    driver.execute(310876821,
        """UPDATE Attachments SET downloaded=1, url=? WHERE account=? AND origin_stanza_id=?""", 3)
        {
      bindString(1, url)
      bindString(2, database.AttachmentsAdapter.accountAdapter.encode(account))
      bindString(3, originStanzaId)
    }
    notifyQueries(310876821, {database.attachmentsQueries.allNotDownloadedByAccountAndIds +
        database.attachmentsQueries.allNotDownloadedByAccount +
        database.attachmentsQueries.getByOriginId +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.selectUnsentMessages})
  }

  private inner class GetByOriginIdQuery<out T : Any>(
    public val account: BareJID,
    public val originStanzaId: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getByOriginId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1026141608,
        """SELECT * FROM Attachments WHERE account=? AND origin_stanza_id=?""", 2) {
      bindString(1, database.AttachmentsAdapter.accountAdapter.encode(account))
      bindString(2, originStanzaId)
    }

    public override fun toString(): String = "Attachments.sq:getByOriginId"
  }

  private inner class AllNotDownloadedByAccountQuery<out T : Any>(
    public val account: BareJID,
    mapper: (SqlCursor) -> T
  ) : Query<T>(allNotDownloadedByAccount, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1470087513,
        """SELECT * FROM Attachments WHERE account=? AND downloaded=0""", 1) {
      bindString(1, database.AttachmentsAdapter.accountAdapter.encode(account))
    }

    public override fun toString(): String = "Attachments.sq:allNotDownloadedByAccount"
  }

  private inner class AllNotDownloadedByAccountAndIdsQuery<out T : Any>(
    public val account: BareJID,
    public val identifiers: Collection<String>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(allNotDownloadedByAccountAndIds, mapper) {
    public override fun execute(): SqlCursor {
      val identifiersIndexes = createArguments(count = identifiers.size)
      return driver.executeQuery(null,
          """SELECT * FROM Attachments WHERE account=? AND downloaded=0 AND origin_stanza_id IN $identifiersIndexes""",
          1 + identifiers.size) {
        bindString(1, database.AttachmentsAdapter.accountAdapter.encode(account))
        identifiers.forEachIndexed { index, identifiers_ ->
            bindString(index + 2, identifiers_)
            }
      }
    }

    public override fun toString(): String = "Attachments.sq:allNotDownloadedByAccountAndIds"
  }
}

private class AvatarsCacheDatabaseQueriesImpl(
  private val database: OfficialTeaDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), AvatarsCacheDatabaseQueries {
  internal val selectByJid: MutableList<Query<*>> = copyOnWriteList()

  internal val selectByJidHash: MutableList<Query<*>> = copyOnWriteList()

  internal val checkIfExists: MutableList<Query<*>> = copyOnWriteList()

  internal val checkIfAvatarExists: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectByJid(jid: BareJID, mapper: (
    id: Long,
    jid: BareJID,
    avatarId: String,
    bytes: Long?,
    width: Long?,
    height: Long?,
    type: String?,
    url: String?,
    avatarData: ByteArray
  ) -> T): Query<T> = SelectByJidQuery(jid) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.AvatarsCacheAdapter.jidAdapter.decode(cursor.getString(1)!!),
      cursor.getString(2)!!,
      cursor.getLong(3),
      cursor.getLong(4),
      cursor.getLong(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getBytes(8)!!
    )
  }

  public override fun selectByJid(jid: BareJID): Query<AvatarsCache> = selectByJid(jid) { id, jid_,
      avatarId, bytes, width, height, type, url, avatarData ->
    AvatarsCache(
      id,
      jid_,
      avatarId,
      bytes,
      width,
      height,
      type,
      url,
      avatarData
    )
  }

  public override fun <T : Any> selectByJidHash(
    jid: BareJID,
    avatarId: String,
    mapper: (
      id: Long,
      jid: BareJID,
      avatarId: String,
      bytes: Long?,
      width: Long?,
      height: Long?,
      type: String?,
      url: String?,
      avatarData: ByteArray
    ) -> T
  ): Query<T> = SelectByJidHashQuery(jid, avatarId) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.AvatarsCacheAdapter.jidAdapter.decode(cursor.getString(1)!!),
      cursor.getString(2)!!,
      cursor.getLong(3),
      cursor.getLong(4),
      cursor.getLong(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getBytes(8)!!
    )
  }

  public override fun selectByJidHash(jid: BareJID, avatarId: String): Query<AvatarsCache> =
      selectByJidHash(jid, avatarId) { id, jid_, avatarId_, bytes, width, height, type, url,
      avatarData ->
    AvatarsCache(
      id,
      jid_,
      avatarId_,
      bytes,
      width,
      height,
      type,
      url,
      avatarData
    )
  }

  public override fun checkIfExists(jid: BareJID, avatarId: String): Query<Long> =
      CheckIfExistsQuery(jid, avatarId) { cursor ->
    cursor.getLong(0)!!
  }

  public override fun checkIfAvatarExists(jid: BareJID): Query<Long> =
      CheckIfAvatarExistsQuery(jid) { cursor ->
    cursor.getLong(0)!!
  }

  public override fun add(
    jid: BareJID,
    avatarId: String,
    bytes: Long?,
    width: Long?,
    height: Long?,
    type: String?,
    url: String?,
    avatarData: ByteArray
  ): Unit {
    driver.execute(-941831640, """
    |INSERT INTO AvatarsCache (jid, avatarId, bytes,  width, height, type, url, avatarData)
    |VALUES (?,?,?,?,?,?,?,?)
    """.trimMargin(), 8) {
      bindString(1, database.AvatarsCacheAdapter.jidAdapter.encode(jid))
      bindString(2, avatarId)
      bindLong(3, bytes)
      bindLong(4, width)
      bindLong(5, height)
      bindString(6, type)
      bindString(7, url)
      bindBytes(8, avatarData)
    }
    notifyQueries(-941831640, {database.avatarsCacheDatabaseQueries.checkIfExists +
        database.avatarsCacheDatabaseQueries.selectByJid +
        database.avatarsCacheDatabaseQueries.selectByJidHash +
        database.avatarsCacheDatabaseQueries.checkIfAvatarExists})
  }

  private inner class SelectByJidQuery<out T : Any>(
    public val jid: BareJID,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectByJid, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1622043111,
        """SELECT * FROM AvatarsCache WHERE jid=? LIMIT 1""", 1) {
      bindString(1, database.AvatarsCacheAdapter.jidAdapter.encode(jid))
    }

    public override fun toString(): String = "AvatarsCacheDatabase.sq:selectByJid"
  }

  private inner class SelectByJidHashQuery<out T : Any>(
    public val jid: BareJID,
    public val avatarId: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectByJidHash, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-770107705,
        """SELECT * FROM AvatarsCache WHERE jid=? AND avatarId=? LIMIT 1""", 2) {
      bindString(1, database.AvatarsCacheAdapter.jidAdapter.encode(jid))
      bindString(2, avatarId)
    }

    public override fun toString(): String = "AvatarsCacheDatabase.sq:selectByJidHash"
  }

  private inner class CheckIfExistsQuery<out T : Any>(
    public val jid: BareJID,
    public val avatarId: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(checkIfExists, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1898776120,
        """SELECT 1  FROM AvatarsCache WHERE jid=? AND avatarId=? LIMIT 1""", 2) {
      bindString(1, database.AvatarsCacheAdapter.jidAdapter.encode(jid))
      bindString(2, avatarId)
    }

    public override fun toString(): String = "AvatarsCacheDatabase.sq:checkIfExists"
  }

  private inner class CheckIfAvatarExistsQuery<out T : Any>(
    public val jid: BareJID,
    mapper: (SqlCursor) -> T
  ) : Query<T>(checkIfAvatarExists, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(673824641,
        """SELECT 1  FROM AvatarsCache WHERE jid=? LIMIT 1""", 1) {
      bindString(1, database.AvatarsCacheAdapter.jidAdapter.encode(jid))
    }

    public override fun toString(): String = "AvatarsCacheDatabase.sq:checkIfAvatarExists"
  }
}

private class CapabilitiesCacheQueriesImpl(
  private val database: OfficialTeaDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), CapabilitiesCacheQueries {
  internal val identityExists: MutableList<Query<*>> = copyOnWriteList()

  internal val findFeatures: MutableList<Query<*>> = copyOnWriteList()

  internal val findIdentities: MutableList<Query<*>> = copyOnWriteList()

  public override fun identityExists(node: String): Query<Long> = IdentityExistsQuery(node) {
      cursor ->
    cursor.getLong(0)!!
  }

  public override fun <T : Any> findFeatures(node: String, mapper: (node: String,
      feature: String) -> T): Query<T> = FindFeaturesQuery(node) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!
    )
  }

  public override fun findFeatures(node: String): Query<CapsFeatures> = findFeatures(node) { node_,
      feature ->
    CapsFeatures(
      node_,
      feature
    )
  }

  public override fun <T : Any> findIdentities(node: String, mapper: (
    node: String,
    name: String?,
    type: String?,
    category: String?
  ) -> T): Query<T> = FindIdentitiesQuery(node) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3)
    )
  }

  public override fun findIdentities(node: String): Query<CapsIdentities> = findIdentities(node) {
      node_, name, type, category ->
    CapsIdentities(
      node_,
      name,
      type,
      category
    )
  }

  public override fun addFeature(node: String, feature: String): Unit {
    driver.execute(-1893441529, """INSERT INTO CapsFeatures (node, feature) VALUES (?,?)""", 2) {
      bindString(1, node)
      bindString(2, feature)
    }
    notifyQueries(-1893441529, {database.capabilitiesCacheQueries.findFeatures})
  }

  public override fun addIdentity(
    node: String,
    name: String?,
    type: String?,
    category: String?
  ): Unit {
    driver.execute(1587752397,
        """INSERT INTO CapsIdentities (node, name, type, category) VALUES (?,?,?,?)""", 4) {
      bindString(1, node)
      bindString(2, name)
      bindString(3, type)
      bindString(4, category)
    }
    notifyQueries(1587752397, {database.capabilitiesCacheQueries.findIdentities +
        database.capabilitiesCacheQueries.identityExists})
  }

  private inner class IdentityExistsQuery<out T : Any>(
    public val node: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(identityExists, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(2066747052,
        """SELECT 1  FROM CapsIdentities WHERE node=? LIMIT 1""", 1) {
      bindString(1, node)
    }

    public override fun toString(): String = "CapabilitiesCache.sq:identityExists"
  }

  private inner class FindFeaturesQuery<out T : Any>(
    public val node: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findFeatures, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1882951048,
        """SELECT * FROM CapsFeatures WHERE node=?""", 1) {
      bindString(1, node)
    }

    public override fun toString(): String = "CapabilitiesCache.sq:findFeatures"
  }

  private inner class FindIdentitiesQuery<out T : Any>(
    public val node: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findIdentities, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-132608921,
        """SELECT * FROM CapsIdentities WHERE node=?""", 1) {
      bindString(1, node)
    }

    public override fun toString(): String = "CapabilitiesCache.sq:findIdentities"
  }
}

private class ChatMarkersQueriesImpl(
  private val database: OfficialTeaDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), ChatMarkersQueries {
  internal val findMarker: MutableList<Query<*>> = copyOnWriteList()

  internal val listMarkers: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> findMarker(
    openchat_id: Long,
    sender_nick: String,
    sender_id: String,
    sender_jid: BareJID,
    mapper: (type: ChatMarkersModule.Marker, timestamp: Instant) -> T
  ): Query<T> = FindMarkerQuery(openchat_id, sender_nick, sender_id, sender_jid) { cursor ->
    mapper(
      database.ChatMarkersAdapter.typeAdapter.decode(cursor.getString(0)!!),
      database.ChatMarkersAdapter.timestampAdapter.decode(cursor.getLong(1)!!)
    )
  }

  public override fun findMarker(
    openchat_id: Long,
    sender_nick: String,
    sender_id: String,
    sender_jid: BareJID
  ): Query<FindMarker> = findMarker(openchat_id, sender_nick, sender_id, sender_jid) { type,
      timestamp ->
    FindMarker(
      type,
      timestamp
    )
  }

  public override fun <T : Any> listMarkers(openchat_id: Long, mapper: (
    openchat_id: Long,
    sender_nick: String,
    sender_id: String,
    sender_jid: BareJID,
    timestamp: Instant,
    type: ChatMarkersModule.Marker
  ) -> T): Query<T> = ListMarkersQuery(openchat_id) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      database.ChatMarkersAdapter.sender_jidAdapter.decode(cursor.getString(3)!!),
      database.ChatMarkersAdapter.timestampAdapter.decode(cursor.getLong(4)!!),
      database.ChatMarkersAdapter.typeAdapter.decode(cursor.getString(5)!!)
    )
  }

  public override fun listMarkers(openchat_id: Long): Query<ChatMarkers> =
      listMarkers(openchat_id) { openchat_id_, sender_nick, sender_id, sender_jid, timestamp,
      type ->
    ChatMarkers(
      openchat_id_,
      sender_nick,
      sender_id,
      sender_jid,
      timestamp,
      type
    )
  }

  public override fun addMarker(
    openchat_id: Long,
    sender_nick: String,
    sender_id: String,
    sender_jid: BareJID,
    timestamp: Instant,
    type: ChatMarkersModule.Marker
  ): Unit {
    driver.execute(1437265300, """
    |INSERT INTO ChatMarkers (openchat_id, sender_nick, sender_id, sender_jid, timestamp, type)
    |VALUES (?,?,?,?,?,?)
    """.trimMargin(), 6) {
      bindLong(1, openchat_id)
      bindString(2, sender_nick)
      bindString(3, sender_id)
      bindString(4, database.ChatMarkersAdapter.sender_jidAdapter.encode(sender_jid))
      bindLong(5, database.ChatMarkersAdapter.timestampAdapter.encode(timestamp))
      bindString(6, database.ChatMarkersAdapter.typeAdapter.encode(type))
    }
    notifyQueries(1437265300, {database.chatMarkersQueries.findMarker +
        database.chatMarkersQueries.listMarkers})
  }

  public override fun updateMarker(
    timestamp: Instant,
    type: ChatMarkersModule.Marker,
    openchat_id: Long,
    sender_nick: String,
    sender_id: String,
    sender_jid: BareJID
  ): Unit {
    driver.execute(983851626,
        """UPDATE ChatMarkers SET timestamp = ?, type = ? WHERE openchat_id=? AND sender_nick = ? AND sender_id = ? AND sender_jid = ?""",
        6) {
      bindLong(1, database.ChatMarkersAdapter.timestampAdapter.encode(timestamp))
      bindString(2, database.ChatMarkersAdapter.typeAdapter.encode(type))
      bindLong(3, openchat_id)
      bindString(4, sender_nick)
      bindString(5, sender_id)
      bindString(6, database.ChatMarkersAdapter.sender_jidAdapter.encode(sender_jid))
    }
    notifyQueries(983851626, {database.chatMarkersQueries.findMarker +
        database.chatMarkersQueries.listMarkers})
  }

  private inner class FindMarkerQuery<out T : Any>(
    public val openchat_id: Long,
    public val sender_nick: String,
    public val sender_id: String,
    public val sender_jid: BareJID,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findMarker, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1226440422,
        """SELECT type, timestamp FROM ChatMarkers WHERE openchat_id=? AND sender_nick = ? AND sender_id = ? AND sender_jid = ?""",
        4) {
      bindLong(1, openchat_id)
      bindString(2, sender_nick)
      bindString(3, sender_id)
      bindString(4, database.ChatMarkersAdapter.sender_jidAdapter.encode(sender_jid))
    }

    public override fun toString(): String = "ChatMarkers.sq:findMarker"
  }

  private inner class ListMarkersQuery<out T : Any>(
    public val openchat_id: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(listMarkers, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(111102996,
        """SELECT * FROM ChatMarkers WHERE openchat_id=?""", 1) {
      bindLong(1, openchat_id)
    }

    public override fun toString(): String = "ChatMarkers.sq:listMarkers"
  }
}

private class MessagesDatabaseQueriesImpl(
  private val database: OfficialTeaDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), MessagesDatabaseQueries {
  internal val getLatestMamStanzaId: MutableList<Query<*>> = copyOnWriteList()

  internal val getLatestMessage: MutableList<Query<*>> = copyOnWriteList()

  internal val getRowIdByOriginId: MutableList<Query<*>> = copyOnWriteList()

  internal val getByOriginId: MutableList<Query<*>> = copyOnWriteList()

  internal val getByRemoteId: MutableList<Query<*>> = copyOnWriteList()

  internal val messageWithAttachment: MutableList<Query<*>> = copyOnWriteList()

  internal val countByChatId: MutableList<Query<*>> = copyOnWriteList()

  internal val messageWithAttachmentAndState: MutableList<Query<*>> = copyOnWriteList()

  internal val selectByState: MutableList<Query<*>> = copyOnWriteList()

  internal val getOlderThan: MutableList<Query<*>> = copyOnWriteList()

  internal val selectUnsentMessages: MutableList<Query<*>> = copyOnWriteList()

  internal val selectNewest: MutableList<Query<*>> = copyOnWriteList()

  internal val lastInsertRowId: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getLatestMamStanzaId(account: BareJID, mapper: (
    id: Long,
    openchat_id: Long?,
    account: BareJID,
    author_jid: JID,
    author_nickname: String?,
    recipient_jid: JID,
    recipient_nickname: String?,
    stanza_type: MessageType,
    origin_stanza_id: String,
    mam_stanza_id: String,
    remote_stanza_id: String?,
    participant_id: String?,
    body: String?,
    timestamp: Instant,
    state: MessageState,
    markable: Boolean,
    correction_stanza_id: String?,
    correction_timestamp: Instant?
  ) -> T): Query<T> = GetLatestMamStanzaIdQuery(account) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.MessagesAdapter.accountAdapter.decode(cursor.getString(2)!!),
      database.MessagesAdapter.author_jidAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4),
      database.MessagesAdapter.recipient_jidAdapter.decode(cursor.getString(5)!!),
      cursor.getString(6),
      database.MessagesAdapter.stanza_typeAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!,
      cursor.getString(9)!!,
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      database.MessagesAdapter.timestampAdapter.decode(cursor.getLong(13)!!),
      database.MessagesAdapter.stateAdapter.decode(cursor.getString(14)!!),
      cursor.getLong(15)!! == 1L,
      cursor.getString(16),
      cursor.getLong(17)?.let { database.MessagesAdapter.correction_timestampAdapter.decode(it) }
    )
  }

  public override fun getLatestMamStanzaId(account: BareJID): Query<GetLatestMamStanzaId> =
      getLatestMamStanzaId(account) { id, openchat_id, account_, author_jid, author_nickname,
      recipient_jid, recipient_nickname, stanza_type, origin_stanza_id, mam_stanza_id,
      remote_stanza_id, participant_id, body, timestamp, state, markable, correction_stanza_id,
      correction_timestamp ->
    GetLatestMamStanzaId(
      id,
      openchat_id,
      account_,
      author_jid,
      author_nickname,
      recipient_jid,
      recipient_nickname,
      stanza_type,
      origin_stanza_id,
      mam_stanza_id,
      remote_stanza_id,
      participant_id,
      body,
      timestamp,
      state,
      markable,
      correction_stanza_id,
      correction_timestamp
    )
  }

  public override fun <T : Any> getLatestMessage(
    openchat_id: Long?,
    state: Collection<MessageState>,
    mapper: (
      id: Long,
      openchat_id: Long?,
      account: BareJID,
      author_jid: JID,
      author_nickname: String?,
      recipient_jid: JID,
      recipient_nickname: String?,
      stanza_type: MessageType,
      origin_stanza_id: String,
      mam_stanza_id: String?,
      remote_stanza_id: String?,
      participant_id: String?,
      body: String?,
      timestamp: Instant,
      state: MessageState,
      markable: Boolean,
      correction_stanza_id: String?,
      correction_timestamp: Instant?
    ) -> T
  ): Query<T> = GetLatestMessageQuery(openchat_id, state) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.MessagesAdapter.accountAdapter.decode(cursor.getString(2)!!),
      database.MessagesAdapter.author_jidAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4),
      database.MessagesAdapter.recipient_jidAdapter.decode(cursor.getString(5)!!),
      cursor.getString(6),
      database.MessagesAdapter.stanza_typeAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!,
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      database.MessagesAdapter.timestampAdapter.decode(cursor.getLong(13)!!),
      database.MessagesAdapter.stateAdapter.decode(cursor.getString(14)!!),
      cursor.getLong(15)!! == 1L,
      cursor.getString(16),
      cursor.getLong(17)?.let { database.MessagesAdapter.correction_timestampAdapter.decode(it) }
    )
  }

  public override fun getLatestMessage(openchat_id: Long?, state: Collection<MessageState>):
      Query<Messages> = getLatestMessage(openchat_id, state) { id, openchat_id_, account,
      author_jid, author_nickname, recipient_jid, recipient_nickname, stanza_type, origin_stanza_id,
      mam_stanza_id, remote_stanza_id, participant_id, body, timestamp, state_, markable,
      correction_stanza_id, correction_timestamp ->
    Messages(
      id,
      openchat_id_,
      account,
      author_jid,
      author_nickname,
      recipient_jid,
      recipient_nickname,
      stanza_type,
      origin_stanza_id,
      mam_stanza_id,
      remote_stanza_id,
      participant_id,
      body,
      timestamp,
      state_,
      markable,
      correction_stanza_id,
      correction_timestamp
    )
  }

  public override fun getRowIdByOriginId(
    openchat_id: Long?,
    author_jid: String,
    origin_stanza_id: String
  ): Query<Long> = GetRowIdByOriginIdQuery(openchat_id, author_jid, origin_stanza_id) { cursor ->
    cursor.getLong(0)!!
  }

  public override fun <T : Any> getByOriginId(
    openchat_id: Long?,
    author_jid: String,
    origin_stanza_id: String,
    mapper: (
      id: Long,
      openchat_id: Long?,
      account: BareJID,
      author_jid: JID,
      author_nickname: String?,
      recipient_jid: JID,
      recipient_nickname: String?,
      stanza_type: MessageType,
      origin_stanza_id: String,
      mam_stanza_id: String?,
      remote_stanza_id: String?,
      participant_id: String?,
      body: String?,
      timestamp: Instant,
      state: MessageState,
      markable: Boolean,
      correction_stanza_id: String?,
      correction_timestamp: Instant?
    ) -> T
  ): Query<T> = GetByOriginIdQuery(openchat_id, author_jid, origin_stanza_id) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.MessagesAdapter.accountAdapter.decode(cursor.getString(2)!!),
      database.MessagesAdapter.author_jidAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4),
      database.MessagesAdapter.recipient_jidAdapter.decode(cursor.getString(5)!!),
      cursor.getString(6),
      database.MessagesAdapter.stanza_typeAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!,
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      database.MessagesAdapter.timestampAdapter.decode(cursor.getLong(13)!!),
      database.MessagesAdapter.stateAdapter.decode(cursor.getString(14)!!),
      cursor.getLong(15)!! == 1L,
      cursor.getString(16),
      cursor.getLong(17)?.let { database.MessagesAdapter.correction_timestampAdapter.decode(it) }
    )
  }

  public override fun getByOriginId(
    openchat_id: Long?,
    author_jid: String,
    origin_stanza_id: String
  ): Query<Messages> = getByOriginId(openchat_id, author_jid, origin_stanza_id) { id, openchat_id_,
      account, author_jid_, author_nickname, recipient_jid, recipient_nickname, stanza_type,
      origin_stanza_id_, mam_stanza_id, remote_stanza_id, participant_id, body, timestamp, state,
      markable, correction_stanza_id, correction_timestamp ->
    Messages(
      id,
      openchat_id_,
      account,
      author_jid_,
      author_nickname,
      recipient_jid,
      recipient_nickname,
      stanza_type,
      origin_stanza_id_,
      mam_stanza_id,
      remote_stanza_id,
      participant_id,
      body,
      timestamp,
      state,
      markable,
      correction_stanza_id,
      correction_timestamp
    )
  }

  public override fun <T : Any> getByRemoteId(
    openchat_id: Long?,
    remote_stanza_id: String?,
    mapper: (
      id: Long,
      openchat_id: Long?,
      account: BareJID,
      author_jid: JID,
      author_nickname: String?,
      recipient_jid: JID,
      recipient_nickname: String?,
      stanza_type: MessageType,
      origin_stanza_id: String,
      mam_stanza_id: String?,
      remote_stanza_id: String?,
      participant_id: String?,
      body: String?,
      timestamp: Instant,
      state: MessageState,
      markable: Boolean,
      correction_stanza_id: String?,
      correction_timestamp: Instant?
    ) -> T
  ): Query<T> = GetByRemoteIdQuery(openchat_id, remote_stanza_id) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.MessagesAdapter.accountAdapter.decode(cursor.getString(2)!!),
      database.MessagesAdapter.author_jidAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4),
      database.MessagesAdapter.recipient_jidAdapter.decode(cursor.getString(5)!!),
      cursor.getString(6),
      database.MessagesAdapter.stanza_typeAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!,
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      database.MessagesAdapter.timestampAdapter.decode(cursor.getLong(13)!!),
      database.MessagesAdapter.stateAdapter.decode(cursor.getString(14)!!),
      cursor.getLong(15)!! == 1L,
      cursor.getString(16),
      cursor.getLong(17)?.let { database.MessagesAdapter.correction_timestampAdapter.decode(it) }
    )
  }

  public override fun getByRemoteId(openchat_id: Long?, remote_stanza_id: String?): Query<Messages>
      = getByRemoteId(openchat_id, remote_stanza_id) { id, openchat_id_, account, author_jid,
      author_nickname, recipient_jid, recipient_nickname, stanza_type, origin_stanza_id,
      mam_stanza_id, remote_stanza_id_, participant_id, body, timestamp, state, markable,
      correction_stanza_id, correction_timestamp ->
    Messages(
      id,
      openchat_id_,
      account,
      author_jid,
      author_nickname,
      recipient_jid,
      recipient_nickname,
      stanza_type,
      origin_stanza_id,
      mam_stanza_id,
      remote_stanza_id_,
      participant_id,
      body,
      timestamp,
      state,
      markable,
      correction_stanza_id,
      correction_timestamp
    )
  }

  public override fun <T : Any> messageWithAttachment(
    chatId: Long?,
    size: Long,
    mapper: (
      id: Long,
      openchat_id: Long?,
      account: BareJID,
      author_jid: JID,
      author_nickname: String?,
      recipient_jid: JID,
      recipient_nickname: String?,
      stanza_type: MessageType,
      origin_stanza_id: String,
      mam_stanza_id: String?,
      remote_stanza_id: String?,
      participant_id: String?,
      body: String?,
      timestamp: Instant,
      state: MessageState,
      markable: Boolean,
      correction_stanza_id: String?,
      correction_timestamp: Instant?,
      attachment_id: Long?,
      attachment_url: String?,
      attachment_downloaded: Boolean?,
      attachment_name: String?,
      attachment_size: Long?,
      attachment_localFile: String?,
      attachment_contentType: String?
    ) -> T
  ): Query<T> = MessageWithAttachmentQuery(chatId, size) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.MessagesAdapter.accountAdapter.decode(cursor.getString(2)!!),
      database.MessagesAdapter.author_jidAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4),
      database.MessagesAdapter.recipient_jidAdapter.decode(cursor.getString(5)!!),
      cursor.getString(6),
      database.MessagesAdapter.stanza_typeAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!,
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      database.MessagesAdapter.timestampAdapter.decode(cursor.getLong(13)!!),
      database.MessagesAdapter.stateAdapter.decode(cursor.getString(14)!!),
      cursor.getLong(15)!! == 1L,
      cursor.getString(16),
      cursor.getLong(17)?.let { database.MessagesAdapter.correction_timestampAdapter.decode(it) },
      cursor.getLong(18),
      cursor.getString(19),
      cursor.getLong(20)?.let { it == 1L },
      cursor.getString(21),
      cursor.getLong(22),
      cursor.getString(23),
      cursor.getString(24)
    )
  }

  public override fun messageWithAttachment(chatId: Long?, size: Long): Query<MessageWithAttachment>
      = messageWithAttachment(chatId, size) { id, openchat_id, account, author_jid, author_nickname,
      recipient_jid, recipient_nickname, stanza_type, origin_stanza_id, mam_stanza_id,
      remote_stanza_id, participant_id, body, timestamp, state, markable, correction_stanza_id,
      correction_timestamp, attachment_id, attachment_url, attachment_downloaded, attachment_name,
      attachment_size, attachment_localFile, attachment_contentType ->
    MessageWithAttachment(
      id,
      openchat_id,
      account,
      author_jid,
      author_nickname,
      recipient_jid,
      recipient_nickname,
      stanza_type,
      origin_stanza_id,
      mam_stanza_id,
      remote_stanza_id,
      participant_id,
      body,
      timestamp,
      state,
      markable,
      correction_stanza_id,
      correction_timestamp,
      attachment_id,
      attachment_url,
      attachment_downloaded,
      attachment_name,
      attachment_size,
      attachment_localFile,
      attachment_contentType
    )
  }

  public override fun countByChatId(chatId: Long?): Query<Long> = CountByChatIdQuery(chatId) {
      cursor ->
    cursor.getLong(0)!!
  }

  public override fun <T : Any> messageWithAttachmentAndState(
    openchat_id: Long?,
    state: Collection<MessageState>,
    mapper: (
      id: Long,
      openchat_id: Long?,
      account: BareJID,
      author_jid: JID,
      author_nickname: String?,
      recipient_jid: JID,
      recipient_nickname: String?,
      stanza_type: MessageType,
      origin_stanza_id: String,
      mam_stanza_id: String?,
      remote_stanza_id: String?,
      participant_id: String?,
      body: String?,
      timestamp: Instant,
      state: MessageState,
      markable: Boolean,
      correction_stanza_id: String?,
      correction_timestamp: Instant?
    ) -> T
  ): Query<T> = MessageWithAttachmentAndStateQuery(openchat_id, state) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.MessagesAdapter.accountAdapter.decode(cursor.getString(2)!!),
      database.MessagesAdapter.author_jidAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4),
      database.MessagesAdapter.recipient_jidAdapter.decode(cursor.getString(5)!!),
      cursor.getString(6),
      database.MessagesAdapter.stanza_typeAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!,
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      database.MessagesAdapter.timestampAdapter.decode(cursor.getLong(13)!!),
      database.MessagesAdapter.stateAdapter.decode(cursor.getString(14)!!),
      cursor.getLong(15)!! == 1L,
      cursor.getString(16),
      cursor.getLong(17)?.let { database.MessagesAdapter.correction_timestampAdapter.decode(it) }
    )
  }

  public override fun messageWithAttachmentAndState(openchat_id: Long?,
      state: Collection<MessageState>): Query<Messages> = messageWithAttachmentAndState(openchat_id,
      state) { id, openchat_id_, account, author_jid, author_nickname, recipient_jid,
      recipient_nickname, stanza_type, origin_stanza_id, mam_stanza_id, remote_stanza_id,
      participant_id, body, timestamp, state_, markable, correction_stanza_id,
      correction_timestamp ->
    Messages(
      id,
      openchat_id_,
      account,
      author_jid,
      author_nickname,
      recipient_jid,
      recipient_nickname,
      stanza_type,
      origin_stanza_id,
      mam_stanza_id,
      remote_stanza_id,
      participant_id,
      body,
      timestamp,
      state_,
      markable,
      correction_stanza_id,
      correction_timestamp
    )
  }

  public override fun <T : Any> selectByState(state: Collection<MessageState>, mapper: (
    id: Long,
    openchat_id: Long?,
    account: BareJID,
    author_jid: JID,
    author_nickname: String?,
    recipient_jid: JID,
    recipient_nickname: String?,
    stanza_type: MessageType,
    origin_stanza_id: String,
    mam_stanza_id: String?,
    remote_stanza_id: String?,
    participant_id: String?,
    body: String?,
    timestamp: Instant,
    state: MessageState,
    markable: Boolean,
    correction_stanza_id: String?,
    correction_timestamp: Instant?
  ) -> T): Query<T> = SelectByStateQuery(state) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.MessagesAdapter.accountAdapter.decode(cursor.getString(2)!!),
      database.MessagesAdapter.author_jidAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4),
      database.MessagesAdapter.recipient_jidAdapter.decode(cursor.getString(5)!!),
      cursor.getString(6),
      database.MessagesAdapter.stanza_typeAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!,
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      database.MessagesAdapter.timestampAdapter.decode(cursor.getLong(13)!!),
      database.MessagesAdapter.stateAdapter.decode(cursor.getString(14)!!),
      cursor.getLong(15)!! == 1L,
      cursor.getString(16),
      cursor.getLong(17)?.let { database.MessagesAdapter.correction_timestampAdapter.decode(it) }
    )
  }

  public override fun selectByState(state: Collection<MessageState>): Query<Messages> =
      selectByState(state) { id, openchat_id, account, author_jid, author_nickname, recipient_jid,
      recipient_nickname, stanza_type, origin_stanza_id, mam_stanza_id, remote_stanza_id,
      participant_id, body, timestamp, state_, markable, correction_stanza_id,
      correction_timestamp ->
    Messages(
      id,
      openchat_id,
      account,
      author_jid,
      author_nickname,
      recipient_jid,
      recipient_nickname,
      stanza_type,
      origin_stanza_id,
      mam_stanza_id,
      remote_stanza_id,
      participant_id,
      body,
      timestamp,
      state_,
      markable,
      correction_stanza_id,
      correction_timestamp
    )
  }

  public override fun <T : Any> getOlderThan(
    openchat_id: Long?,
    timestamp: Instant,
    state: Collection<MessageState>,
    mapper: (
      id: Long,
      openchat_id: Long?,
      account: BareJID,
      author_jid: JID,
      author_nickname: String?,
      recipient_jid: JID,
      recipient_nickname: String?,
      stanza_type: MessageType,
      origin_stanza_id: String,
      mam_stanza_id: String?,
      remote_stanza_id: String?,
      participant_id: String?,
      body: String?,
      timestamp: Instant,
      state: MessageState,
      markable: Boolean,
      correction_stanza_id: String?,
      correction_timestamp: Instant?
    ) -> T
  ): Query<T> = GetOlderThanQuery(openchat_id, timestamp, state) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.MessagesAdapter.accountAdapter.decode(cursor.getString(2)!!),
      database.MessagesAdapter.author_jidAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4),
      database.MessagesAdapter.recipient_jidAdapter.decode(cursor.getString(5)!!),
      cursor.getString(6),
      database.MessagesAdapter.stanza_typeAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!,
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      database.MessagesAdapter.timestampAdapter.decode(cursor.getLong(13)!!),
      database.MessagesAdapter.stateAdapter.decode(cursor.getString(14)!!),
      cursor.getLong(15)!! == 1L,
      cursor.getString(16),
      cursor.getLong(17)?.let { database.MessagesAdapter.correction_timestampAdapter.decode(it) }
    )
  }

  public override fun getOlderThan(
    openchat_id: Long?,
    timestamp: Instant,
    state: Collection<MessageState>
  ): Query<Messages> = getOlderThan(openchat_id, timestamp, state) { id, openchat_id_, account,
      author_jid, author_nickname, recipient_jid, recipient_nickname, stanza_type, origin_stanza_id,
      mam_stanza_id, remote_stanza_id, participant_id, body, timestamp_, state_, markable,
      correction_stanza_id, correction_timestamp ->
    Messages(
      id,
      openchat_id_,
      account,
      author_jid,
      author_nickname,
      recipient_jid,
      recipient_nickname,
      stanza_type,
      origin_stanza_id,
      mam_stanza_id,
      remote_stanza_id,
      participant_id,
      body,
      timestamp_,
      state_,
      markable,
      correction_stanza_id,
      correction_timestamp
    )
  }

  public override fun <T : Any> selectUnsentMessages(mapper: (
    id: Long,
    openchat_id: Long?,
    account: BareJID,
    author_jid: JID,
    author_nickname: String?,
    recipient_jid: JID,
    recipient_nickname: String?,
    stanza_type: MessageType,
    origin_stanza_id: String,
    mam_stanza_id: String?,
    remote_stanza_id: String?,
    participant_id: String?,
    body: String?,
    timestamp: Instant,
    state: MessageState,
    markable: Boolean,
    correction_stanza_id: String?,
    correction_timestamp: Instant?,
    type: OpenChatType,
    attachment_id: Long?,
    attachment_url: String?,
    attachment_downloaded: Boolean?,
    attachment_name: String?,
    attachment_size: Long?,
    attachment_localFile: String?,
    attachment_contentType: String?
  ) -> T): Query<T> = Query(918130550, selectUnsentMessages, driver, "MessagesDatabase.sq",
      "selectUnsentMessages", """
  |SELECT Messages.*, o.type, A.id AS attachment_id, A.url AS attachment_url, A.downloaded AS attachment_downloaded, A.fileName AS attachment_name, A.size AS attachment_size, A.localFile AS attachment_localFile,A.type AS attachment_contentType
  |FROM Messages, OpenChats o LEFT JOIN Attachments A ON Messages.origin_stanza_id = A.origin_stanza_id AND Messages.openchat_id = A.openchat_id
  |WHERE state='OutNotSent' AND Messages.openchat_id==o.id ORDER BY Messages.timestamp
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.MessagesAdapter.accountAdapter.decode(cursor.getString(2)!!),
      database.MessagesAdapter.author_jidAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4),
      database.MessagesAdapter.recipient_jidAdapter.decode(cursor.getString(5)!!),
      cursor.getString(6),
      database.MessagesAdapter.stanza_typeAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!,
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      database.MessagesAdapter.timestampAdapter.decode(cursor.getLong(13)!!),
      database.MessagesAdapter.stateAdapter.decode(cursor.getString(14)!!),
      cursor.getLong(15)!! == 1L,
      cursor.getString(16),
      cursor.getLong(17)?.let { database.MessagesAdapter.correction_timestampAdapter.decode(it) },
      database.OpenChatsAdapter.typeAdapter.decode(cursor.getString(18)!!),
      cursor.getLong(19),
      cursor.getString(20),
      cursor.getLong(21)?.let { it == 1L },
      cursor.getString(22),
      cursor.getLong(23),
      cursor.getString(24),
      cursor.getString(25)
    )
  }

  public override fun selectUnsentMessages(): Query<SelectUnsentMessages> = selectUnsentMessages {
      id, openchat_id, account, author_jid, author_nickname, recipient_jid, recipient_nickname,
      stanza_type, origin_stanza_id, mam_stanza_id, remote_stanza_id, participant_id, body,
      timestamp, state, markable, correction_stanza_id, correction_timestamp, type, attachment_id,
      attachment_url, attachment_downloaded, attachment_name, attachment_size, attachment_localFile,
      attachment_contentType ->
    SelectUnsentMessages(
      id,
      openchat_id,
      account,
      author_jid,
      author_nickname,
      recipient_jid,
      recipient_nickname,
      stanza_type,
      origin_stanza_id,
      mam_stanza_id,
      remote_stanza_id,
      participant_id,
      body,
      timestamp,
      state,
      markable,
      correction_stanza_id,
      correction_timestamp,
      type,
      attachment_id,
      attachment_url,
      attachment_downloaded,
      attachment_name,
      attachment_size,
      attachment_localFile,
      attachment_contentType
    )
  }

  public override fun <T : Any> selectNewest(
    openchat_id: Long?,
    state: Collection<MessageState>,
    mapper: (
      id: Long,
      openchat_id: Long?,
      account: BareJID,
      author_jid: JID,
      author_nickname: String?,
      recipient_jid: JID,
      recipient_nickname: String?,
      stanza_type: MessageType,
      origin_stanza_id: String,
      mam_stanza_id: String?,
      remote_stanza_id: String?,
      participant_id: String?,
      body: String?,
      timestamp: Instant,
      state: MessageState,
      markable: Boolean,
      correction_stanza_id: String?,
      correction_timestamp: Instant?
    ) -> T
  ): Query<T> = SelectNewestQuery(openchat_id, state) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1),
      database.MessagesAdapter.accountAdapter.decode(cursor.getString(2)!!),
      database.MessagesAdapter.author_jidAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4),
      database.MessagesAdapter.recipient_jidAdapter.decode(cursor.getString(5)!!),
      cursor.getString(6),
      database.MessagesAdapter.stanza_typeAdapter.decode(cursor.getString(7)!!),
      cursor.getString(8)!!,
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      database.MessagesAdapter.timestampAdapter.decode(cursor.getLong(13)!!),
      database.MessagesAdapter.stateAdapter.decode(cursor.getString(14)!!),
      cursor.getLong(15)!! == 1L,
      cursor.getString(16),
      cursor.getLong(17)?.let { database.MessagesAdapter.correction_timestampAdapter.decode(it) }
    )
  }

  public override fun selectNewest(openchat_id: Long?, state: Collection<MessageState>):
      Query<Messages> = selectNewest(openchat_id, state) { id, openchat_id_, account, author_jid,
      author_nickname, recipient_jid, recipient_nickname, stanza_type, origin_stanza_id,
      mam_stanza_id, remote_stanza_id, participant_id, body, timestamp, state_, markable,
      correction_stanza_id, correction_timestamp ->
    Messages(
      id,
      openchat_id_,
      account,
      author_jid,
      author_nickname,
      recipient_jid,
      recipient_nickname,
      stanza_type,
      origin_stanza_id,
      mam_stanza_id,
      remote_stanza_id,
      participant_id,
      body,
      timestamp,
      state_,
      markable,
      correction_stanza_id,
      correction_timestamp
    )
  }

  public override fun lastInsertRowId(): Query<Long> = Query(-372765303, lastInsertRowId, driver,
      "MessagesDatabase.sq", "lastInsertRowId", "SELECT last_insert_rowid()") { cursor ->
    cursor.getLong(0)!!
  }

  public override fun add(
    openchat_id: Long?,
    account: BareJID,
    author_jid: JID,
    author_nickname: String?,
    recipient_jid: JID,
    recipient_nickname: String?,
    stanza_type: MessageType,
    origin_stanza_id: String,
    mam_stanza_id: String?,
    remote_stanza_id: String?,
    participant_id: String?,
    body: String?,
    timestamp: Instant,
    state: MessageState,
    markable: Boolean
  ): Unit {
    driver.execute(2055838148, """
    |INSERT INTO Messages (openchat_id, account, author_jid, author_nickname, recipient_jid, recipient_nickname, stanza_type ,origin_stanza_id, mam_stanza_id, remote_stanza_id, participant_id, body, timestamp, state, markable)
    |VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)
    """.trimMargin(), 15) {
      bindLong(1, openchat_id)
      bindString(2, database.MessagesAdapter.accountAdapter.encode(account))
      bindString(3, database.MessagesAdapter.author_jidAdapter.encode(author_jid))
      bindString(4, author_nickname)
      bindString(5, database.MessagesAdapter.recipient_jidAdapter.encode(recipient_jid))
      bindString(6, recipient_nickname)
      bindString(7, database.MessagesAdapter.stanza_typeAdapter.encode(stanza_type))
      bindString(8, origin_stanza_id)
      bindString(9, mam_stanza_id)
      bindString(10, remote_stanza_id)
      bindString(11, participant_id)
      bindString(12, body)
      bindLong(13, database.MessagesAdapter.timestampAdapter.encode(timestamp))
      bindString(14, database.MessagesAdapter.stateAdapter.encode(state))
      bindLong(15, if (markable) 1L else 0L)
    }
    notifyQueries(2055838148, {database.openChatsDatabaseQueries.selectWithUnread +
        database.messagesDatabaseQueries.getByOriginId +
        database.messagesDatabaseQueries.getLatestMamStanzaId +
        database.messagesDatabaseQueries.getLatestMessage +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.countByChatId +
        database.messagesDatabaseQueries.selectByState +
        database.messagesDatabaseQueries.selectNewest +
        database.messagesDatabaseQueries.getByRemoteId +
        database.messagesDatabaseQueries.getOlderThan +
        database.messagesDatabaseQueries.selectUnsentMessages +
        database.messagesDatabaseQueries.getRowIdByOriginId +
        database.messagesDatabaseQueries.messageWithAttachmentAndState})
  }

  public override fun update(
    mam_stanza_id: String?,
    author_nickname: String?,
    remote_stanza_id: String?,
    participant_id: String?,
    timestamp: Instant,
    id: Long
  ): Unit {
    driver.execute(-175611706, """
    |UPDATE Messages SET mam_stanza_id=?, author_nickname=?, remote_stanza_id=?, participant_id=?, timestamp=?
    |WHERE id=?
    """.trimMargin(), 6) {
      bindString(1, mam_stanza_id)
      bindString(2, author_nickname)
      bindString(3, remote_stanza_id)
      bindString(4, participant_id)
      bindLong(5, database.MessagesAdapter.timestampAdapter.encode(timestamp))
      bindLong(6, id)
    }
    notifyQueries(-175611706, {database.openChatsDatabaseQueries.selectWithUnread +
        database.messagesDatabaseQueries.getByOriginId +
        database.messagesDatabaseQueries.getLatestMamStanzaId +
        database.messagesDatabaseQueries.getLatestMessage +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.countByChatId +
        database.messagesDatabaseQueries.selectByState +
        database.messagesDatabaseQueries.selectNewest +
        database.messagesDatabaseQueries.getByRemoteId +
        database.messagesDatabaseQueries.getOlderThan +
        database.messagesDatabaseQueries.selectUnsentMessages +
        database.messagesDatabaseQueries.getRowIdByOriginId +
        database.messagesDatabaseQueries.messageWithAttachmentAndState})
  }

  public override fun updateBody(
    body: String?,
    correction_stanza_id: String?,
    correction_timestamp: Instant?,
    id: Long
  ): Unit {
    driver.execute(1163803528, """
    |UPDATE Messages SET body=?, correction_stanza_id=?, correction_timestamp=?
    |WHERE id=?
    """.trimMargin(), 4) {
      bindString(1, body)
      bindString(2, correction_stanza_id)
      bindLong(3, correction_timestamp?.let {
          database.MessagesAdapter.correction_timestampAdapter.encode(it) })
      bindLong(4, id)
    }
    notifyQueries(1163803528, {database.openChatsDatabaseQueries.selectWithUnread +
        database.messagesDatabaseQueries.getByOriginId +
        database.messagesDatabaseQueries.getLatestMamStanzaId +
        database.messagesDatabaseQueries.getLatestMessage +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.countByChatId +
        database.messagesDatabaseQueries.selectByState +
        database.messagesDatabaseQueries.selectNewest +
        database.messagesDatabaseQueries.getByRemoteId +
        database.messagesDatabaseQueries.getOlderThan +
        database.messagesDatabaseQueries.selectUnsentMessages +
        database.messagesDatabaseQueries.getRowIdByOriginId +
        database.messagesDatabaseQueries.messageWithAttachmentAndState})
  }

  public override fun updateMessageState(state: MessageState, id: Collection<Long>): Unit {
    val idIndexes = createArguments(count = id.size)
    driver.execute(null, """UPDATE Messages SET state=? WHERE id IN $idIndexes""", 1 + id.size) {
      bindString(1, database.MessagesAdapter.stateAdapter.encode(state))
      id.forEachIndexed { index, id_ ->
          bindLong(index + 2, id_)
          }
    }
    notifyQueries(1103723696, {database.openChatsDatabaseQueries.selectWithUnread +
        database.messagesDatabaseQueries.getByOriginId +
        database.messagesDatabaseQueries.getLatestMamStanzaId +
        database.messagesDatabaseQueries.getLatestMessage +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.countByChatId +
        database.messagesDatabaseQueries.selectByState +
        database.messagesDatabaseQueries.selectNewest +
        database.messagesDatabaseQueries.getByRemoteId +
        database.messagesDatabaseQueries.getOlderThan +
        database.messagesDatabaseQueries.selectUnsentMessages +
        database.messagesDatabaseQueries.getRowIdByOriginId +
        database.messagesDatabaseQueries.messageWithAttachmentAndState})
  }

  public override fun updateMessageStateByOriginId(
    state: MessageState,
    openchat_id: Long?,
    author_jid: String,
    origin_stanza_id: String,
    state_: Collection<MessageState>
  ): Unit {
    val state_Indexes = createArguments(count = state_.size)
    driver.execute(null,
        """UPDATE Messages SET state=? WHERE openchat_id${ if (openchat_id == null) " IS " else "=" }? AND author_jid LIKE ? AND origin_stanza_id=? AND state IN $state_Indexes""",
        4 + state_.size) {
      bindString(1, database.MessagesAdapter.stateAdapter.encode(state))
      bindLong(2, openchat_id)
      bindString(3, author_jid)
      bindString(4, origin_stanza_id)
      state_.forEachIndexed { index, state__ ->
          bindString(index + 5, database.MessagesAdapter.stateAdapter.encode(state__))
          }
    }
    notifyQueries(-475140824, {database.openChatsDatabaseQueries.selectWithUnread +
        database.messagesDatabaseQueries.getByOriginId +
        database.messagesDatabaseQueries.getLatestMamStanzaId +
        database.messagesDatabaseQueries.getLatestMessage +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.countByChatId +
        database.messagesDatabaseQueries.selectByState +
        database.messagesDatabaseQueries.selectNewest +
        database.messagesDatabaseQueries.getByRemoteId +
        database.messagesDatabaseQueries.getOlderThan +
        database.messagesDatabaseQueries.selectUnsentMessages +
        database.messagesDatabaseQueries.getRowIdByOriginId +
        database.messagesDatabaseQueries.messageWithAttachmentAndState})
  }

  public override fun updateMessagesState(
    state: MessageState,
    openchat_id: Long?,
    timestamp: Instant,
    state_: Collection<MessageState>
  ): Unit {
    val state_Indexes = createArguments(count = state_.size)
    driver.execute(null,
        """UPDATE Messages SET state=? WHERE openchat_id${ if (openchat_id == null) " IS " else "=" }? AND timestamp<=? AND state IN $state_Indexes""",
        3 + state_.size) {
      bindString(1, database.MessagesAdapter.stateAdapter.encode(state))
      bindLong(2, openchat_id)
      bindLong(3, database.MessagesAdapter.timestampAdapter.encode(timestamp))
      state_.forEachIndexed { index, state__ ->
          bindString(index + 4, database.MessagesAdapter.stateAdapter.encode(state__))
          }
    }
    notifyQueries(741901183, {database.openChatsDatabaseQueries.selectWithUnread +
        database.messagesDatabaseQueries.getByOriginId +
        database.messagesDatabaseQueries.getLatestMamStanzaId +
        database.messagesDatabaseQueries.getLatestMessage +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.countByChatId +
        database.messagesDatabaseQueries.selectByState +
        database.messagesDatabaseQueries.selectNewest +
        database.messagesDatabaseQueries.getByRemoteId +
        database.messagesDatabaseQueries.getOlderThan +
        database.messagesDatabaseQueries.selectUnsentMessages +
        database.messagesDatabaseQueries.getRowIdByOriginId +
        database.messagesDatabaseQueries.messageWithAttachmentAndState})
  }

  private inner class GetLatestMamStanzaIdQuery<out T : Any>(
    public val account: BareJID,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getLatestMamStanzaId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1461905175, """
    |SELECT * FROM Messages
    |WHERE mam_stanza_id IS NOT NULL AND account=? ORDER BY timestamp DESC LIMIT 1
    """.trimMargin(), 1) {
      bindString(1, database.MessagesAdapter.accountAdapter.encode(account))
    }

    public override fun toString(): String = "MessagesDatabase.sq:getLatestMamStanzaId"
  }

  private inner class GetLatestMessageQuery<out T : Any>(
    public val openchat_id: Long?,
    public val state: Collection<MessageState>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getLatestMessage, mapper) {
    public override fun execute(): SqlCursor {
      val stateIndexes = createArguments(count = state.size)
      return driver.executeQuery(null,
          """SELECT * FROM Messages WHERE openchat_id${ if (openchat_id == null) " IS " else "=" }? AND state IN $stateIndexes ORDER BY timestamp DESC LIMIT 1""",
          1 + state.size) {
        bindLong(1, openchat_id)
        state.forEachIndexed { index, state_ ->
            bindString(index + 2, database.MessagesAdapter.stateAdapter.encode(state_))
            }
      }
    }

    public override fun toString(): String = "MessagesDatabase.sq:getLatestMessage"
  }

  private inner class GetRowIdByOriginIdQuery<out T : Any>(
    public val openchat_id: Long?,
    public val author_jid: String,
    public val origin_stanza_id: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getRowIdByOriginId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT id FROM Messages WHERE openchat_id${ if (openchat_id == null) " IS " else "=" }? AND author_jid LIKE ? AND origin_stanza_id=? LIMIT 1""",
        3) {
      bindLong(1, openchat_id)
      bindString(2, author_jid)
      bindString(3, origin_stanza_id)
    }

    public override fun toString(): String = "MessagesDatabase.sq:getRowIdByOriginId"
  }

  private inner class GetByOriginIdQuery<out T : Any>(
    public val openchat_id: Long?,
    public val author_jid: String,
    public val origin_stanza_id: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getByOriginId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT * FROM Messages WHERE openchat_id${ if (openchat_id == null) " IS " else "=" }? AND author_jid LIKE ? AND origin_stanza_id=? LIMIT 1""",
        3) {
      bindLong(1, openchat_id)
      bindString(2, author_jid)
      bindString(3, origin_stanza_id)
    }

    public override fun toString(): String = "MessagesDatabase.sq:getByOriginId"
  }

  private inner class GetByRemoteIdQuery<out T : Any>(
    public val openchat_id: Long?,
    public val remote_stanza_id: String?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getByRemoteId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT * FROM Messages WHERE openchat_id${ if (openchat_id == null) " IS " else "=" }? AND remote_stanza_id${ if (remote_stanza_id == null) " IS " else "=" }? LIMIT 1""",
        2) {
      bindLong(1, openchat_id)
      bindString(2, remote_stanza_id)
    }

    public override fun toString(): String = "MessagesDatabase.sq:getByRemoteId"
  }

  private inner class MessageWithAttachmentQuery<out T : Any>(
    public val chatId: Long?,
    public val size: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(messageWithAttachment, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT Messages.*, A.id AS attachment_id, A.url AS attachment_url, A.downloaded AS attachment_downloaded, A.fileName AS attachment_name, A.size AS attachment_size, A.localFile AS attachment_localFile,A.type AS attachment_contentType  FROM Messages
    |LEFT JOIN Attachments A ON Messages.origin_stanza_id = A.origin_stanza_id AND Messages.openchat_id = A.openchat_id
    |WHERE Messages.openchat_id${ if (chatId == null) " IS " else "=" }? ORDER BY timestamp DESC LIMIT ?
    """.trimMargin(), 2) {
      bindLong(1, chatId)
      bindLong(2, size)
    }

    public override fun toString(): String = "MessagesDatabase.sq:messageWithAttachment"
  }

  private inner class CountByChatIdQuery<out T : Any>(
    public val chatId: Long?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(countByChatId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT count(*) FROM Messages WHERE openchat_id${ if (chatId == null) " IS " else "=" }?""",
        1) {
      bindLong(1, chatId)
    }

    public override fun toString(): String = "MessagesDatabase.sq:countByChatId"
  }

  private inner class MessageWithAttachmentAndStateQuery<out T : Any>(
    public val openchat_id: Long?,
    public val state: Collection<MessageState>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(messageWithAttachmentAndState, mapper) {
    public override fun execute(): SqlCursor {
      val stateIndexes = createArguments(count = state.size)
      return driver.executeQuery(null,
          """SELECT * FROM Messages WHERE openchat_id${ if (openchat_id == null) " IS " else "=" }? AND state IN $stateIndexes ORDER BY timestamp DESC""",
          1 + state.size) {
        bindLong(1, openchat_id)
        state.forEachIndexed { index, state_ ->
            bindString(index + 2, database.MessagesAdapter.stateAdapter.encode(state_))
            }
      }
    }

    public override fun toString(): String = "MessagesDatabase.sq:messageWithAttachmentAndState"
  }

  private inner class SelectByStateQuery<out T : Any>(
    public val state: Collection<MessageState>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectByState, mapper) {
    public override fun execute(): SqlCursor {
      val stateIndexes = createArguments(count = state.size)
      return driver.executeQuery(null,
          """SELECT * FROM Messages WHERE state IN $stateIndexes ORDER BY timestamp""", state.size)
          {
        state.forEachIndexed { index, state_ ->
            bindString(index + 1, database.MessagesAdapter.stateAdapter.encode(state_))
            }
      }
    }

    public override fun toString(): String = "MessagesDatabase.sq:selectByState"
  }

  private inner class GetOlderThanQuery<out T : Any>(
    public val openchat_id: Long?,
    public val timestamp: Instant,
    public val state: Collection<MessageState>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getOlderThan, mapper) {
    public override fun execute(): SqlCursor {
      val stateIndexes = createArguments(count = state.size)
      return driver.executeQuery(null,
          """SELECT * FROM Messages WHERE openchat_id${ if (openchat_id == null) " IS " else "=" }? AND timestamp<=? AND state IN $stateIndexes""",
          2 + state.size) {
        bindLong(1, openchat_id)
        bindLong(2, database.MessagesAdapter.timestampAdapter.encode(timestamp))
        state.forEachIndexed { index, state_ ->
            bindString(index + 3, database.MessagesAdapter.stateAdapter.encode(state_))
            }
      }
    }

    public override fun toString(): String = "MessagesDatabase.sq:getOlderThan"
  }

  private inner class SelectNewestQuery<out T : Any>(
    public val openchat_id: Long?,
    public val state: Collection<MessageState>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectNewest, mapper) {
    public override fun execute(): SqlCursor {
      val stateIndexes = createArguments(count = state.size)
      return driver.executeQuery(null,
          """SELECT * FROM Messages WHERE openchat_id${ if (openchat_id == null) " IS " else "=" }? AND state IN $stateIndexes ORDER BY timestamp DESC LIMIT 1""",
          1 + state.size) {
        bindLong(1, openchat_id)
        state.forEachIndexed { index, state_ ->
            bindString(index + 2, database.MessagesAdapter.stateAdapter.encode(state_))
            }
      }
    }

    public override fun toString(): String = "MessagesDatabase.sq:selectNewest"
  }
}

private class OpenChatsDatabaseQueriesImpl(
  private val database: OfficialTeaDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), OpenChatsDatabaseQueries {
  internal val selectAll: MutableList<Query<*>> = copyOnWriteList()

  internal val selectByAccountJid: MutableList<Query<*>> = copyOnWriteList()

  internal val selectById: MutableList<Query<*>> = copyOnWriteList()

  internal val lastInsertRowId: MutableList<Query<*>> = copyOnWriteList()

  internal val selectWithUnread: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectAll(mapper: (
    id: Long,
    account: BareJID,
    jid: BareJID,
    name: String,
    type: OpenChatType
  ) -> T): Query<T> = Query(-1449461581, selectAll, driver, "OpenChatsDatabase.sq", "selectAll",
      "SELECT * FROM OpenChats") { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.OpenChatsAdapter.accountAdapter.decode(cursor.getString(1)!!),
      database.OpenChatsAdapter.jidAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      database.OpenChatsAdapter.typeAdapter.decode(cursor.getString(4)!!)
    )
  }

  public override fun selectAll(): Query<OpenChats> = selectAll { id, account, jid, name, type ->
    OpenChats(
      id,
      account,
      jid,
      name,
      type
    )
  }

  public override fun <T : Any> selectByAccountJid(
    account: BareJID,
    jid: BareJID,
    mapper: (
      id: Long,
      account: BareJID,
      jid: BareJID,
      name: String,
      type: OpenChatType
    ) -> T
  ): Query<T> = SelectByAccountJidQuery(account, jid) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.OpenChatsAdapter.accountAdapter.decode(cursor.getString(1)!!),
      database.OpenChatsAdapter.jidAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      database.OpenChatsAdapter.typeAdapter.decode(cursor.getString(4)!!)
    )
  }

  public override fun selectByAccountJid(account: BareJID, jid: BareJID): Query<OpenChats> =
      selectByAccountJid(account, jid) { id, account_, jid_, name, type ->
    OpenChats(
      id,
      account_,
      jid_,
      name,
      type
    )
  }

  public override fun <T : Any> selectById(id: Long, mapper: (
    id: Long,
    account: BareJID,
    jid: BareJID,
    name: String,
    type: OpenChatType
  ) -> T): Query<T> = SelectByIdQuery(id) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.OpenChatsAdapter.accountAdapter.decode(cursor.getString(1)!!),
      database.OpenChatsAdapter.jidAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      database.OpenChatsAdapter.typeAdapter.decode(cursor.getString(4)!!)
    )
  }

  public override fun selectById(id: Long): Query<OpenChats> = selectById(id) { id_, account, jid,
      name, type ->
    OpenChats(
      id_,
      account,
      jid,
      name,
      type
    )
  }

  public override fun lastInsertRowId(): Query<Long> = Query(-564843500, lastInsertRowId, driver,
      "OpenChatsDatabase.sq", "lastInsertRowId", "SELECT last_insert_rowid()") { cursor ->
    cursor.getLong(0)!!
  }

  public override fun <T : Any> selectWithUnread(mapper: (
    id: Long,
    account: BareJID,
    jid: BareJID,
    name: String,
    type: OpenChatType,
    cnt: Long?,
    inroster: Long?
  ) -> T): Query<T> = Query(-1871467325, selectWithUnread, driver, "OpenChatsDatabase.sq",
      "selectWithUnread", """
  |SELECT oc.*, x.cnt, R.inroster
  |FROM OpenChats oc
  |         LEFT OUTER JOIN (SELECT jid, account, 1 inroster FROM Roster) R ON R.account = oc.account AND R.jid = oc.jid
  |         LEFT OUTER JOIN (SELECT openchat_id, count(*) cnt
  |                          FROM Messages
  |                          WHERE state = 'IncomingUnread'
  |                          GROUP BY openchat_id) x ON oc.id == x.openchat_id
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.OpenChatsAdapter.accountAdapter.decode(cursor.getString(1)!!),
      database.OpenChatsAdapter.jidAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3)!!,
      database.OpenChatsAdapter.typeAdapter.decode(cursor.getString(4)!!),
      cursor.getLong(5),
      cursor.getLong(6)
    )
  }

  public override fun selectWithUnread(): Query<SelectWithUnread> = selectWithUnread { id, account,
      jid, name, type, cnt, inroster ->
    SelectWithUnread(
      id,
      account,
      jid,
      name,
      type,
      cnt,
      inroster
    )
  }

  public override fun add(
    account: BareJID,
    jid: BareJID,
    name: String,
    type: OpenChatType
  ): Unit {
    driver.execute(155185359,
        """INSERT INTO OpenChats (account, jid, name, type) VALUES (?,?,?,?)""", 4) {
      bindString(1, database.OpenChatsAdapter.accountAdapter.encode(account))
      bindString(2, database.OpenChatsAdapter.jidAdapter.encode(jid))
      bindString(3, name)
      bindString(4, database.OpenChatsAdapter.typeAdapter.encode(type))
    }
    notifyQueries(155185359, {database.openChatsDatabaseQueries.selectById +
        database.openChatsDatabaseQueries.selectWithUnread +
        database.openChatsDatabaseQueries.selectAll +
        database.messagesDatabaseQueries.selectUnsentMessages +
        database.openChatsDatabaseQueries.selectByAccountJid})
  }

  public override fun delete(id: Long): Unit {
    driver.execute(1829369533, """DELETE FROM OpenChats WHERE id=?""", 1) {
      bindLong(1, id)
    }
    notifyQueries(1829369533, {database.openChatsDatabaseQueries.selectById +
        database.openChatsDatabaseQueries.selectWithUnread +
        database.attachmentsQueries.allNotDownloadedByAccountAndIds +
        database.messagesDatabaseQueries.getByOriginId +
        database.attachmentsQueries.allNotDownloadedByAccount +
        database.messagesDatabaseQueries.getLatestMamStanzaId +
        database.openChatsDatabaseQueries.selectAll + database.chatMarkersQueries.findMarker +
        database.attachmentsQueries.getByOriginId +
        database.messagesDatabaseQueries.getLatestMessage +
        database.messagesDatabaseQueries.messageWithAttachment +
        database.messagesDatabaseQueries.countByChatId +
        database.messagesDatabaseQueries.selectByState + database.chatMarkersQueries.listMarkers +
        database.messagesDatabaseQueries.selectNewest +
        database.messagesDatabaseQueries.getByRemoteId +
        database.messagesDatabaseQueries.getOlderThan +
        database.messagesDatabaseQueries.selectUnsentMessages +
        database.messagesDatabaseQueries.getRowIdByOriginId +
        database.openChatsDatabaseQueries.selectByAccountJid +
        database.messagesDatabaseQueries.messageWithAttachmentAndState})
  }

  public override fun updateName(name: String, id: Long): Unit {
    driver.execute(-623234298, """UPDATE OpenChats SET name=? WHERE id=?""", 2) {
      bindString(1, name)
      bindLong(2, id)
    }
    notifyQueries(-623234298, {database.openChatsDatabaseQueries.selectById +
        database.openChatsDatabaseQueries.selectWithUnread +
        database.openChatsDatabaseQueries.selectAll +
        database.messagesDatabaseQueries.selectUnsentMessages +
        database.openChatsDatabaseQueries.selectByAccountJid})
  }

  private inner class SelectByAccountJidQuery<out T : Any>(
    public val account: BareJID,
    public val jid: BareJID,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectByAccountJid, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1205389949,
        """SELECT * FROM OpenChats WHERE account=? AND jid=?""", 2) {
      bindString(1, database.OpenChatsAdapter.accountAdapter.encode(account))
      bindString(2, database.OpenChatsAdapter.jidAdapter.encode(jid))
    }

    public override fun toString(): String = "OpenChatsDatabase.sq:selectByAccountJid"
  }

  private inner class SelectByIdQuery<out T : Any>(
    public val id: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1983594752,
        """SELECT * FROM OpenChats WHERE id=?""", 1) {
      bindLong(1, id)
    }

    public override fun toString(): String = "OpenChatsDatabase.sq:selectById"
  }
}

private class RosterDatabaseQueriesImpl(
  private val database: OfficialTeaDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), RosterDatabaseQueries {
  internal val selectAllFromAccount: MutableList<Query<*>> = copyOnWriteList()

  internal val loadAll: MutableList<Query<*>> = copyOnWriteList()

  internal val getItem: MutableList<Query<*>> = copyOnWriteList()

  internal val getVersion: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectAllFromAccount(account: BareJID, mapper: (
    id: Long,
    account: BareJID,
    jid: BareJID,
    name: String?,
    ask: Boolean,
    subscription: Subscription?,
    last_status: Long?,
    mixParticipantId: String?
  ) -> T): Query<T> = SelectAllFromAccountQuery(account) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.RosterAdapter.accountAdapter.decode(cursor.getString(1)!!),
      database.RosterAdapter.jidAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3),
      cursor.getLong(4)!! == 1L,
      cursor.getString(5)?.let { database.RosterAdapter.subscriptionAdapter.decode(it) },
      cursor.getLong(6),
      cursor.getString(7)
    )
  }

  public override fun selectAllFromAccount(account: BareJID): Query<Roster> =
      selectAllFromAccount(account) { id, account_, jid, name, ask, subscription, last_status,
      mixParticipantId ->
    Roster(
      id,
      account_,
      jid,
      name,
      ask,
      subscription,
      last_status,
      mixParticipantId
    )
  }

  public override fun <T : Any> loadAll(mapper: (
    id: Long,
    account: BareJID,
    jid: BareJID,
    name: String?,
    ask: Boolean,
    subscription: Subscription?,
    last_status: Long?,
    mixParticipantId: String?
  ) -> T): Query<T> = Query(-1070723873, loadAll, driver, "RosterDatabase.sq", "loadAll",
      "SELECT * FROM Roster WHERE mixParticipantId ISNULL") { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.RosterAdapter.accountAdapter.decode(cursor.getString(1)!!),
      database.RosterAdapter.jidAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3),
      cursor.getLong(4)!! == 1L,
      cursor.getString(5)?.let { database.RosterAdapter.subscriptionAdapter.decode(it) },
      cursor.getLong(6),
      cursor.getString(7)
    )
  }

  public override fun loadAll(): Query<Roster> = loadAll { id, account, jid, name, ask,
      subscription, last_status, mixParticipantId ->
    Roster(
      id,
      account,
      jid,
      name,
      ask,
      subscription,
      last_status,
      mixParticipantId
    )
  }

  public override fun <T : Any> getItem(
    account: BareJID,
    jid: BareJID,
    mapper: (
      id: Long,
      account: BareJID,
      jid: BareJID,
      name: String?,
      ask: Boolean,
      subscription: Subscription?,
      last_status: Long?,
      mixParticipantId: String?
    ) -> T
  ): Query<T> = GetItemQuery(account, jid) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.RosterAdapter.accountAdapter.decode(cursor.getString(1)!!),
      database.RosterAdapter.jidAdapter.decode(cursor.getString(2)!!),
      cursor.getString(3),
      cursor.getLong(4)!! == 1L,
      cursor.getString(5)?.let { database.RosterAdapter.subscriptionAdapter.decode(it) },
      cursor.getLong(6),
      cursor.getString(7)
    )
  }

  public override fun getItem(account: BareJID, jid: BareJID): Query<Roster> = getItem(account,
      jid) { id, account_, jid_, name, ask, subscription, last_status, mixParticipantId ->
    Roster(
      id,
      account_,
      jid_,
      name,
      ask,
      subscription,
      last_status,
      mixParticipantId
    )
  }

  public override fun <T : Any> getVersion(userjid: BareJID,
      mapper: (roster_version: String?) -> T): Query<T> = GetVersionQuery(userjid) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public override fun getVersion(userjid: BareJID): Query<GetVersion> = getVersion(userjid) {
      roster_version ->
    GetVersion(
      roster_version
    )
  }

  public override fun add(
    account: BareJID,
    jid: BareJID,
    name: String?,
    ask: Boolean,
    subscription: Subscription?,
    last_status: Long?,
    mixParticipantId: String?
  ): Unit {
    driver.execute(507042981, """
    |INSERT INTO Roster (account, jid, name, ask, subscription, last_status, mixParticipantId)
    |VALUES (?,?,?,?,?,?,?)
    """.trimMargin(), 7) {
      bindString(1, database.RosterAdapter.accountAdapter.encode(account))
      bindString(2, database.RosterAdapter.jidAdapter.encode(jid))
      bindString(3, name)
      bindLong(4, if (ask) 1L else 0L)
      bindString(5, subscription?.let { database.RosterAdapter.subscriptionAdapter.encode(it) })
      bindLong(6, last_status)
      bindString(7, mixParticipantId)
    }
    notifyQueries(507042981, {database.rosterDatabaseQueries.selectAllFromAccount +
        database.openChatsDatabaseQueries.selectWithUnread +
        database.rosterDatabaseQueries.getItem + database.rosterDatabaseQueries.loadAll})
  }

  public override fun update(
    name: String?,
    ask: Boolean,
    subscription: Subscription?,
    last_status: Long?,
    account: BareJID,
    jid: BareJID
  ): Unit {
    driver.execute(501229125,
        """UPDATE Roster SET name=?, ask=?, subscription=?, last_status=? WHERE account=? AND jid=?""",
        6) {
      bindString(1, name)
      bindLong(2, if (ask) 1L else 0L)
      bindString(3, subscription?.let { database.RosterAdapter.subscriptionAdapter.encode(it) })
      bindLong(4, last_status)
      bindString(5, database.RosterAdapter.accountAdapter.encode(account))
      bindString(6, database.RosterAdapter.jidAdapter.encode(jid))
    }
    notifyQueries(501229125, {database.rosterDatabaseQueries.selectAllFromAccount +
        database.openChatsDatabaseQueries.selectWithUnread +
        database.rosterDatabaseQueries.getItem + database.rosterDatabaseQueries.loadAll})
  }

  public override fun removeItem(account: BareJID, jid: BareJID): Unit {
    driver.execute(-1670720205, """DELETE FROM Roster WHERE account=? AND jid=?""", 2) {
      bindString(1, database.RosterAdapter.accountAdapter.encode(account))
      bindString(2, database.RosterAdapter.jidAdapter.encode(jid))
    }
    notifyQueries(-1670720205, {database.rosterDatabaseQueries.selectAllFromAccount +
        database.openChatsDatabaseQueries.selectWithUnread +
        database.rosterDatabaseQueries.getItem + database.rosterDatabaseQueries.loadAll})
  }

  public override fun setVersion(roster_version: String?, userjid: BareJID): Unit {
    driver.execute(556299666, """UPDATE Accounts SET roster_version=? WHERE userjid=?""", 2) {
      bindString(1, roster_version)
      bindString(2, database.AccountsAdapter.userjidAdapter.encode(userjid))
    }
    notifyQueries(556299666, {database.rosterDatabaseQueries.getVersion +
        database.accountsDatabaseQueries.selectAll + database.accountsDatabaseQueries.selectById +
        database.accountsDatabaseQueries.selectByName})
  }

  private inner class SelectAllFromAccountQuery<out T : Any>(
    public val account: BareJID,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectAllFromAccount, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-2097953958,
        """SELECT * FROM Roster WHERE account=? AND mixParticipantId ISNULL""", 1) {
      bindString(1, database.RosterAdapter.accountAdapter.encode(account))
    }

    public override fun toString(): String = "RosterDatabase.sq:selectAllFromAccount"
  }

  private inner class GetItemQuery<out T : Any>(
    public val account: BareJID,
    public val jid: BareJID,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getItem, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1482775155,
        """SELECT * FROM Roster WHERE account=? AND jid=?""", 2) {
      bindString(1, database.RosterAdapter.accountAdapter.encode(account))
      bindString(2, database.RosterAdapter.jidAdapter.encode(jid))
    }

    public override fun toString(): String = "RosterDatabase.sq:getItem"
  }

  private inner class GetVersionQuery<out T : Any>(
    public val userjid: BareJID,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getVersion, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1380505570,
        """SELECT ac.roster_version FROM Accounts ac WHERE ac.userjid=?""", 1) {
      bindString(1, database.AccountsAdapter.userjidAdapter.encode(userjid))
    }

    public override fun toString(): String = "RosterDatabase.sq:getVersion"
  }
}
