import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.jetbrains.subscribeAsState
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.*
import org.tigase.officialtea.common.main.components.messageinput.MessageInput
import org.tigase.officialtea.common.services.FileToUpload
import org.w3c.files.get

@Composable
fun InputPanel(component: MessageInput) {
	val state by component.models.subscribeAsState()
	Div(attrs = { classes("input-panel") }) {
		Div(attrs = {
			classes("textInput", "body-large", "clearfix", "dark:border", "dark:border-[#fff]", "mb-0")
		}) {
//			FileInput(attrs = {
////				this.value(state.file?.toString()?:"")
//				onInput {
//					val file=it.target.files?.get(0)!!
//					component.onFileChanged(FileToUpload(file))
//				}
//			})

			Input(type = InputType.Text, attrs = {
				placeholder(state.placeholder)
				this.value(state.text)
				onInput { event ->
					component.onTextChanged(event.value)
				}
				onKeyDown {
					if (it.getNormalizedKey() == "Enter") {
						component.onSendClick()
					}
				}
			})
		}
		Button(attrs = {
			classes(
				"bg-[#324167]",
				"hover:bg-sky-700",
				"px-5",
				"py-2",
				"h-[40px]",
				"w-[100px]",
				"rounded-full",
				"font-[500]",
				"text-white",
				"text-[15px]"
			)
			onClick {
				component.onSendClick()
			}
		}) {
			Text("Send")
		}
	}
}