package org.tigase.officialtea.common.main.components.roster

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.map
import com.badoo.reaktive.single.observeOn
import com.badoo.reaktive.single.subscribe
import com.badoo.reaktive.single.subscribeOn
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.asValue
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.presence.PresenceModule
import tigase.halcyon.core.xmpp.modules.roster.RosterItem
import tigase.halcyon.core.xmpp.modules.roster.RosterModule
import tigase.halcyon.core.xmpp.stanzas.PresenceType
import tigase.halcyon.core.xmpp.toBareJID
import tigase.halcyon.rx.asSingle

interface RosterItemEditor {
    fun OnJIDUpdate(value: String)
    fun OnNameUpdate(value: String)
    fun OnAskForSubscriptionUpdate(value: Boolean)
    fun OnAllowForSubscriptionUpdate(value: Boolean)
    fun OnAccountChange(value: String)
    fun OnSavePress()
    fun OnCancelPress()

    val state: Value<State>

    data class State(
        val accountsList: List<BareJID> = emptyList(),
        val account: BareJID? = null,
        val jid: String = "",
        val jidError: Boolean = false,
        val name: String = "",
        val askForSubscription: Boolean = true,
        val allowForSubscription: Boolean = true,
    )

}

class RosterItemEditorComponent(
    componentContext: ComponentContext,
    storeFactory: StoreFactory,
    private val serviceKeeper: ServiceKeeper,
    private val onDone: () -> Unit
) : RosterItemEditor, ComponentContext by componentContext {

    private val store = instanceKeeper.getStore {
        RosterItemEditorStoreFactory(storeFactory = storeFactory, serviceKeeper = serviceKeeper).create()
    }

    override val state: Value<RosterItemEditor.State> = store.asValue()


    override fun OnJIDUpdate(value: String) {
        store.accept(RosterItemEditorStore.Intent.UpdateJID(value))
    }

    override fun OnNameUpdate(value: String) {
        store.accept(RosterItemEditorStore.Intent.UpdateName(value))
    }

    override fun OnAskForSubscriptionUpdate(value: Boolean) {
        store.accept(RosterItemEditorStore.Intent.UpdateAskForSubscription(value))
    }

    override fun OnAllowForSubscriptionUpdate(value: Boolean) {
        store.accept(RosterItemEditorStore.Intent.UpdateAllowForSubscription(value))
    }

    override fun OnAccountChange(value: String) {
        store.accept(RosterItemEditorStore.Intent.UpdateAccount(value))
    }

    override fun OnSavePress() {
        val state = store.state
        if (state.jidError || state.account == null) return

        val rosterItem = RosterItem(jid = state.jid.toBareJID(), name = state.name.ifBlank { null })
        val halcyon = serviceKeeper.connectionService[state.account]!!

        halcyon.getModule(RosterModule).addItem(rosterItem).asSingle()
            .map {
                halcyon.getModule(PresenceModule).sendSubscriptionSet(state.jid.toBareJID(), PresenceType.Subscribe)
                    .asSingle()
            }
            .map {
                halcyon.getModule(PresenceModule).sendSubscriptionSet(state.jid.toBareJID(), PresenceType.Subscribed)
                    .asSingle()
            }
            .subscribeOn(mainScheduler)
            .observeOn(mainScheduler)
            .subscribe {
                onDone()
            }
    }

    override fun OnCancelPress() {
        onDone()
    }

}