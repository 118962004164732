package org.tigase.officialtea.common.main.components.chat.chatstate

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.observable.filter
import com.badoo.reaktive.observable.map
import com.badoo.reaktive.observable.observeOn
import com.badoo.reaktive.observable.threadLocal
import com.badoo.reaktive.scheduler.mainScheduler
import kotlinx.datetime.Clock
import org.tigase.officialtea.common.main.components.chat.ChatData
import org.tigase.officialtea.common.main.components.chat.chatstate.ChatStateComponent.Model
import org.tigase.officialtea.common.main.components.chat.chatstate.ChatStateStore.Intent
import org.tigase.officialtea.common.main.components.chat.chatstate.ChatStateStore.Label
import org.tigase.officialtea.common.services.ChatsService
import org.tigase.officialtea.common.services.ConnectionService
import tigase.halcyon.core.xmpp.JID
import tigase.halcyon.core.xmpp.bareJID
import tigase.halcyon.core.xmpp.modules.chatstates.ChatState
import tigase.halcyon.core.xmpp.modules.chatstates.ChatStateEvent
import tigase.halcyon.rx.observe

internal interface ChatStateStore : Store<Intent, Model, Label> {

	sealed interface Intent

	sealed interface Label
}

internal class ChatStateStoreProvider(
	private val storeFactory: StoreFactory,
	private val service: ConnectionService,
	private val chatsService: ChatsService,
	private val chatData: ChatData,
) {

	fun provide(): ChatStateStore = object : ChatStateStore, Store<Intent, Model, Label> by storeFactory.create(
		name = "ChatStateStore", initialState = Model(
			emptyList()
		), bootstrapper = SimpleBootstrapper(
			Unit
		), executorFactory = ::ExecutorImpl, reducer = ReducerImpl
	) {

	}

	private sealed interface Result {

		data class ChatStates(val list: List<ChatStateComponent.Item>) : Result

	}

	private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, Model, Result, Label>() {

		override fun executeAction(action: Unit, getState: () -> Model) {
			service.eventBus.observe<ChatStateEvent>(ChatStateEvent.TYPE)
				.filter { it.jid == chatData.jid }
				.map { Result.ChatStates(updateList(it.jid, it.state, getState())) }
				.observeOn(mainScheduler)
				.threadLocal()
				.subscribeScoped(onNext = ::dispatch)
		}

		private fun updateList(jid: JID, chatState: ChatState, model: Model): List<ChatStateComponent.Item> {
			val x = model.items.filter { it.jid != jid.bareJID }
				.toMutableList()

			x.add(ChatStateComponent.Item(jid.bareJID, chatData.title, Clock.System.now(), chatState))
			return x
		}

	}

	private object ReducerImpl : Reducer<Model, Result> {

		override fun Model.reduce(result: Result): Model = when (result) {
			is Result.ChatStates -> copy(items = result.list)
		}
	}
}
