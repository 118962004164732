package org.tigase.officialtea.common.services

import co.touchlab.kermit.Logger
import com.badoo.reaktive.subject.Subject
import com.badoo.reaktive.subject.behavior.BehaviorSubject

object FocusService {

	private val log = Logger.withTag("FocusService")

	private val appFocusedSubject = BehaviorSubject<Boolean>(false)

	private val activeChatSubject = BehaviorSubject<Long?>(null)

	val focused: Boolean
		get() = appFocusedSubject.value

	val visibleChatId: Long?
		get() = activeChatSubject.value

	fun observe(): Subject<Boolean> = appFocusedSubject

	fun observeActiveChat(): Subject<Long?> = activeChatSubject

	fun setActive(active: Boolean) {
		log.i { if (active) "Window focused" else "Window not focused" }
		appFocusedSubject.onNext(active)
	}

	fun setActiveChat(chatIt: Long?) {
		log.d { "Active chat is $chatIt" }
		activeChatSubject.onNext(chatIt)
	}

}