import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.jetbrains.subscribeAsState
import components.ChatMenuItem
import org.tigase.officialtea.common.main.components.chat.mixparticipants.MixParticipants

@Composable
fun MixParticipantsContent(component: MixParticipants) {
    val model by component.models.subscribeAsState()
    for (item in model.participants) {
        ChatMenuItem(
            active = false,
            itemCaption = item.nick,
            jid = item.jid,
            name = item.nick,
            unreadCount = 0
        )
    }
}