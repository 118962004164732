package org.tigase.officialtea.common.main.components.vcard

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.scheduler.ioScheduler
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.flatMap
import com.badoo.reaktive.single.observeOn
import com.badoo.reaktive.single.subscribeOn
import korlibs.crypto.sha1
import org.tigase.officialtea.common.main.components.vcard.VCardPanel.State
import org.tigase.officialtea.common.main.components.vcard.VCardPanelStore.Intent
import org.tigase.officialtea.common.main.components.vcard.VCardPanelStore.Label
import org.tigase.officialtea.common.main.components.vcard.VCardPanelStoreProvider.Result.VCardLoaded
import org.tigase.officialtea.common.services.AvatarsCacheService
import org.tigase.officialtea.common.services.ServiceKeeper
import tigase.halcyon.core.Base64
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.avatar.UserAvatarModule
import tigase.halcyon.core.xmpp.modules.vcard.Photo
import tigase.halcyon.core.xmpp.modules.vcard.VCard
import tigase.halcyon.core.xmpp.modules.vcard.VCardModule
import tigase.halcyon.rx.asSingle

internal interface VCardPanelStore : Store<Intent, State, Label> {

    sealed interface Intent

    sealed interface Label
}

internal class VCardPanelStoreProvider(
    private val account: BareJID,
    private val jid: BareJID,
    private val storeFactory: StoreFactory,
    private val keeper: ServiceKeeper,
) {

    fun provide(): VCardPanelStore = object : VCardPanelStore, Store<Intent, State, Label> by storeFactory.create(
        name = "VCardPanelStore", initialState = State(
            jid = jid, name = jid.localpart!!
        ), bootstrapper = SimpleBootstrapper(
            Unit
        ), executorFactory = ::ExecutorImpl, reducer = ReducerImpl
    ) {}

    private sealed interface Result {

        data class VCardLoaded(val vcard: VCard) : Result
        data class ContactNameUpdated(val name: String) : Result
    }

    private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, State, Result, Label>() {

		fun updateAvatarCache(vcard: VCard) {
			vcard.photos.filterIsInstance<Photo.PhotoData>()
				.filter {
					!it.data.isNullOrBlank()
				}
				.map {
					Pair(
						Base64.decodeToByteArray(it.data!!)
							.sha1().hex, it
					)
				}
				.forEach { (id: String, vcard: Photo.PhotoData) ->
					AvatarsCacheService.store(
						userJID = jid, avatarID = id, data = UserAvatarModule.Avatar(
							info = UserAvatarModule.AvatarInfo(
								bytes = Base64.decodeToByteArray(
									vcard.data!!
								).size, id = id, type = vcard.imageType ?: "", height = null, url = null, width = null
							), data = UserAvatarModule.AvatarData(
								id, vcard.data!!
							)
						)
					)
				}
		}


		override fun executeAction(action: Unit, getState: () -> State) {
			keeper.connectionService.halcyon(account)
				.flatMap {
					it.getModule<VCardModule>(VCardModule.TYPE)
						.retrieveVCard(jid)
						.asSingle()
				}
				.subscribeOn(ioScheduler)
				.observeOn(mainScheduler)
				.subscribeScoped(onSuccess = {
					updateAvatarCache(it)
					processContactName(it)
					dispatch(VCardLoaded(it))
				}, onError = {})
		}

		private fun processContactName(it: VCard) {
			if (it.formattedName != null) {
				dispatch(Result.ContactNameUpdated(it.formattedName!!))
			} else if (it.structuredName != null) {
				dispatch(Result.ContactNameUpdated("${it.structuredName!!.surname} ${it.structuredName!!.given}"))
			}
		}
	}

	private object ReducerImpl : Reducer<State, Result> {

		override fun State.reduce(msg: Result): State = when (msg) {
			is VCardLoaded -> copy(vCard = msg.vcard)
			is Result.ContactNameUpdated -> copy(name = msg.name)
		}
	}
}


fun ByteArray.toHex(): String {
	val hexChars = "0123456789ABCDEF"
	val result = StringBuilder(this.size * 2)
	for (byte in this) {
		val i = byte.toInt()
		result.append(hexChars[i shr 4 and 0x0F])
		result.append(hexChars[i and 0x0F])
	}
	return result.toString()
}

