package components

import Avatar
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLElement
import tigase.halcyon.core.xmpp.BareJID

@Composable
fun ChatMenuItem(
	active: Boolean = false,
	itemCaption: String,
	jid: BareJID,
	name: String,
	unreadCount: Long = 0L,
	onClickCallback: (() -> Unit)? = null,
) {
		MenuItem( active = active, icon = { Avatar(jid, name) }, onClickCallback = onClickCallback, content = {
			Div(attrs = {
				classes("name")
				style {
					if (unreadCount > 0) fontWeight("bolder")
				}
			}) {
				Text(itemCaption)
			}
			if (unreadCount > 0) Div(attrs = { classes("badge") }) { Text("$unreadCount") }
		})
}

@Composable
fun IconMenuItem(
	active: Boolean = false,
	itemCaption: String,
	iconName: String,
	onClickCallback: (() -> Unit)? = null,
) {
	MenuItem(active = active,
			 onClickCallback = onClickCallback,
			 icon = { Span({ classes("material-icons-outlined") }) { Text(iconName) } },
			 content = { Text(itemCaption) })
}

@Composable
fun MenuItem(
	active: Boolean = false,
	icon: ContentBuilder<HTMLElement>,
	onClickCallback: (() -> Unit)? = null,
	content: ContentBuilder<HTMLElement>,
) {
	MenuItem(active = active, onClickCallback = onClickCallback) {
		Div({ classes("listItem-stateLayer") }) {
			Div({ classes("otw-avatar") }) {
				icon(this)
			}
			Div({ classes("label-large", "roster-label", "items-center", "dark:text-[#fff]") }) {
				content(this)
			}
		}
	}
}

@Composable
fun MenuItem(
	active: Boolean = false,
	onClickCallback: (() -> Unit)? = null,
	content: ContentBuilder<HTMLElement>,
) {
	Div({
			classes(listOf("listItem") + if (active) listOf("listItemActive") else emptyList())
			tabIndex(0)
			onClickCallback?.let { callback ->
				onClick { callback() }
			}
		}) {
		content(this)
	}
}

