import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import com.arkivanov.decompose.extensions.compose.jetbrains.subscribeAsState
import com.arkivanov.decompose.value.operator.map
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.tigase.officialtea.common.database.MessageState
import org.tigase.officialtea.common.i18n.AppMessages
import org.tigase.officialtea.common.main.components.chat.ChatPanel
import org.tigase.officialtea.common.main.components.chat.ChatView
import org.tigase.officialtea.common.main.components.chat.chatstate.ChatStateComponent
import org.tigase.officialtea.common.main.components.chat.messagelist.MessageList
import org.w3c.dom.ScrollToOptions

@Composable
fun ToggleIcon(checked: Boolean, iconName: String, contentDescription: String, onbCheckedChange: (Boolean) -> Unit) {
    Button(attrs = {
        classes("round")
        onClick { onbCheckedChange(!checked) }
    }) {
        Span({ classes("material-icons-outlined", "text-[#324167]") }) { Text(iconName) }
    }
}

@Composable
fun ChatPanelUI(component: ChatPanel) {
    val stack by component.routerState.subscribeAsState()
    val state by component.models.subscribeAsState()
    val features by component.features.subscribeAsState()
    val activePage by component.routerState.map { it.active.configuration }
        .subscribeAsState()

    Div(attrs = { classes("otw-top-app-bar") }) {
        Div(attrs = { classes("otw-avatar") }) {
            Avatar(state.jid, state.title)
        }
        Div(attrs = { classes("title") }) {
            Text(state.title)
        }

        if (features.contains(ChatPanel.Feature.VCard)) ToggleIcon(
            activePage == ChatPanel.Configuration.ContactVCard, "account_circle", "View VCard"
        ) { component.onPressedVCard() }
        if (features.contains(ChatPanel.Feature.MIXParticipants)) ToggleIcon(
            activePage == ChatPanel.Configuration.ChannelParticipants, "people", "View participants"
        ) { component.onPressedParticipants() }
    }

    when (val instance = stack.active.instance) {
        is ChatPanel.Child.ChannelParticipants -> MixParticipantsContent(instance.component)
        is ChatPanel.Child.Chat -> ConversationContent(instance.component)
        is ChatPanel.Child.ContactVCard -> VCardContent(instance.component)
    }

}

@Composable
fun ConversationContent(component: ChatView) {
    val messageListModel by component.messageList.models.subscribeAsState()

    var pos = remember { 0.0 }

    Div(attrs = { classes("otw-chat") }) {
        Div(attrs = { classes("otw-chat-header") }) { }

        Div(attrs = {
            classes("otw-messages-room")
        }) {
            messageListModel.compiledRows.asReversed().forEach { item ->
                when (item) {
                    is MessageList.DisplayRow.Message -> MessageItem(item, messageListModel.title)
                    is MessageList.DisplayRow.DateSeparator -> DateDivider(item.date)
                    is MessageList.DisplayRow.Mark -> chatMarkerComponent(item.marks)
                }
            }
            Div(attrs = { classes("vert-space") }) { }
            DisposableEffect(messageListModel) {
                this.scopeElement.scrollTo(ScrollToOptions(left = 0.0, top = this.scopeElement.scrollHeight.toDouble()))
                onDispose { }
            }
        }

        Div(attrs = {
            classes("otw-bottom-input")
        }) {
            InputPanel(component.messageInput)
            ChatState(component.chatState)
        }

    }
}

@Composable
fun ChatState(chatState: ChatStateComponent) {
    val model by chatState.models.subscribeAsState()
    Div(attrs = {
        classes("chat-states")
    }) {
        Text(" ")
        for (item in model.items) {
            when (item.type) {
                tigase.halcyon.core.xmpp.modules.chatstates.ChatState.Composing -> AppMessages.chatState_composing(item.nickname)
//				ChatState.Active -> AppMessages.chatState_active(item.nickname)
//				ChatState.Inactive -> AppMessages.chatState_inactive(item.nickname)
                tigase.halcyon.core.xmpp.modules.chatstates.ChatState.Paused -> AppMessages.chatState_paused(item.nickname)
                tigase.halcyon.core.xmpp.modules.chatstates.ChatState.Gone -> AppMessages.chatState_gone(item.nickname)
                else -> null
            }?.let {
                Div { Text(it) }
            }
        }
    }
}

@Composable
fun chatMarkerComponent(marks: List<MessageList.Mark>) {
    Div(attrs = { classes("chat-markers") }) {
        for ((markType, m) in marks.groupBy { it.type }) {
            Div {
                Div(attrs = { classes("avatars") }) {
                    for (mark in m) {
                        Div(attrs = { classes("otw-avatar") }) {
                            Avatar(mark.jid, mark.nickname)
                        }
                    }
                }
                Div(attrs = { classes("description") }) {
                    Text(markType.name)
                }
            }
        }
    }
}

@Composable
fun DateDivider(date: Instant) {
    Div(attrs = { classes("date-divider") }) {
        Span {
            Text(date.formatDate())
        }
    }
}

@Composable
fun MessageItem(
    message: MessageList.DisplayRow.Message, hisName: String, mineName: String = "Me",
) {
    val nickname = message.message.let { message ->
        if (!message.author_nickname.isNullOrBlank()) message.author_nickname!!
        else if (message.state.outgoing) mineName else hisName
    }


    Div(attrs = { classes("otw-message") }) {
        if (!message.mergeWithPrevious) {
            Div(attrs = { classes("otw-avatar") }) {
                Avatar(message.message.author_jid, nickname)
            }
            Div(attrs = { classes("otw-message-header") }) {
                Div(attrs = { classes("otw-message-username", "body-medium") }) { Text(nickname) }
                Div(attrs = {
                    classes("otw-message-timestamp", "body-small")
                }) { Text(message.message.timestamp.formatTime()) }
            }
        }

        Div(attrs = { classes("otw-message-line") }) {
            Div(attrs = { classes("otw-message-body", "body-large") }) {
                if (message.message.attachment_url != null) {
                    Attachment(message.message)
                } else {
                    Text(message.message.body ?: " ")
                }
            }
            Div(attrs = { classes("otw-message-read-icon") }) {
                when (message.message.state) {
                    MessageState.OutNotSent -> Span(attrs = { classes("material-icons-outlined") }) { Text("schedule") }
                    MessageState.OutSent -> Span(attrs = { classes("material-icons-outlined") }) { Text("done") }
                    MessageState.OutDelivered -> Span(attrs = { classes("material-icons-outlined") }) { Text("check_circle") }
                    MessageState.OutRead -> Span(attrs = { classes("material-icons") }) { Text("check_circle") }
                    else -> {}
                }
            }
        }
    }
}

@Composable
fun MessageItemContentFull(message: MessageList.DisplayRow.Message, nickname: String) {
    Div(attrs = { classes("otw-message") }) {
        Div(attrs = { classes("otw-message-right") }) {
            Div(attrs = { classes("otw-avatar") }) {
                Avatar(message.message.author_jid, nickname)
            }
            Div(attrs = { classes("otw-user-message") }) {
                Div(attrs = { classes("otw-user-name", "body-medium") }) {
                    Text(nickname)
                }
                MessageBody(message)
            }

        }
        Div(attrs = { classes("otw-message-left") }) {
            Div(attrs = { classes("otw-message-date", "body-small") }) {
                Text(message.message.timestamp.formatTime())
            }
        }
    }
}


@Composable
fun MessageItemContentSimple(message: MessageList.DisplayRow.Message) {
    Div(attrs = { classes("otw-message-simple") }) {
        MessageBody(message)
    }
}

@Composable
fun MessageBody(message: MessageList.DisplayRow.Message) {
    Div(attrs = { classes("otw-message-line") }) {
        Div(attrs = { classes("otw-message-body", "body-large") }) {
            Text(message.message.body ?: " ")
        }
        Div(attrs = { classes("otw-message-read-icon") }) {
            when (message.message.state) {
                MessageState.OutNotSent -> Span(attrs = { classes("material-icons-outlined") }) { Text("schedule") }
                MessageState.OutSent -> Span(attrs = { classes("material-icons-outlined") }) { Text("done") }
                MessageState.OutDelivered -> Span(attrs = { classes("material-icons-outlined") }) { Text("check_circle") }
                MessageState.OutRead -> Span(attrs = { classes("material-icons") }) { Text("check_circle") }
                else -> {}
            }
        }
    }
}

fun Instant?.formatDate(): String {
    if (this == null) return ""
    val timestamp = this.toLocalDateTime(TimeZone.currentSystemDefault())
    return buildString {
        append(
            timestamp.dayOfMonth.toString().padStart(2, '0')
        )
        append('-')
        append(
            timestamp.monthNumber.toString().padStart(2, '0')
        )
        append('-')
        append(
            timestamp.year.toString().padStart(2, '0')
        )
    }
}

fun Instant?.formatTime(): String {
    if (this == null) return ""
    val timestamp = this.toLocalDateTime(TimeZone.currentSystemDefault())
    return buildString {
        append(
            timestamp.hour.toString().padStart(2, '0')
        )
        append(':')
        append(
            timestamp.minute.toString().padStart(2, '0')
        )
        append(':')
        append(
            timestamp.second.toString().padStart(2, '0')
        )
    }
}