package org.tigase.officialtea.common.services

import com.badoo.reaktive.observable.Observable
import tigase.halcyon.core.xmpp.BareJID

interface RosterPresenceService {

	fun observeAll(): Observable<List<RosterPresenceItem>>

}

enum class ContactStatus {

	Offline,
	Error,
	Dnd,
	Xa,
	Away,
	Online,
	Chat,
}

data class RosterPresenceItem(val account: BareJID, val jid: BareJID, val name: String, val status: ContactStatus)
