package org.tigase.officialtea.common.main.settings.accountslist

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.labels
import com.badoo.reaktive.base.Consumer
import com.badoo.reaktive.base.invoke
import com.badoo.reaktive.observable.subscribe
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.asValue

class AccountEditComponent(
	componentContext: ComponentContext,
	storeFactory: StoreFactory,
	private val serviceKeeper: ServiceKeeper,
	private val accountIdToLoad: Long?,
	private val output: Consumer<Output>,
) : AccountEdit, ComponentContext by componentContext {

	private val store = instanceKeeper.getStore {
		AccountEditStoreProvider(
			storeFactory = storeFactory, serviceKeeper = serviceKeeper, accountIdToLoad = accountIdToLoad
		).provide()
	}

	override val models: Value<AccountEdit.Model> = store.asValue()

	init {
		store.labels.subscribe(onNext = {
			output(Output.Done)
		})
	}

	override fun onUserJidChange(value: String) = store.accept(AccountEditStore.Intent.SetUserJid(value))

	override fun onPasswordChange(value: String) = store.accept(AccountEditStore.Intent.SetUserPassword(value))

	override fun onHostnameChange(value: String) = store.accept(AccountEditStore.Intent.SetHostname(value))

	override fun onResourceChange(value: String) = store.accept(AccountEditStore.Intent.SetResource(value))

	override fun onNicknameChange(value: String) = store.accept(AccountEditStore.Intent.SetNickname(value))

	override fun onSavePress() = store.accept(AccountEditStore.Intent.Save)

	override fun onDeletePress() = store.accept(AccountEditStore.Intent.Delete)

	override fun onPushEnabledChange(value: Boolean) = store.accept(AccountEditStore.Intent.SetPushEnabled(value))

	override fun onBackPress() {
		output(Output.Done)
	}

	sealed interface Output {

		object Done : Output

	}

}