package org.tigase.officialtea.common.main.settings.accountslist

import com.arkivanov.decompose.value.Value

interface AccountEdit {

	val models: Value<Model>

	fun onUserJidChange(value: String)
	fun onPasswordChange(value: String)
	fun onHostnameChange(value: String)
	fun onResourceChange(value: String)
	fun onNicknameChange(value: String)
	fun onPushEnabledChange(value: Boolean)

	fun onSavePress()
	fun onBackPress()
	fun onDeletePress()

	data class Model(
		val id: Long?,
		val userJid: String = "",
		val userJidInvalid: Boolean = false,
		val password: String = "",
		val passwordInvalid: Boolean = false,
		val hostname: String = "",
		val resource: String = "",
		val nickname: String = "",
		val pushEnabled: Boolean = false,
	)

}