import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.jetbrains.subscribeAsState
import components.IconMenuItem
import org.tigase.officialtea.common.main.settings.Options
import org.tigase.officialtea.common.main.settings.OptionsComponent
import org.tigase.officialtea.common.main.settings.Settings
import org.tigase.officialtea.common.main.settings.SettingsComponent

@Composable
fun SettingsContent(component: SettingsComponent) {
    val stack by component.routerState.subscribeAsState()
    when (val instance = stack.active.instance) {
        is Settings.Child.Accounts -> AccountsContent(instance.component)
        is Settings.Child.AppPreferences -> WIP()
        is Settings.Child.Appearance -> WIP()
        is Settings.Child.Menu -> SettingsMenu(component.options)
    }
}

@Composable
fun SettingsMenu(component: OptionsComponent) {
    val model by component.models.subscribeAsState()
    model.items.forEach { item ->
        SettingsMenuItem(item = item, selected = model.selected, onClickCallback = { component.select(item) })
    }
}

@Composable
fun SettingsMenuItem(item: Options.MenuItem, selected: Options.MenuItem, onClickCallback: (() -> Unit)? = null) {
    IconMenuItem(
        active = item == selected, iconName = when (item.icon) {
            "preferences" -> "settings"
            "settings_accounts" -> "manage_accounts"
            "palette" -> "palette"
            else -> "check_box_outline_blank"
        }, onClickCallback = onClickCallback, itemCaption = item.name
    )
}