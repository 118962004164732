import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.jetbrains.subscribeAsState
import components.*
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.tigase.officialtea.common.i18n.AppMessages
import org.tigase.officialtea.common.main.components.roster.Roster
import org.tigase.officialtea.common.main.components.roster.RosterItemEditor
import org.tigase.officialtea.common.main.components.roster.RosterMain
import org.tigase.officialtea.common.services.RosterPresenceItem

@Composable
fun RosterMainUI(component: RosterMain) {
    val stack by component.childStack.subscribeAsState()
    when (val instance = stack.active.instance) {
        is RosterMain.Child.EditorChild -> RosterItemEditUI(instance.component)
        is RosterMain.Child.ListChild -> RosterContent(instance.component, onAddClick = component::onClickAddItem)
    }
}

@Composable
fun RosterItemEditUI(component: RosterItemEditor) {
    val state by component.state.subscribeAsState()

    FormPanel {
        Div(attrs = {
            classes("textInput", "body-large", "clearfix")
        }) {
            //For some stupid  something in placeholder (for example " ")
            Select(attrs = {
                onChange { component.OnAccountChange(it.value!!) }
            }) {
                state.accountsList.forEach {
                    Option(attrs = {
                        if (state.account == it) selected()
                    }, value = it.toString()) {
                        Text(it.toString())
                    }
                }
            }
            Label {
                Text("Account")
            }
        }

        textInput(
            label = AppMessages.roster_edit_jid.toString(),
            value = state.jid,
            onValueChange = component::OnJIDUpdate
        )

        textInput(
            label = AppMessages.roster_edit_name.toString(),
            value = state.name,
            onValueChange = component::OnNameUpdate
        )

        CheckBox(
            label = AppMessages.roster_edit_subscribe_ask.toString(),
            value = state.askForSubscription,
            onValueChange = component::OnAskForSubscriptionUpdate
        )

        CheckBox(
            label = AppMessages.roster_edit_subscribe_allow.toString(),
            value = state.allowForSubscription,
            onValueChange = component::OnAllowForSubscriptionUpdate
        )


        Div({
            style {
                display(DisplayStyle.Flex)
                justifyContent(JustifyContent.Center)
            }
        }) {

            Button({
                classes("pure-material-button-contained", "secondary")
                style { margin(4.px) }
                onClick { component.OnCancelPress() }
            }) { Text("Cancel") }

            Button({
                classes("pure-material-button-contained", "primary")
                style { margin(4.px) }
                onClick { component.OnSavePress() }
                if (state.jidError || state.account == null || state.jid.isBlank()) {
                    disabled()
                }
            }) {
                Text(AppMessages.settings_accounts_saveButton())

            }

        }


    }

}

@Composable
fun RosterContent(component: Roster, onAddClick: (() -> Unit)? = null) {
    val model by component.models.subscribeAsState()
    FloatingButton(iconName = "add", onClick = { onAddClick?.invoke() })
    for (item in model.items) {
        RosterItemView(
            item = item,
            isSelected = model.selected.contains(item),
            onClickCallback = { component.startChatWith(item) })
    }
}

@Composable
fun RosterItemView(item: RosterPresenceItem, isSelected: Boolean, onClickCallback: (() -> Unit)? = null) {
    ChatMenuItem(
        active = isSelected,
        itemCaption = item.displayName(),
        jid = item.jid,
        name = item.displayName(),
        unreadCount = 0,
        onClickCallback = onClickCallback
    )
}

fun RosterPresenceItem.displayName(): String = when {
    this.name.isNotBlank() -> this.name
    else -> this.jid.toString()
}
