package org.tigase.officialtea.common.root

//import org.tigase.officialtea.common.main.components.mixbrowser.MixBrowserComponent

import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import org.tigase.officialtea.common.main.components.NewChat.NewChat
import org.tigase.officialtea.common.main.components.accountStatus.AccountStatus
import org.tigase.officialtea.common.main.components.accounts.AccountsInt
import org.tigase.officialtea.common.main.components.chat.ChatPanel
import org.tigase.officialtea.common.main.components.mixbrowser.MixBrowserComponent
import org.tigase.officialtea.common.main.components.roster.RosterComponent
import org.tigase.officialtea.common.main.components.roster.RosterMain
import org.tigase.officialtea.common.main.components.sidemenu.SideMenu
import org.tigase.officialtea.common.main.components.support.SupportComponent
import org.tigase.officialtea.common.main.settings.SettingsComponent
import org.tigase.officialtea.common.services.AccountsService
import org.tigase.officialtea.common.services.ChatsService
import org.tigase.officialtea.common.services.RosterManipulationService
import org.tigase.officialtea.common.services.settings.ApplicationSettings

interface AppRoot {

    val routerState: Value<ChildStack<AppRootComponent.Configuration, Child>>

    val sideMenu: SideMenu

    val chatsService: ChatsService

    val settingsComponent: SettingsComponent

    val accountsComponent: AccountsInt

    val preferences: ApplicationSettings

    val accountsService: AccountsService

    val accountStatus: AccountStatus

    val newChat: NewChat

    fun toggleTheme()

    sealed class Child {
        object WelcomeScreen : Child()
        data class Settings(val component: SettingsComponent) : Child()
        data class Chat(val component: ChatPanel) : Child()
        data class RosterScreen(val component: RosterMain) : Child()
        data class MixChannelBrowser(val component: MixBrowserComponent) : Child()
        data class Support(val component: SupportComponent) : Child()
    }
}