package org.tigase.officialtea.common.database

import com.squareup.sqldelight.ColumnAdapter
import kotlin.ByteArray
import kotlin.Long
import kotlin.String
import kotlin.collections.contentToString
import tigase.halcyon.core.xmpp.BareJID

public data class AvatarsCache(
  public val id: Long,
  public val jid: BareJID,
  public val avatarId: String,
  public val bytes: Long?,
  public val width: Long?,
  public val height: Long?,
  public val type: String?,
  public val url: String?,
  public val avatarData: ByteArray
) {
  public override fun toString(): String = """
  |AvatarsCache [
  |  id: $id
  |  jid: $jid
  |  avatarId: $avatarId
  |  bytes: $bytes
  |  width: $width
  |  height: $height
  |  type: $type
  |  url: $url
  |  avatarData: ${avatarData.contentToString()}
  |]
  """.trimMargin()

  public class Adapter(
    public val jidAdapter: ColumnAdapter<BareJID, String>
  )
}
