package org.tigase.officialtea.common.main.components.chat.chatstate

import com.arkivanov.decompose.value.Value
import kotlinx.datetime.Instant
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.chatstates.ChatState

interface ChatStateComponent {

	val models: Value<Model>

	data class Item(val jid: BareJID, val nickname: String, val timestamp: Instant, val type: ChatState)

	data class Model(
		val items: List<Item>,
	)

}