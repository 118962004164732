package org.tigase.officialtea.common.main.components.chat.chatstate

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.Value
import com.arkivanov.mvikotlin.core.store.StoreFactory
import org.tigase.officialtea.common.main.components.chat.ChatData
import org.tigase.officialtea.common.services.ChatsService
import org.tigase.officialtea.common.services.ConnectionService
import org.tigase.officialtea.common.utils.asValue
import org.tigase.officialtea.common.utils.getStore

class ChatStateComponentImpl(
	componentContext: ComponentContext,
	storeFactory: StoreFactory,
	chatData: ChatData,
	connectionService: ConnectionService,
	chatsService: ChatsService,
) : ChatStateComponent, ComponentContext by componentContext {

	private val store = instanceKeeper.getStore {
		ChatStateStoreProvider(
			storeFactory = storeFactory, service = connectionService, chatData = chatData, chatsService = chatsService
		).provide()
	}

	override val models: Value<ChatStateComponent.Model> = store.asValue()

}