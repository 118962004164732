package org.tigase.officialtea.common.main.settings.accountslist

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.completable.observeOn
import com.badoo.reaktive.completable.subscribe
import com.badoo.reaktive.completable.threadLocal
import com.badoo.reaktive.maybe.*
import com.badoo.reaktive.scheduler.ioScheduler
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.flatMap
import com.badoo.reaktive.single.notNull
import com.badoo.reaktive.single.singleOf
import com.badoo.reaktive.single.toSingle
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.main.settings.accountslist.AccountEdit.Model
import org.tigase.officialtea.common.main.settings.accountslist.AccountEditStore.Intent
import org.tigase.officialtea.common.main.settings.accountslist.AccountEditStore.Label
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.services.halcyon.PushNotificationsModule
import tigase.halcyon.core.xmpp.toBareJID
import tigase.halcyon.rx.asSingle

internal interface AccountEditStore : Store<Intent, Model, Label> {

    sealed interface Intent {

        object Save : Intent
        object Delete : Intent
        data class SetUserJid(val value: String) : Intent
        data class SetUserPassword(val value: String) : Intent
        data class SetHostname(val value: String) : Intent
        data class SetResource(val value: String) : Intent
        data class SetNickname(val value: String) : Intent
        data class SetPushEnabled(val value: Boolean) : Intent
    }

    sealed interface Label {

        data class Removed(val jid: String) : Label
        data class Saved(val jid: String) : Label
    }
}

internal class AccountEditStoreProvider(
    private val storeFactory: StoreFactory,
    private val serviceKeeper: ServiceKeeper,
    private val accountIdToLoad: Long?,
) {

    private val service = serviceKeeper.accountsService

    fun provide(): AccountEditStore = object : AccountEditStore, Store<Intent, Model, Label> by storeFactory.create(
        name = "AccountEditStore", initialState = Model(
            null
        ), bootstrapper = SimpleBootstrapper(
            Unit
        ), executorFactory = ::ExecutorImpl, reducer = ReducerImpl
    ) {}

    private sealed interface Result {

        data class DataLoaded(val data: Accounts) : Result
        data class UserJidChanged(val value: String) : Result
        data class UserPasswordChanged(val value: String) : Result
        data class HostnameChanged(val value: String) : Result
        data class ResourceChanged(val value: String) : Result
        data class NicknameChanged(val value: String) : Result
        data class Validation(val jid: Boolean, val password: Boolean) : Result
        data class PushEnabledChanged(val value: Boolean) : Result
    }

    private inner class ExecutorImpl : ReaktiveExecutor<Intent, Unit, Model, Result, Label>() {

        override fun executeAction(action: Unit, getState: () -> Model) {
            accountIdToLoad?.let {
                service.load(it)
                    .observeOn(mainScheduler)
                    .threadLocal()
                    .map { Result.DataLoaded(it) }
                    .subscribeScoped(onSuccess = ::dispatch)
            }
        }

        override fun executeIntent(intent: Intent, getState: () -> Model) = when (intent) {
            is Intent.SetUserJid -> onSetUserJID(intent, getState())
            is Intent.SetUserPassword -> dispatch(Result.UserPasswordChanged(intent.value))
            is Intent.Save -> done(getState())
            is Intent.SetHostname -> dispatch(Result.HostnameChanged(intent.value))
            is Intent.SetResource -> dispatch(Result.ResourceChanged(intent.value))
            is Intent.SetNickname -> dispatch(Result.NicknameChanged(intent.value))
            Intent.Delete -> deleteAccount(getState())
            is Intent.SetPushEnabled -> setPushEnabled(intent.value, getState())
        }

        private fun onSetUserJID(intent: Intent.SetUserJid, state: Model) {
            val stateJid = state.userJid
            dispatch(Result.UserJidChanged(intent.value))
            try {
                val sn = stateJid.toBareJID().localpart ?: return
                val ln = intent.value.toBareJID().localpart ?: return
                if (state.nickname.isEmpty() || state.nickname == sn) {
                    dispatch(Result.NicknameChanged(ln))
                }
            } catch (_: Exception) {
            }
        }

        private fun setPushEnabled(value: Boolean, state: Model) {
            val PUSH_SERVICE = "push.tigase.im".toBareJID()
            val deviceToken = serviceKeeper.accountsService.pushDeviceToken ?: return

            if (value) {
                singleOf(serviceKeeper.connectionService[state.userJid.toBareJID()]).notNull()
                    .subscribeOn(ioScheduler)
                    .observeOn(ioScheduler)
                    .flatMapSingle { halcyon ->
                        halcyon.getModule<PushNotificationsModule>(PushNotificationsModule.TYPE)
                            .registerInPushService(PUSH_SERVICE, deviceToken)
                            .asSingle()
                            .flatMap {
                                Pair(halcyon, it).toSingle()
                            }
                    }
                    .flatMapSingle { (halcyon, node) ->
                        halcyon.getModule<PushNotificationsModule>(PushNotificationsModule.TYPE)
                            .enable(PUSH_SERVICE, node)
                            .asSingle()
                            .flatMap { node.toSingle() }
                    }
                    .flatMapCompletable { node ->
                        service.updatePushNode(state.id!!, node, true)
                    }
                    .subscribeScoped()
            } else {
                singleOf(serviceKeeper.connectionService[state.userJid.toBareJID()]).notNull()
                    .subscribeOn(ioScheduler)
                    .observeOn(ioScheduler)
                    .flatMap { halcyon ->
                        service.load(state.userJid.toBareJID())
                            .flatMapSingle { Pair(halcyon, it).toSingle() }
                    }
                    .flatMapSingle { (halcyon, node) ->
                        halcyon.getModule<PushNotificationsModule>(PushNotificationsModule.TYPE)
                            .disable(PUSH_SERVICE)
                            .asSingle()
                            .flatMap { halcyon.toSingle() }
                    }
                    .flatMapSingle { halcyon ->
                        halcyon.getModule<PushNotificationsModule>(PushNotificationsModule.TYPE)
                            .unregisterInPushService(PUSH_SERVICE, deviceToken)
                            .asSingle()
                    }
                    .flatMapCompletable { node ->
                        service.updatePushNode(state.id!!, null, false)
                    }
                    .subscribe()
            }


            dispatch(Result.PushEnabledChanged(value))
        }

        private fun validate(state: Model): Boolean {
            val pwdError = state.password.isEmpty()
            val jidError = !isJidValid(state.userJid)

            val result = !pwdError && !jidError
            if (!result) {
                dispatch(Result.Validation(jid = jidError, password = pwdError))
            }

            return result
        }

        private fun isJidValid(v: String): Boolean {
            val x = v.toBareJID()
            return !x.localpart.isNullOrBlank() && x.toString() == v.trim()
        }

        private fun deleteAccount(state: Model) {
            service.delete(state.userJid.toBareJID())
                .observeOn(mainScheduler)
                .threadLocal()
                .subscribe(onComplete = { publish(Label.Removed(state.userJid)) })

        }

        private fun done(state: Model) {
            if (validate(state)) service.addOrUpdate(
                id = accountIdToLoad,
                userjid = state.userJid.toBareJID(),
                password = state.password,
                resource = state.resource,
                hostname = state.hostname,
                nickname = state.nickname
            )
                .observeOn(mainScheduler)
                .threadLocal()
                .subscribe(onError = {
                    it.printStackTrace()
                }, onComplete = {
                    publish(Label.Saved(state.userJid))
                })
        }

    }

    private object ReducerImpl : Reducer<Model, Result> {

        override fun Model.reduce(result: Result): Model = when (result) {
            is Result.UserJidChanged -> copy(userJid = result.value)
            is Result.UserPasswordChanged -> copy(password = result.value)
            is Result.DataLoaded -> copy(
                id = result.data.id,
                userJid = result.data.userjid.toString(),
                password = result.data.password,
                resource = result.data.resource ?: "",
                nickname = result.data.nickname ?: "",
                hostname = result.data.hostname ?: "",
                pushEnabled = !result.data.push_node.isNullOrEmpty() && result.data.push_enabled
            )

            is Result.HostnameChanged -> copy(hostname = result.value)
            is Result.ResourceChanged -> copy(resource = result.value)
            is Result.NicknameChanged -> copy(nickname = result.value)
            is Result.Validation -> copy(userJidInvalid = result.jid, passwordInvalid = result.password)
            is Result.PushEnabledChanged -> copy(pushEnabled = result.value)
        }
    }
}
