package org.tigase.officialtea.common.services

import co.touchlab.kermit.Logger
import com.badoo.reaktive.completable.Completable
import com.badoo.reaktive.completable.completable
import com.badoo.reaktive.single.Single
import com.badoo.reaktive.single.flatMap
import com.badoo.reaktive.single.map
import org.tigase.officialtea.common.database.Attachments
import org.tigase.officialtea.common.database.AttachmentsQueries
import org.tigase.officialtea.common.database.query
import org.w3c.xhr.XMLHttpRequest
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.fileupload.Slot

actual class FileUploader actual constructor(
    val attachmentProvider: AttachmentProvider,
    val attachmentQueries: Single<AttachmentsQueries>,
    val connectionService: ConnectionService
) {

    actual fun upload(account: BareJID, originStanzaId: String): Single<Slot?> {
        return attachmentQueries.query { it.getByOriginId(account, originStanzaId) }.map { it.executeAsOne() }
            .flatMap { upload(connectionService, it) }
    }

    actual fun sendDataToSlot(slot: Slot, attachment: Attachments): Completable = completable { emmiter ->
        val data = attachmentProvider.loadPreview(attachment.id, attachment.localFile)!!

        val xhr = XMLHttpRequest()
        xhr.onreadystatechange = {
            Logger.i("FileUploader") { "Ready state change: ${xhr.readyState}" }
            if (xhr.readyState == 4.toShort() && xhr.status == 201.toShort()) {
                Logger.i("FileUploader") { "File uploaded!" }
                emmiter.onComplete()
            } else if (xhr.readyState == 4.toShort()) {
                Logger.i("FileUploader") { "Upload error: ${xhr.status}" }
                emmiter.onError(RuntimeException("File upload error. http_status=${xhr.status}"))
            }
        }
        xhr.open("PUT", slot.putUrl, true)
        xhr.setRequestHeader("Content-Type", slot.contentType)
        xhr.setRequestHeader("Content-Length", slot.contentLength.toString())
        slot.headers.forEach { (name, value) ->
            xhr.setRequestHeader(name, value)
        }
        xhr.send(data)
        Logger.i("FileUploader") { "Upload started: Content-Type=${slot.contentType}; Content-Length=${slot.contentLength};  xhr=$xhr" }
    }

}