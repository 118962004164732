package org.tigase.officialtea.common.main.components.accounts

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.reaktive.ReaktiveExecutor
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.observeOn
import org.tigase.officialtea.common.database.Accounts
import org.tigase.officialtea.common.services.AccountsService


internal interface AccountsStore : Store<AccountsStore.Intent, AccountsInt.Model, AccountsStore.Label> {

    sealed interface Intent {
        object LoadActiveAccounts : Intent
    }

    sealed interface Label
}

// new
internal class AccountsStoreProvider(
    private val storeFactory: StoreFactory, private val service: AccountsService,
){

    private sealed interface Result {
        data class ActiveAccountsLoaded(val items: List<Accounts>): Result
    }
    fun provide(): AccountsStore = object : AccountsStore, Store<AccountsStore.Intent, AccountsInt.Model, AccountsStore.Label> by storeFactory.create(
        name = "AccountsStore", initialState = AccountsInt.Model(), bootstrapper = SimpleBootstrapper(
            Unit
        ), executorFactory = ::ExecutorImpl, reducer = AccountsStoreProvider.ReducerImpl
    ) {}
    private inner class ExecutorImpl : ReaktiveExecutor<AccountsStore.Intent, Unit, AccountsInt.Model, Result, AccountsStore.Label>() {
        override fun executeIntent(intent: AccountsStore.Intent, getState: () -> AccountsInt.Model) = when (intent) {
            is AccountsStore.Intent.LoadActiveAccounts -> loadActiveAccounts()
        }

        private fun loadActiveAccounts() {
            service.loadAll()
                .observeOn(mainScheduler)
                .subscribeScoped(
                    onSuccess = { accounts ->
                        dispatch(Result.ActiveAccountsLoaded(accounts))
                    },
                    onError = { throwable ->
                        println("Failed to load accounts: ${throwable.message}")
                    }
                )
        }
    }

    private object ReducerImpl : Reducer<AccountsInt.Model, AccountsStoreProvider.Result> {
        override fun AccountsInt.Model.reduce(result: AccountsStoreProvider.Result): AccountsInt.Model = when (result) {
            is Result.ActiveAccountsLoaded -> copy(activeAccounts = result.items)
        }
    }


}