package org.tigase.officialtea.common.services

import co.touchlab.kermit.Logger
import com.squareup.sqldelight.db.SqlDriver
import org.tigase.officialtea.common.database.SharedDatabase
import tigase.halcyon.core.xmpp.modules.caps.EntityCapabilitiesCache
import tigase.halcyon.core.xmpp.modules.caps.EntityCapabilitiesModule
import tigase.halcyon.core.xmpp.modules.discovery.DiscoveryModule
import kotlin.native.concurrent.ThreadLocal

@ThreadLocal
object CapabilitiesCacheService : EntityCapabilitiesCache {

	private val log = Logger.withTag("CapabilitiesCacheService")

	lateinit var driver: SqlDriver

	override fun isCached(node: String): Boolean {
		try {
			Logger.i { "sprawdzamy cache" }
			return SharedDatabase(driver).capabilitiesCacheQueries.identityExists(node)
				.executeAsOneOrNull() != null
		} catch (e: Throwable) {
			Logger.e("kurwa, mać", e)
			return false
		}
	}

	override fun load(node: String): EntityCapabilitiesModule.Caps? {
		val identities = SharedDatabase(driver).capabilitiesCacheQueries.findIdentities(node)
			.executeAsList()
		val features = SharedDatabase(driver).capabilitiesCacheQueries.findFeatures(node)
			.executeAsList()
		return if (identities.isNotEmpty()) EntityCapabilitiesModule.Caps(node, identities.map {
			DiscoveryModule.Identity(it.category!!, it.type!!, it.name)
		}, features.map { it.feature }) else null
	}

	override fun store(node: String, caps: EntityCapabilitiesModule.Caps) {
		val db = SharedDatabase(driver).capabilitiesCacheQueries
		if (db.identityExists(node)
				.executeAsOneOrNull() != null
		) return
		db.transaction {
			caps.features.forEach {
				db.addFeature(node, it)
			}
			caps.identities.forEach {
				db.addIdentity(node, it.name, it.type, it.category)
			}

		}
	}
}