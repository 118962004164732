package org.tigase.officialtea.common.main.components.vcard

import com.arkivanov.decompose.value.Value
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.modules.vcard.VCard

interface VCardPanel {

	val models: Value<State>

	data class State(val jid: BareJID, val name: String, val vCard: VCard? = null)
}