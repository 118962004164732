package org.tigase.officialtea.common.main.components.roster

import com.arkivanov.decompose.value.Value
import org.tigase.officialtea.common.services.RosterManipulationService
import org.tigase.officialtea.common.services.RosterPresenceItem

interface Roster: RosterManipulationService {

	val models: Value<Model>

	fun onRosterItemClick(item: RosterPresenceItem)
	fun startChatWith(item: RosterPresenceItem)
	fun onModalClose()
	fun onRosterItemSelect(item: RosterPresenceItem)
	fun editMode(value: Boolean)
	fun onActionMenuClicked(item: RosterPresenceItem)

	data class Model(
		val items: List<RosterPresenceItem> = emptyList(),
		val selected: List<RosterPresenceItem> = emptyList(),
		val actionMenu: RosterPresenceItem? = null,
		var editMode: Boolean = false,
		val blockedList: List<RosterPresenceItem> = emptyList(),
	)

}