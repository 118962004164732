package org.tigase.officialtea.common.main.components.mixbrowser

import com.arkivanov.decompose.value.Value
import com.badoo.reaktive.observable.Observable
import tigase.halcyon.core.xmpp.BareJID

interface AddressInput {

	val models: Value<Model>

	data class Model(
		val availableAccounts: List<BareJID> = emptyList(),
		val account: BareJID? = null,
		val address: String = "",
	)

	fun labels(): Observable<AddressInputStore.Label>

	fun onTextChange(value: String)
	fun onAccountChange(account: String)

	fun tryToApplyData()
}
