package org.tigase.officialtea.common.main.settings

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.childContext
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.replaceCurrent
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.badoo.reaktive.scheduler.mainScheduler
import com.badoo.reaktive.single.observeOn
import com.badoo.reaktive.single.singleOf
import com.badoo.reaktive.single.subscribe
import com.russhwolf.settings.ExperimentalSettingsApi
import org.tigase.officialtea.common.main.settings.accountslist.AccountsRootComponent
import org.tigase.officialtea.common.main.settings.preferences.PreferencesComponent
import org.tigase.officialtea.common.main.settings.themes.ThemesComponent
import org.tigase.officialtea.common.services.ServiceKeeper
import org.tigase.officialtea.common.utils.Consumer

class SettingsComponent(
	componentContext: ComponentContext,
	private val storeFactory: StoreFactory,
	private val serviceKeeper: ServiceKeeper,
) : Settings, ComponentContext by componentContext {

	override val options = OptionsComponent(childContext("OptionsComponent"), storeFactory, Consumer(::select))

	private val router = StackNavigation<SettingsConfiguration>()
	private val stack = childStack(
		key = "SettingsRouter",
		source = router,
		initialConfiguration = SettingsConfiguration.Menu, //options.models.value.selected.action(),
		handleBackButton = true,
		childFactory = ::createChild,
	)
	override val routerState = stack

	@OptIn(ExperimentalSettingsApi::class)
	private fun createChild(configuration: SettingsConfiguration, componentContext: ComponentContext): Settings.Child =
		when (configuration) {
			is SettingsConfiguration.AppPreferences -> Settings.Child.AppPreferences(
				PreferencesComponent(
					componentContext, storeFactory, serviceKeeper
				)
			)

			is SettingsConfiguration.AccountsList -> Settings.Child.Accounts(
				AccountsRootComponent(
					componentContext, storeFactory, serviceKeeper
				)
			)

			is SettingsConfiguration.Appearance -> Settings.Child.Appearance(
				ThemesComponent(componentContext, storeFactory)
			)

			is SettingsConfiguration.Menu -> Settings.Child.Menu(this)
		}

	override fun select(screen: SettingsConfiguration) {
		singleOf(screen).observeOn(mainScheduler)
			.subscribe {
				router.replaceCurrent(it)
			}
	}

	sealed class SettingsConfiguration : Parcelable {

		@Parcelize
		object AppPreferences : SettingsConfiguration()

		@Parcelize
		object Appearance : SettingsConfiguration()

		@Parcelize
		object AccountsList : SettingsConfiguration()

		@Parcelize
		object Menu : SettingsConfiguration()
	}
}