package org.tigase.officialtea.common.services

import com.badoo.reaktive.observable.Observable
import com.badoo.reaktive.subject.publish.PublishSubject
import com.squareup.sqldelight.db.SqlDriver
import org.tigase.officialtea.common.database.AvatarsCache
import org.tigase.officialtea.common.database.SharedDatabase
import tigase.halcyon.core.AbstractHalcyon
import tigase.halcyon.core.Base64
import tigase.halcyon.core.xmpp.BareJID
import tigase.halcyon.core.xmpp.JID
import tigase.halcyon.core.xmpp.bareJID
import tigase.halcyon.core.xmpp.modules.avatar.UserAvatarModule
import tigase.halcyon.core.xmpp.modules.avatar.UserAvatarStore
import tigase.halcyon.core.xmpp.modules.vcard.Photo
import tigase.halcyon.core.xmpp.modules.vcard.VCardModule
import kotlin.native.concurrent.ThreadLocal

@ThreadLocal
object AvatarsCacheService : UserAvatarStore {

    lateinit var driver: SqlDriver

    private val change: PublishSubject<JID> = PublishSubject()

    fun observer(): Observable<JID> = change

    override fun isStored(userJID: BareJID, avatarID: String): Boolean {
        return SharedDatabase(driver).avatarsCacheDatabaseQueries.checkIfExists(userJID, avatarID)
            .executeAsOneOrNull() != null
    }

     fun isStored(userJID: BareJID): Boolean {
        return SharedDatabase(driver).avatarsCacheDatabaseQueries.checkIfAvatarExists(userJID)
            .executeAsOneOrNull() != null
    }

    override fun load(userJID: BareJID, avatarID: String): UserAvatarModule.Avatar? {
        val data = SharedDatabase(driver).avatarsCacheDatabaseQueries.selectByJidHash(userJID, avatarID)
            .executeAsOneOrNull() ?: return null
        return UserAvatarModule.Avatar(
            info = UserAvatarModule.AvatarInfo(
                bytes = data.bytes?.toInt() ?: 0,
                height = data.height?.toInt(),
                width = data.width?.toInt(),
                id = avatarID,
                type = data.type ?: "",
                url = data.url
            ), data = UserAvatarModule.AvatarData(
                id = avatarID, base64Data = Base64.encode(data.avatarData)
            )
        )
    }

    override fun store(userJID: BareJID, avatarID: String?, data: UserAvatarModule.Avatar?) {
        val buff = data?.data?.base64Data?.let {
            Base64.decode(it)
                .map { b -> b.code.toByte() }
                .toByteArray()
        }

        val db = SharedDatabase(driver).avatarsCacheDatabaseQueries
        if (db.checkIfExists(userJID, avatarID!!)
                .executeAsOneOrNull() != null
        ) return

        try {
            db.add(
                jid = userJID,
                avatarId = avatarID,
                bytes = data?.info?.bytes?.toLong(),
                width = data?.info?.width?.toLong(),
                height = data?.info?.height?.toLong(),
                type = data?.info?.type,
                url = data?.info?.url,
                avatarData = buff ?: ByteArray(0)
            )
            change.onNext(userJID)
        } catch (_: Exception) {
            // can be ignored: probably it is duplicated entry.
        }

    }

    fun getAvatar(jid: BareJID): ByteArray? {
        return SharedDatabase(driver).avatarsCacheDatabaseQueries.selectByJid(jid)
            .executeAsOneOrNull()?.avatarData
    }

    fun getAvatarRecord(jid: BareJID): AvatarsCache? {
        return SharedDatabase(driver).avatarsCacheDatabaseQueries.selectByJid(jid)
            .executeAsOneOrNull()
    }


    fun checkAvatarFromVCard(context: AbstractHalcyon, jid: JID, avatarId: String) {
        if (isStored(jid.bareJID, avatarId)) return
        context.getModule<VCardModule>(VCardModule.TYPE)
            .retrieveVCard(jid.bareJID)
            .response {
                it.onSuccess { it.photos.forEach { store(jid, it, avatarId) } }
            }
            .send()
    }

    private fun store(jid: JID, photo: Photo, avatarId: String) = when (photo) {
        is Photo.PhotoData -> storePhotoData(jid, photo, avatarId)
        is Photo.PhotoUri -> storePhotoUri(jid, photo, avatarId)
    }

    private fun storePhotoUri(jid: JID, photo: Photo.PhotoUri, avatarId: String) {

    }

    private fun storePhotoData(jid: JID, photo: Photo.PhotoData, avatarId: String) {
        val buff = photo.data?.let {
            Base64.decode(it)
                .map { b -> b.code.toByte() }
                .toByteArray()
        } ?: return

        SharedDatabase(driver).avatarsCacheDatabaseQueries.add(
            jid = jid.bareJID,
            avatarId = avatarId,
            bytes = buff.size.toLong(),
            width = null,
            height = null,
            type = photo.imageType ?: "",
            url = null,
            avatarData = buff
        )
        change.onNext(jid.bareJID)
    }

}